import React from "react";
import { Controller } from "react-hook-form";
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";

import Select from "react-select";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";

export default function PropertyAmenity({
    stepper,
  control,
  register,
  errors,
  amenities,
  getValues,
  setValue
}) {
  var propertyStatus = [
    { value: "rent", label: "Rent" },
    { value: "sale", label: "Sale" },
  ];
  const nextClick = ()=>{
    stepper.next()
  }

  return (
    <>
      <Row className="gy-1 gx-2">
      
            {amenities.map((amenityType) => (
              <Col key={amenityType._id}>
                <h5 className="fw-bolder text-primary">
                  {amenityType.type ? amenityType.type : "Others"}
                </h5>
                {amenityType.amenities?.map((am, idx) => (
                  <div className="form-check" key={idx}>
                    <Input
                      type="checkbox"
                      id={`checkbox_${am._id}`}
                      value={am._id}
                      checked={getValues().amenities.find((id) => id == am._id)}
                      onChange={(e) => {
                        let amenities = getValues().amenities;
                        let currentValue = e.target.value;
                        if (e.target.checked) {
                          setValue("amenities", [...amenities, currentValue]);
                        } else {
                          var updatdList = amenities.filter(
                            (item) => item != currentValue
                          );
                          setValue("amenities", updatdList);
                        }
                      }}
                    />

                    <Label
                      for={`checkbox_${am._id}`}
                      className="form-check-label"
                    >
                      {am.name}
                    </Label>
                  </div>
                ))}
              </Col>
            ))}
        
      </Row>

      <div className="d-flex justify-content-between mt-2">
        <Button color="secondary" className="btn-prev" onClick={()=>stepper.previous()}>
          <ArrowLeft
            size={14}
            className="align-middle me-sm-25 me-0"
          />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
        <Button color="primary" className="btn-next" onClick={nextClick}>
          <span className="align-middle d-sm-inline-block d-none">Next</span>
          <ArrowRight
            size={14}
            className="align-middle ms-sm-25 ms-0"
          />
        </Button>
      </div>
    </>
  );
}
