import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Job from "./components/job";
import Departments from "./components/department";
import Shifts from "../shifts/index";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const tabs = [
  { id: "Jobs Information", label: "Jobs Information", component: <Job /> },
  {
    id: "Departments Information",
    label: "Departments Information",
    component: <Departments />,
  },
  {
    id: "Shifts Information",
    label: "Shifts Information",
    component: <Shifts />,
  },
];

const Jobs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const toggle = (tab) => {
    setSearchParams({ tab });
  };

  useEffect(() => {
    if (!tab) {
      setSearchParams({ tab: tabs[0].id });
    }
  }, []);

  return (
    <React.Fragment>
      <Nav pills fill>
        {tabs.map(({ id, label }) => (
          <NavItem key={id} className="border border-1 rounded">
            <NavLink
              active={tab === id}
              onClick={() => {
                toggle(id);
              }}
            >
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent className="py-50" activeTab={tab}>
        {tabs.map(({ id, component }) => (
          <TabPane key={id} tabId={id}>
            {component}
          </TabPane>
        ))}
      </TabContent>
    </React.Fragment>
  );
};
export default Jobs;
