// ** Reactstrap Imports
import moment from "moment/moment";
import { Badge } from "reactstrap";

export const columns = [
  {
    name: "Index",
    // minWidth: '',
    sortable: true,
    selector: (row) => row.index,
    cell: (row) => row.index,
  },
  {
    name: "Name",
    minWidth: "250px",
    sortable: true,
    cell: ({ name }) => name,
    selector: (row) => row.name,
  },
  {
    sortable: true,
    minWidth: "250px",
    name: "User",
    cell: ({ createdBy }) => createdBy?.username,
  },
  {
    sortable: true,
    minWidth: "250px",
    name: "Created Date",
    selector: (row) => row.createdAt,
    cell: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY"),
    sortFunction: (rowA, rowB) => {
      return new Date(rowB.createdAt) - new Date(rowA.createdAt);
    },
  },
  {
    name: "Status",
    minWidth: "250px",
    sortable: true,
    cell: (row) => (
      <Badge
        color={row.status == "active" ? "success" : "danger"}
        className="text-capitalize"
      >
        <span className="">{row.status}</span>
      </Badge>
    ),
    selector: (row) => row.status,
  },
];
