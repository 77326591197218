import { toast } from 'react-hot-toast';

export const ApiErrorHandler = (error) => {
    if (!error) return;
    console.log(error)
    if (typeof error === 'string') {
        toast.error(error);
    } else if (error.error) {
        toast.error(error.error);
    } else if (error.message) {
        toast.error(error.message);
    } else if (error.data) {
        const message = error.data?.message ?? 'Something went wrong. Please try again later.';
        toast.error(message);
    } else {
        toast.error('Something went wrong. Please try again later.');
    }
}