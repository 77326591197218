import moment from "moment/moment";
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Archive,
  ChevronDown,
  Eye,
  MoreVertical,
  RefreshCcw,
  Trash2,
} from "react-feather";
import ReactPaginate from "react-paginate";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  convertDaysToMonthsAndYears,
  formatCurrency,
} from "../../../utility/Utils";
import classNames from "classnames";
import { EyeFill, Link45deg, ReceiptCutoff } from "react-bootstrap-icons";
import { Link, useSearchParams } from "react-router-dom";
import ReceiptForm from "../../receipts/components/form";
import axios from "axios";

const Receipts = ({ store }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");
  const [sort, setSort] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [modelShow, setModelShow] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [AllCustomers, setAllCustomers] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [receipts, setReceipts] = useState([]);
  const columns = [
    {
      name: "#",
      sortable: true,
      // minWidth: "10px",
      // sortField: "name",
      selector: (row) => row.receiptNo,
      cell: (row) => (
        <div className="d-flex justify-content-left align-items-center">
          <div className="d-flex flex-column">
            <span className="fw-bolder text-primary">
              #{row.receiptNo ?? ""}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Date",
      // minWidth: "100px",
      // sortable: true,
      // sortField: "status",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <span className="text-capitalize">
          {moment(row.createdAt).format("DD-MM-YYYY")}
        </span>
      ),
    },

    {
      name: "Invoice",
      sortable: true,
      // minWidth: "172px",
      // sortField: "floors",
      selector: (row) => row.invoiceId,
      cell: (row) => (
        <Link
          to={
            `invoices/${row.invoiceId?._id}` +
            (appView ? `?view=${appView}` : "")
          }
        >
          {" "}
          <span>{row.invoiceId?.invoiceNo}</span>
        </Link>
      ),
    },
    {
      name: "Amount",
      // minWidth: "138px",
      // sortable: true,
      // sortField: "discount",
      selector: (row) => row.amount,
      cell: (row) => (
        <Badge color="success" className="text-capitalize fw-bolder">
          ${row.amount}
        </Badge>
      ),
    },

    {
      name: "Reference",

      selector: (row) => row.reference,
      cell: (row) => <span className="text-capitalize">{row.reference}</span>,
    },

    {
      name: "User",

      selector: (row) => row.createdBy,
      cell: (row) => row.createdBy?.username,
    },
    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="column-action d-flex align-items-center justify-content-center">
 
          <Link
            to={`/receipts/${row._id}` + (appView ? `?view=` + appView : ``)}
 
            className="mx-1"
            id={`pw-tooltip-${row._id}`}
          >
            <Eye size={17} />
          </Link>
          <UncontrolledTooltip placement="top" target={`pw-tooltip-${row._id}`}>
            Print Receipt
          </UncontrolledTooltip>

          <UncontrolledDropdown>
            <DropdownToggle tag="div" className="btn btn-sm">
              <MoreVertical size={14} className="cursor-pointer" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(selectReceipt(row));
                  setModalShow(true);
                }}
              >
                <Archive size={14} className="me-50" />
                <span className="align-middle">Edit</span>
              </DropdownItem>
              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  handleConfirmDelete(row._id, row.name);
                }}
              >
                <Trash2 size={14} className="me-50" />
                <span className="align-middle">Delete</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const handlePagination = (page) => {
    const startIndex = page.selected * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const currentPageData = store.data.receipts?.slice(startIndex, endIndex);

    setInvoices(currentPageData);
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.data.receipts.length / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const ToggleRentModal = (show) => {
    setModelShow(false);
  };

  const getAccounts = async () => {
    try {
      var { status, data } = await axios.get(
        "/accountings/accounts/hierarchy/64089b489d122d411c67abe4"
      );
      if (status == 200) {
        setAccounts(data?.data ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCustomers = async () => {
    try {
      var { status, data } = await axios.get("/customers/list");
      if (status == 200) {
        setAllCustomers(data?.data ?? []);
      }
    } catch (error) {
      console.log(error);
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    getAccounts();
    getAllCustomers();
  }, []);

  useEffect(() => {
    setReceipts(store.data.receipts?.slice(0, rowsPerPage));
  }, [store]);

  return (
    <Fragment>
      <ReceiptForm
        show={modelShow}
        ShowModal={ToggleRentModal}
        data={{
          accounts,
          customers: AllCustomers,
        }}
        appView={appView}
      />
      <Card>
        <CardHeader tag="h4">
          <CardTitle>Customers's Receipt List</CardTitle>
          <Button color="primary" onClick={() => setModelShow(true)}>
            New Receipt
          </Button>
        </CardHeader>
        <CardBody>
          <div className="react-dataTable user-view-account-projects">
            <DataTable
              subHeader
              sortServer
              pagination
              responsive
              paginationServer
              striped
              columns={columns}
              sortIcon={<ChevronDown />}
              className="react-dataTable react-dataTable-selectable-rows"
              paginationComponent={CustomPagination}
              data={receipts}
            />
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Receipts;
