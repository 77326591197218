import React from "react";
import { Controller } from "react-hook-form";
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";

import Select from "react-select";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";

export default function PropertyDetail({
  stepper,
  control,
  register,
  errors,
  getValues,
}) {
  const nextClick = () => {
    stepper.next();
  };

  return (
    <>
      <Row className="gy-1 gx-2">
        <Col xs={12} md={3}>
          <Label className="form-label" for="price">
            {getValues().propertyStatus?.value == "For Sale"
              ? "Price"
              : "Total Rent Amount"}
          </Label>
          <InputGroup className="input-group-merge">
            <InputGroupText>$</InputGroupText>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <Input
                  id="price"
                  min={0}
                  type="number"
                  {...register("price", { required: true })}
                  step={"any"}
                  placeholder="300"
                  invalid={errors.price && true}
                  {...field}
                />
              )}
            />{" "}
          </InputGroup>
          {errors.price && (
            <FormFeedback>Please enter a valid price</FormFeedback>
          )}
        </Col>

        <Col xs={12} md={3}>
          <Label className="form-label" for="deposit">
            Deposit
          </Label>
          <InputGroup className="input-group-merge">
            <InputGroupText>$</InputGroupText>

            <Controller
              name="deposit"
              control={control}
              render={({ field }) => (
                <Input
                  id="deposit"
                  min={0}
                  type="number"
                  {...register("deposit", { required: true })}
                  step={"any"}
                  placeholder="300"
                  invalid={errors.deposit && true}
                  {...field}
                  disabled={getValues().propertyStatus?.value == "For Sale"}
                />
              )}
            />
          </InputGroup>
          {errors.deposit && (
            <FormFeedback>Please enter a valid deposit</FormFeedback>
          )}
        </Col>
        <Col xs={12} md={3}>
          <Label className="form-label" for="advance">
            Advance
          </Label>
          <InputGroup className="input-group-merge" aria-disabled="true">
            <InputGroupText>$</InputGroupText>

            <Controller
              name="advance"
              control={control}
              render={({ field }) => (
                <Input
                  id="advance"
                  min={0}
                  type="number"
                  {...register("advance", { required: true })}
                  step={"any"}
                  placeholder="300"
                  invalid={errors.advance && true}
                  {...field}
                  disabled={getValues().propertyStatus?.value == "For Sale"}
                />
              )}
            />
          </InputGroup>
          {errors.advance && (
            <FormFeedback>Please enter a valid advance</FormFeedback>
          )}
        </Col>
        <Col xs={12} md={3}>
          <Label className="form-label" for="reservationFee">
            Reservation Fee
          </Label>
          <InputGroup className="input-group-merge">
            <InputGroupText>$</InputGroupText>

            <Controller
              name="reservationFee"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="reservationFee"
                  min={0}
                  type="number"
                  step={"any"}
                  placeholder="50"
                  invalid={errors.reservationFee && true}
                  {...field}
                  disabled={getValues().propertyStatus?.value == "For Sale"}
                />
              )}
            />
          </InputGroup>
          {errors.reservationFee && (
            <FormFeedback>Please enter a valid reservation fee</FormFeedback>
          )}
        </Col>
      </Row>

      <div className="d-flex justify-content-between mt-2">
        <Button
          color="secondary"
          className="btn-prev"
          onClick={() => stepper.previous()}
        >
          <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </Button>
        <Button color="primary" className="btn-next" onClick={nextClick}>
          <span className="align-middle d-sm-inline-block d-none">Next</span>
          <ArrowRight size={14} className="align-middle ms-sm-25 ms-0" />
        </Button>
      </div>
    </>
  );
}
