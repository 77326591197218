// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// ** Icons Imports
import { User, Lock, Bookmark, Bell, Link } from "react-feather";

// ** User Components

import CustomerDetail from "./CustomerDetail";
import Invoice from "./Invoices";
import Receipt from "./Receipts";
import Agreements from "./Agreements";
import UserView from "../preview";
import Statement from "../statement";
import Preview from "../statement/preview";

const UserTabs = ({ active, toggleTab, store }) => {
  return (
    <Fragment>
      <Nav pills className="mb-2">
        <NavItem>
          <NavLink active={active === "1"} onClick={() => toggleTab("1")}>
            <User className="font-medium-3 me-50" />
            <span className="fw-bold">Profile</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "2"} onClick={() => toggleTab("2")}>
            <Lock className="font-medium-3 me-50" />
            <span className="fw-bold">Invoice</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "3"} onClick={() => toggleTab("3")}>
            <Bookmark className="font-medium-3 me-50" />
            <span className="fw-bold">Receipts</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "4"} onClick={() => toggleTab("4")}>
            <Bell className="font-medium-3 me-50" />
            <span className="fw-bold">Agreements</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "5"} onClick={() => toggleTab("5")}>
            <Link className="font-medium-3 me-50" />
            <span className="fw-bold">Statement</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={active}>
        <TabPane tabId="1">
          <CustomerDetail store={store} />
        </TabPane>
        <TabPane tabId="2">
          <Invoice store={store} />
        </TabPane>
        <TabPane tabId="3">
          <Receipt store={store} />
        </TabPane>
        <TabPane tabId="4">
          <Agreements store={store} />
        </TabPane>
        <TabPane tabId="5">
          {/* <UserView /> */}
          <Statement store={store} />
        </TabPane>
      </TabContent>
    </Fragment>
  );
};
export default UserTabs;
