// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllPayments = createAsyncThunk(
  "Payments/getAllData",
  async (p, { dispatch, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      let params = {
        params: {
          options: {
            query: {},
            populate: [
              {
                dir: "accountings/chartOfAccounts",
                path: "accountId",
              },
              {
                dir: "buildings",
                path: "building",
              },
            ],
          },
        },
      };

      const response = await axios.get("accountings/payments", params);
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        const docs = response.data?.data?.docs || [];
        return { docs, totalRows: response.data?.data?.totalDocs };
      }
    } catch (error) {
      dispatch(isLoading(false));
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(false));
  }
);

export const getData = createAsyncThunk(
  "Payments/getData",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      console.log(params);
      const response = await axios.get("accountings/payments", {
        params: { options: params },
      });
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        return {
          params,
          data: response.data?.data?.docs,
        };
      }
    } catch (error) {
      dispatch(isLoading(false));
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(false));
  }
);
export const updatePayment = createAsyncThunk(
  "Payments/update",
  async ({ formdata, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      var response = await axios.patch(`accountings/payments/${id}`, formdata);
      // await dispatch(getData(getState().users.params));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        dispatch(editPayment(response.data?.data));
        return { status: response.status, message: response.message };
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(false));
  }
);

export const createPayment = createAsyncThunk(
  "Payments/create",
  async (formdata, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      var response = await axios.post("accountings/payments", formdata);
      // await dispatch(getData(getState().users.params));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        dispatch(addPayment(response.data?.data));
        return { status: response.status, message: response.message };
      }
    } catch (error) {
      dispatch(isLoading(false));
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(false));
  }
);

export const deletePayment = createAsyncThunk(
  "Payments/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(isLoading(true));
      const response = await axios.delete(`accountings/payments/${id}`);
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        await dispatch(getAllPayments());
        return response.status;
      }
    } catch (error) {
      dispatch(isLoading(false));
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
    dispatch(isLoading(false));
  }
);

export const paymentSlice = createSlice({
  name: "payments",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedPayment: null,
  },
  reducers: {
    isLoading: (state, { payload }) => {
      state.loading = payload;
      state.error = null;
    },
    addPayment: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectPayment: (state, { payload }) => {
      state.selectedPayment = payload;
    },
    editPayment: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPayments.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading=false;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading=false;
      });
  },
});
export const { addPayment, selectPayment, editPayment, isLoading } =
  paymentSlice.actions;
export default paymentSlice.reducer;
