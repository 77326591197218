// ** React Imports
import { Fragment, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  CardBody,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
} from "reactstrap";

// ** Third Party Components
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Home, Check, X, Briefcase } from "react-feather";
import Avatar from "@src/assets/images/portrait/small/avatar-s-11.jpg";

// ** Utils
import { selectThemeColors } from "@utils";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import MultipleImageUpload from "./MultipleImageUpload";
import {
  addBuilding,
  createBuilding,
  updateBuilding,
  selectBuilding as selectBuildingReducer,
} from "../../../redux/building";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import GoogleMap from "../../../google-map-react-2.2/src";

const countryOptions = [
  { value: "uk", label: "UK" },
  { value: "usa", label: "USA" },
  { value: "france", label: "France" },
  { value: "russia", label: "Russia" },
  { value: "canada", label: "Canada" },
];
// const schema = yup.object().shape({
//   name: yup.string().required(),
//   floors: yup.number().required(),
//   price: yup.number().required(),
//   type: yup.string().required(),
// });

const AddNewAddress = ({ show, ShowModal, data }) => {
  const selectedBuilding = useSelector(
    (state) => state.buildings.selectedBuilding
  );

  const defaultValues = {
    name: "",
    floors: 0,
    price: "",
    category: "",
    phone: "",
    email: "",
    location: {
      country: "",
      city: "",
      state: "",
    },
  };

  // ** Hooks
  const {
    register,
    reset,
    control,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset({
      ...selectedBuilding,
      phone: selectedBuilding?.contacts?.phone ?? "",
      email: selectedBuilding?.contacts?.email ?? "",
      category: {
        value: selectedBuilding?.category?._id,
        label: selectedBuilding?.category?.name,
      },
    });
  }, [selectedBuilding]);

  const defaultProps = {
    center: {
      lat: 2.0469,
      lng: 45.3182,
    },
    zoom: 15,
  };

  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  const [coordinates, setCoordinates] = useState({
    latitude: defaultProps.center.lat,
    longitude: defaultProps.center.lng,
  });

  var buildingTypes = [
    { value: "Tower", label: "Tower" },
    { value: "House", label: "House" },
  ];

  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    try {
      const { phone, email } = data;

      delete data.phone;
      delete data.email;

      var formdata = new FormData();
      for (const key in data) {
        formdata.append(key, data[key]);
      }

      files.forEach((file, i) => {
        formdata.append(`file`, file, file.name);
      });

      formdata.set(
        "location",
        JSON.stringify({ coordinates: coordinates, ...data.location })
      );
      formdata.set("contacts", JSON.stringify({ phone, email }));
      formdata.set("category", data.category?.value);

      if (selectedBuilding) {
        await dispatch(updateBuilding({ id: selectedBuilding._id, formdata }));
        toast.success("Building Information updated Succesfully");
      } else {
        const data = await dispatch(createBuilding(formdata));
        toast.success("New Building Created Succesfully");
      }

      reset();

      ShowModal();
    } catch (error) {
      console.error(error);
      toast.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    dispatch(selectBuildingReducer(null));
    reset();
  };
  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={ShowModal}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={ShowModal}
        ></ModalHeader>
        <ModalBody className="pb-5 px-sm-4 mx-50">
          <h1 className="address-title  mb-1">
            {" "}
            {selectedBuilding ? (
              <>
                {" "}
                Update Property <b>({selectedBuilding.name})</b>
              </>
            ) : (
              "Add New Property"
            )}{" "}
          </h1>
          <p className="address-subtitle  mb-2 pb-75"></p>
          <Row
            tag="form"
            className="gy-1 gx-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Col xs={12} md={6}>
              <Label className="form-label" for="name">
                Name
              </Label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    id="name"
                    {...register("name", { required: true })}
                    placeholder="Burj Tower"
                    invalid={errors.name && true}
                    {...field}
                  />
                )}
              />
              {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6}>
              <Label className="form-label" for="floors">
                Floors
              </Label>
              <Controller
                name="floors"
                control={control}
                render={({ field }) => (
                  <Input
                    id="floors"
                    type="number"
                    placeholder="4"
                    {...register("floors", { required: true })}
                    invalid={errors.floors && true}
                    {...field}
                  />
                )}
              />
              {errors.floors && (
                <FormFeedback>Please enter a valid floor</FormFeedback>
              )}
            </Col>

            <Col xs={12} md={6}>
              <Label className="form-label" for="price">
                Price
              </Label>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <Input
                    id="price"
                    min={0}
                    type="number"
                    {...register("price", { required: true })}
                    step={"any"}
                    placeholder="300"
                    invalid={errors.price && true}
                    {...field}
                  />
                )}
              />
              {errors.price && (
                <FormFeedback>Please enter a valid price</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6}>
              <Label className="form-label" for="category">
                Category
              </Label>

              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <Select
                    id="category"
                    invalid={errors.category && true}
                    {...register("category", { required: true })}
                    {...field}
                    // value={buildingTypes.find(b=>b.value==field.value)}
                    options={data?.categories.map((ct) => ({
                      value: ct._id,
                      label: ct.name,
                    }))}
                    // onChange={(v)=>field.onChange(v.value)}
                  />
                )}
              />
              {errors.category && (
                <FormFeedback>Please enter a valid Category</FormFeedback>
              )}
            </Col>

            <Col xs={12} md={6}>
              <Label className="form-label" for="phone">
                Contact Phone
              </Label>

              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <Input
                    id="phone"
                    type="phone"
                    {...register("phone", { required: true })}
                    placeholder="25261...."
                    invalid={errors.phone && true}
                    {...field}
                  />
                )}
              />
              {errors.phone && (
                <FormFeedback>Please enter a valid Email</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6}>
              <Label className="form-label" for="type">
                Contact Email
              </Label>

              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    id="email"
                    min={0}
                    type="email"
                    {...register("email", { required: true })}
                    step={"any"}
                    placeholder="admin@rukun.so"
                    invalid={errors.email && true}
                    {...field}
                  />
                )}
              />
              {errors.email && (
                <FormFeedback>Please enter a valid Email</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={4}>
              <Label className="form-label" for="location.country">
                Country
              </Label>

              <Controller
                name="location.country"
                control={control}
                render={({ field }) => (
                  <Input
                    id="location.country"
                    type="text"
                    {...register("location.country", { required: true })}
                    placeholder=""
                    invalid={errors.location?.country && true}
                    {...field}
                  />
                )}
              />
              {errors.location?.country && (
                <FormFeedback>Please enter a valid Email</FormFeedback>
              )}
            </Col>

            <Col xs={12} md={4}>
              <Label className="form-label" for="location.state">
                State
              </Label>

              <Controller
                name="location.state"
                control={control}
                render={({ field }) => (
                  <Input
                    id="location.state"
                    type="text"
                    {...register("location.state", { required: true })}
                    placeholder=""
                    invalid={errors.location?.state && true}
                    {...field}
                  />
                )}
              />
              {errors.location?.state && (
                <FormFeedback>Please enter a valid Email</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={4}>
              <Label className="form-label" for="location.city">
                City
              </Label>

              <Controller
                name="location.city"
                control={control}
                render={({ field }) => (
                  <Input
                    id="location.city"
                    type="text"
                    {...register("location.city", { required: true })}
                    placeholder=""
                    invalid={errors.location?.city && true}
                    {...field}
                  />
                )}
              />
              {errors.location?.city && (
                <FormFeedback>Please enter a valid Email</FormFeedback>
              )}
            </Col>

            <Col xs={12}>
              <Label className="form-label">Property Images</Label>

              <MultipleImageUpload files={files} setFiles={setFiles} />
            </Col>
            <Col xs={12}>
              <Label className="form-label">Select Property Location</Label>
              <div style={{ height: "350px" }}>
                <GoogleMap
                  apiKey={""}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  onClick={(e) => console.log(e)}
                />
              </div>
            </Col>
            {/* <Col xs={12}>
              <div className="d-flex align-items-center">
                <div className="form-check form-switch form-check-primary me-25">
                  <Input
                    type="switch"
                    defaultChecked
                    id="billing-switch"
                    name="billing-switch"
                  />
                  <Label className="form-check-label" htmlFor="billing-switch">
                    <span className="switch-icon-left">
                      <Check size={14} />
                    </span>
                    <span className="switch-icon-right">
                      <X size={14} />
                    </span>
                  </Label>
                </div>
                <label
                  className="form-check-label fw-bolder"
                  htmlFor="billing-switch"
                >
                  Is Building available for online Booking?
                </label>
              </div>
            </Col> */}
            <Col className="text-center" xs={12}>
              <Button
                type="submit"
                className="me-1 mt-2"
                color={selectedBuilding ? "success" : "primary"}
                disabled={loading}
              >
                {selectedBuilding
                  ? loading
                    ? "Saving.."
                    : "Save Changes"
                  : "Submit"}
              </Button>
              <Button
                type="reset"
                className="mt-2"
                color="secondary"
                outline
                onClick={onDiscard}
              >
                Discard
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddNewAddress;
