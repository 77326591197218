import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
// ** Reactstrap Imports
import {
    Row,
    Col,
    Label,
    Form,
    Input,
    Button,
    Modal,
    ModalHeader, ModalBody, FormFeedback, Alert
} from 'reactstrap'

// ** Third Party Components
import * as yup from 'yup'
import toast from 'react-hot-toast'

// ** Custom Yup Validation Schema
import { yupResolver } from '@hookform/resolvers/yup'

// ** Redux RTK Query Imports
import { useUpdateShiftMutation } from '../../../../../redux/shifts'

function UpdateShiftModal({ shift, show, setShow }) {
    // ** RTK Query Mutations
    const [updateShift, { isLoading, error }] = useUpdateShiftMutation();

    // ** Default Values
    const defaultValues = {
        name: shift?.name ?? ''
    }

    const ShiftSchema = yup.object().shape({
        name: yup.string().required(),
    })

    // ** useForm hook vars
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(ShiftSchema), defaultValues })

    // ** Handles error toast
    useEffect(() => {
        if (shift) reset(defaultValues);
    }, [shift])


    // ** Handles form submission
    const onSubmit = async (data) => {
        // ** Checks if there are errors in the form values before submitting
        if (Object.values(data).every(field => field.length > 0)) {
            const { data: resData, error: err } = await updateShift({ ...data, _id: shift._id });

            if (resData) {
                setShow(false);
                toast.success('Shift updated successfully!');
            }
            if (err) {
                const message = err.data.message || 'Something went wrong. Please try again later.';
                toast.error(message);
                setShow(false);
            }
        }
    }

    // ** Handles form discard
    const handleDiscard = () => {
        reset();
        setShow(false);
    }


    return (
        <Modal isOpen={show} onClosed={handleDiscard} toggle={() => setShow(!show)} className='modal-dialog-centered'>
            <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>
            <ModalBody className='px-sm-5 pb-5' >
                <div className='text-center mb-2'>
                    <h1 className='mb-1'>Add New Shift</h1>
                </div>

                {error && <Alert color='danger'>
                    <h6 className='alert-heading'>Error {error.status}!</h6>
                    <div className='alert-body'>
                        <p>{JSON.stringify(error.data.message)}</p>
                    </div>
                </Alert>}

                <Row tag={Form} onSubmit={handleSubmit(onSubmit)}>
                    <Col xs={12}>
                        <Label className='form-label' for='name'>
                            shift Name
                        </Label>
                        <Controller
                            control={control}
                            id='name'
                            name='name'
                            render={({ field }) => <Input {...field} placeholder='Shfit Name' invalid={errors.name && true} />}
                        />
                        {errors && errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                    </Col>
                    <Col xs={12} className='text-center mt-2'>
                        <Button className='me-1' color='primary' disabled={isLoading}>
                            {isLoading ? 'Please, wait...' : 'Update Shift'}
                        </Button>
                        <Button outline type='reset' onClick={handleDiscard} disabled={isLoading}>
                            Discard
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default UpdateShiftModal