
export default function useStorage() {

  const setItem=(key,value)=>{
    localStorage.setItem(key,value)
  }  
  const getItem=(key)=>{
    localStorage.getItem(key)
  } 

  const removeItem=(key)=>{
    localStorage.removeItem(key)
  } 


  return {setItem,getItem,removeItem};
}
