// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import {
  Alert,
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import moment from "moment";

import { Controller, useForm } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

import { ErrorHandler } from "../../../common/utils/Error";
import CustomDispatch from "../../../common/utils/HandledDispatch";
import { terminateAgreement } from "../../../redux/agreements";
import HandleDispatch from "../../../common/utils/HandledDispatch";
import axios from "axios";

const TerminateAgreement = ({ show, onClose, row }) => {
  const [terminateError, setTerminateError] = useState("Please Type Later");
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    terminationDate: moment().format("YYYY-MM-DD"),
    reason: "",
    terminationDocs: [],
  };

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (row) {
        data = { ...data, agreement: row?._id };
        var agr = await HandleDispatch(dispatch, terminateAgreement(data));
        if (agr) {
          toast.success(
            `Agreement (${row.agreementName}) Has been terminated `
          );
          reset();
          onClose();
        }
      } else {
        toast.error("Please Select Agreement");
        reset();
        onClose();
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const onDiscard = () => {
    clearErrors();
    reset();
    onClose();
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onClose}
        className=""
        size="lg"
      >
        <ModalHeader toggle={onClose} className="bg-white">
          Terminate Agreements
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}
            {!row ? (
              <Alert color="danger">
                <div className="alert-body">
                  {/* <Check2Circle size={15} /> */}
                  <span className="ms-1">Select Agreement.</span>
                </div>
              </Alert>
            ) : null}
            <Row className="gy-1 gx-2 flex-column my-1">
              <Col xs={12}>
                <Label className="form-label" for="terminationDate">
                  Termination Date
                </Label>
                <Controller
                  name="terminationDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="terminationDate"
                      type="date"
                      invalid={errors.terminationDate && true}
                      {...field}
                    />
                  )}
                />
                {errors.terminationDate && (
                  <FormFeedback>Please enter Termination Date</FormFeedback>
                )}
              </Col>

              <Col xs={12}>
                <Label className="form-label" for="reason">
                  Reason
                </Label>
                <Controller
                  name="reason"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="reason"
                      type="textarea"
                      invalid={errors.reason && true}
                      {...field}
                      accentHeight="50"
                    />
                  )}
                />
                {errors.reason && (
                  <FormFeedback>Please enter Termination Reason</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="success"
              disabled={!row || loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading ? "Submitting..." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default TerminateAgreement;
