// ** React Imports
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

// ** Third Party Components
import axios from "axios";

// ** Reactstrap Imports
import { Row, Col, Alert, Spinner } from "reactstrap";

// ** Invoice Preview Components
import PreviewCard from "./PreviewCard";
import PreviewActions from "./PreviewActions";
// import AddPaymentSidebar from '../shared-sidebar/SidebarAddPayment'
// import SendInvoiceSidebar from '../shared-sidebar/SidebarSendInvoice'

// ** Styles
import "@styles/base/pages/app-invoice.scss";

import useStorage from "../../../utility/hooks/useStorage";

import { ErrorHandler } from "../../../common/utils/Error";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import generatePDF, { Resolution, Margin } from "react-to-pdf";

const InvoicePreview = () => {
  // ** HooksVars
  const { id } = useParams();
  const { removeItem } = useStorage();
  // ** States
  // const [data, setData] = useState(null)
  const [sendSidebarOpen, setSendSidebarOpen] = useState(false);
  const [addPaymentOpen, setAddPaymentOpen] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // ** Functions to toggle add & send sidebar
  const toggleSendSidebar = () => setSendSidebarOpen(!sendSidebarOpen);
  const toggleAddSidebar = () => setAddPaymentOpen(!addPaymentOpen);

  const invoiceRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: `${data[0]?.type} Invoice ${data[0]?.invoiceNo} - ${data[0]?.customer?.name}`,
    pageStyle: `
    @page {
      size: 210mm 148mm;
      }
      @media print {
      @page {  size: a5 landscape;
          margin: 0mm !important;
      }
    }
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always !important;
      }
      .card{
        box-shadow:none !important;
      }
    }
    
    @page {
      size: auto;
    }
    `,
    bodyClass: "",
  });

  const handleToPDF = () => {
    const filename = `${data[0]?.type} Invoice ${data[0]?.invoiceNo} - ${data[0]?.customer?.name}.pdf`;
    const options = {
      filename,
      resolution: Resolution.HIGH,
      page: {
        margin: Margin.SMALL,
        format: "A5",
        // default is 'portrait'
        orientation: "landscape",
      },
      canvas: {
        // default is 'image/jpeg' for better size performance
        // mimeType: "image/png",
        qualityRatio: 1,
      },
      overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
          compress: true,
        },
        canvas: {
          useCORS: true,
        },
      },
    };

    generatePDF(invoiceRef, options);
  };

  const getInvoiceDetail = async () => {
    setLoading(true);
    try {
      let ids = id.split(",");
      const response = await axios.get(`accountings/invoices/get`, {
        params: { invoice: ids },
      });

      if (response.status == 200) {
        setData(response.data.data);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    removeItem("printInvoice");
    getInvoiceDetail();
  }, []);

  return loading ? (
    <div className="d-flex justify-content-center align-items-center">
      {" "}
      <Spinner />{" "}
    </div>
  ) : data && data.length ? (
    <div className="invoice-preview-wrapper">
      <Row className="invoice-preview">
        <Col xl={9} md={8} sm={12}>
          <div ref={invoiceRef} dir="ltr" className="invoice-preview">
            {data.map((item, index) => (
              <>
                <div className="page-break" />
                <PreviewCard key={index} data={item} index={index} />
              </>
            ))}

            {/* <PreviewCard data={data}  /> */}
          </div>
        </Col>
        <Col xl={3} md={4} sm={12}>
          {/* {data?.map((data,key)=>())} */}
          <PreviewActions
            id={id}
            data={data}
            setSendSidebarOpen={setSendSidebarOpen}
            setAddPaymentOpen={setAddPaymentOpen}
            handlePrint={handlePrint}
            handleToPDF={handleToPDF}
          />
          {/* <button onClick={()=>handlePrint()}>Print</button>  */}
        </Col>
      </Row>

      {/* <SendInvoiceSidebar toggleSidebar={toggleSendSidebar} open={sendSidebarOpen} />
      <AddPaymentSidebar toggleSidebar={toggleAddSidebar} open={addPaymentOpen} /> */}
    </div>
  ) : (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <Alert color="danger">
        <h4 className="alert-heading">Invoice not found</h4>
        <div className="alert-body">
          Invoice with id: {id} doesn't exist. Check list of all invoices:{" "}
          <Link to="/apps/invoice/list">Invoice List</Link>
        </div>
      </Alert>
    </div>
  );
};

export default InvoicePreview;
