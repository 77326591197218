// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios, { AxiosError } from "axios";

export const getAllLeaveRequests = createAsyncThunk(
  "leaveRequests/getAllData",
  async () => {
    let bodyContent = {
      params: {
        options: {
          query: {},
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "_id name",
            },
            {
              path: "staff",
              dir: "hrm/staffs",
              select: "_id name contacts",
            },
          ],
        },
      },
    };
    const response = await axios.get("/hrm/leaves", bodyContent);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    const docs = response.data?.data?.docs || [];
    return { docs, totalRows: response.data?.data?.totalDocs };
  }
);

export const getData = createAsyncThunk("leaves/getData", async (params) => {
  const response = await axios.get("/hrm/leaves", {
    params: {
      options: {
        query: params,
        populate: [
          {
            path: "createdBy",
            dir: "users",
            select: "_id name",
          },
          {
            path: "staff",
            dir: "hrm/staffs",
            select: "_id name",
          },
        ],
      },
    },
  });
  if (response.status != 200) {
    return rejectWithValue(response.data.message);
  }
  return {
    params,
    data: response.data?.data?.docs,
  };
});

export const createLeaveRequest = createAsyncThunk(
  "leaves/create",
  async (
    formdata,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      var response = await axios.post("/hrm/leaves", formdata);
      // dispatch(addShift(response.data?.data));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateLeaveRequest = createAsyncThunk(
  "leaves/update",
  async ({ data, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.patch(`/hrm/leaves/${id}`, data);
      // await dispatch(getData(getState().users.params));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      //  dispatch(editShift(response.data?.data));
      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteLeaveRequest = createAsyncThunk(
  "leaves/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/hrm/leaves/${id}`);
      console.log(response.data);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      await dispatch(getAllLeaveRequests());
      return response.status;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const leaveRequestSlice = createSlice({
  name: "leaves",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    error: null,
    selectedLeave: null,
  },
  reducers: {
    addLeave: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectLeave: (state, { payload }) => {
      state.selectedLeave = payload;
    },
    editLeave: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.loading = false;
      state.error = null;
      state.selectedLeave = null;
    },
    searchFilter: (state, { payload }) => {
      if (payload == "") {
        state.data = state.allData;
        return;
      }

      state.data = state.allData.filter(
        (cs) =>
          cs.name?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.phone?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.email?.toLowerCase().includes(payload.toLowerCase())
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllLeaveRequests.pending, (state, action) => {
        state.loading = !state.data.length ? true : false;
        state.error = null;
      })
      .addCase(getAllLeaveRequests.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllLeaveRequests.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(getData.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      })

      .addCase(createLeaveRequest.fulfilled, (state, { payload }) => {
        state.error = null;
        state.selectedLeave = null;
        state.allData.unshift(payload.data);
        state.data.unshift(payload.data);
      })
      .addCase(createLeaveRequest.rejected, (state, { payload, error }) => {
        state.error = payload;
        state.selectedLeave = null;
      })
      .addCase(updateLeaveRequest.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLeaveRequest.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.error = null;
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return payload;
          }
          return da;
        });
        state.selectedLeave = null;
      })
      .addCase(updateLeaveRequest.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      });
  },
});

export const { selectLeaveRequest, searchFilter } = leaveRequestSlice.actions;
export default leaveRequestSlice.reducer;
