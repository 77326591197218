import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  ChevronDown,
  File,
  Printer,
  Share,
  FileText,
  Grid,
  Copy,
} from "react-feather";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Card,
  CardBody,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
  Badge,
  Spinner,
} from "reactstrap";
import TableLoading from "../../../@core/components/base/TableLoading";
import { ErrorHandler } from "../../../common/utils/Error";
import moment from "moment";

const CustomHeader = ({
  data,
  toggleSidebar,
  // handlePerPage,
  rowsPerPage,
  searchTerm,
  // handleFilter,
}) => {
  const navigate = useNavigate();
  // ** Converts table to CSV
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  // ** Downloads CSV
  function downloadCSV(array) {
    array = array.map((item, idx) => ({}));

    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "customers.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row>
        <Col xl="6" className="d-flex align-items-center p-0">
          <div className="d-flex align-items-center w-100">
            <label htmlFor="rows-per-page">Show</label>
            <Input
              className="mx-50"
              type="select"
              id="rows-per-page"
              value={rowsPerPage}
              // onChange={handlePerPage}
              style={{ width: "5rem" }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="50">100</option>
            </Input>
            <label htmlFor="rows-per-page">Entries</label>
          </div>
        </Col>
        <Col
          xl="6"
          className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
        >
          <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
            <label className="mb-0" htmlFor="search-invoice">
              Search:
            </label>
            <Input
              id="search-invoice"
              className="ms-50 w-100"
              type="text"
              value={searchTerm}
              // onChange={(e) => {
              //   handleFilter(e.target?.value);
              // }}
            />
          </div>

          <div className="d-flex align-items-center table-header-actions">
            <UncontrolledDropdown className="me-1">
              <DropdownToggle color="secondary" caret outline>
                <Share className="font-small-4 me-50" />
                <span className="align-middle">Export</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="w-100">
                  <Printer className="font-small-4 me-50" />
                  <span className="align-middle">Print</span>
                </DropdownItem>
                <DropdownItem
                  className="w-100"
                  onClick={() => {
                    downloadCSV(data);
                  }}
                >
                  <FileText className="font-small-4 me-50" />
                  <span className="align-middle">CSV</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Grid className="font-small-4 me-50" />
                  <span className="align-middle">Excel</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <File className="font-small-4 me-50" />
                  <span className="align-middle">PDF</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Copy className="font-small-4 me-50" />
                  <span className="align-middle">Copy</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const agreementDetail = () => {
  const { id } = useParams();
  const [agreements, setAgreements] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadAgreements = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`agreements/detail/${id}`);
      console.log(response);
      if (response.status == 200) {
        setAgreements(response.data?.data);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAgreements();
  }, []);

  const columns = [
    {
      name: "Agreement Name",

      sortable: true,
      sortField: "name",
      selector: (row) => row.agreement.agreementName ?? "",
    },
    {
      name: "Start Date",
      sortable: true,
      sortField: "name",
      selector: (row) => row.startDate ?? "",
      cell: (row) => (
        <div className="">
          {moment(row.startDate).format("DD MMM YYYY") ?? ""}
        </div>
      ),
    },
    {
      name: "End Date",
      sortable: true,
      sortField: "name",
      selector: (row) => row.endDate ?? "",
      cell: (row) => (
        <div className="">
          {row.endDate ? (
            moment(row.endDate).format("DD MMM YYYY")
          ) : (
            <Badge color="success">Now</Badge>
          )}
        </div>
      ),
    },
    {
      name: "Status",
      sortable: true,
      sortField: "name",
      selector: (row) => row.status ?? "",
      cell: (row) => (
        <Badge
          color={
            row.status.toLowerCase() == "start" ||
            row.status.toLowerCase() == "renewed"
              ? "success"
              : "danger"
          }
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },
    {
      name: "Reason",

      sortable: true,
      sortField: "name",
      selector: (row) => row.reason,
      cell: (row) => <span>{row.reason ?? "------"}</span>,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = 0; //Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  return (
    <Fragment>
      <Card>
        {/* <CardBody className="d-flex align-items-start justify-content-between"></CardBody> */}
        <CardBody>
          <Row>
            <Col sm={12} md={6}>
              <Label for="role-select">Filter by Customer</Label>
              <Select
                isClearable={false}
                options={[]}
                className="react-select"
                classNamePrefix="select"
                onChange={(data) => {}}
              />
            </Col>
            <Col sm={12} md={6}>
              <Label for="role-select">Filter by Agreement</Label>
              <Select className="react-select" classNamePrefix="select" />
            </Col>
          </Row>

          <div className="overflow-hidden mt-5">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
              <DataTable
                subHeader
                sortServer
                pagination
                responsive
                paginationServer
                striped
                columns={columns}
                // onSort={handleSort}
                sortIcon={<ChevronDown />}
                className="react-dataTable react-dataTable-selectable-rows"
                paginationComponent={CustomPagination}
                data={agreements}
                progressComponent={<TableLoading rows={1} cols={7} />}
                // selectableRowsComponent={BootstrapCheckbox}
                subHeaderComponent={
                  <CustomHeader
                    data={agreements}
                    // searchTerm={searchTerm}
                    rowsPerPage={rowsPerPage}
                    // handleFilter={handleFilter}
                    // handlePerPage={handlePerPage}
                    // toggleSidebar={toggleSidebar}
                  />
                }
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default agreementDetail;
