import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardText,
  Button,
  Alert,
  Row,
  Col,
  CardTitle,
} from "reactstrap";
import { Star, X, ShoppingCart, Info, LogIn, MapPin } from "react-feather";
import { useEffect } from "react";
import { getAllBuildings, getData } from "../../../redux/building";

import "@styles/base/pages/app-ecommerce.scss";
import { loginBuilding } from "../../../redux/home";
import themeConfig from "../../../configs/themeConfig";
import ReactPaginate from "react-paginate";
import { EyeFill, Eyeglasses, Map } from "react-bootstrap-icons";
import { useContext } from "react";
import { CurrentBuildingContext } from "../../../contexts/currentBuilding";
import { getCompanyDashboard } from "../../../redux/dashboard";
import StatsVertical from "@components/widgets/stats/StatsVertical";
import { Bar } from "react-chartjs-2";
import { CategoryScale, Chart, registerables } from "chart.js";

import Skeleton from "react-loading-skeleton";
import { AbilityContext, Can } from "../../../utility/context/Can";
import { getFirstAvailableRoute } from "../../../utility/Utils";

const BuildingsView = () => {
  const store = useSelector((state) => state.buildings);
  const CurrentBuilding = useSelector((state) => state.CurrentBuilding);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buildings, setBuildings] = useState([]);

  const ability = useContext(AbilityContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const { currentBuilding, logInBuilding } = useContext(CurrentBuildingContext);

  const loginIntoBuilding = (building) => {
    logInBuilding(building);
    dispatch(loginBuilding(building));
    navigate("/dashboard");
  };

  useEffect(() => {
    dispatch(getAllBuildings("company"));
  }, []);

  useEffect(() => {
    setBuildings(
      store?.data?.filter((building) => ability.can("read", building?._id)) ||
        []
    );
  }, [store]);

  useEffect(() => {
    console.log(buildings);

    if (buildings.length === 1) {
      loginIntoBuilding(buildings[0]);
      navigate(getFirstAvailableRoute(ability, buildings[0]._id, "building"));
    }
  }, [buildings]);

  const renderBuilding = () => {
    return buildings?.map((item) => {
      return (
        <Can I="read" this={item._id}>
          <Card className="ecommerce-card" key={item._id}>
            <img
              className="card-img-top"
              src={themeConfig.serverUrl + "uploads/" + item.thumbnail}
              style={{ width: "400px", height: "300px" }}
              alt="Featured Image"
            />

            <CardBody>
              <div className="item-name">
                <h4>{item.name}</h4>
                <p>
                  <MapPin size={"12px"} /> {item.location.address}
                </p>
              </div>
            </CardBody>
            <div className="item-options text-center">
              <Button
                color="primary"
                className="btn-cart move-cart"
                onClick={() => loginIntoBuilding(item)}
              >
                <LogIn className="me-50" size={14} />
                <span>Select</span>
              </Button>
              <NavLink
                to={`/properties/${item._id}?view=${appView}`}
                className="btn btn-primary btn-cart move-cart"
              >
                <EyeFill className="me-50" size={14} />
                <span>View</span>
              </NavLink>
            </div>
          </Card>
        </Can>
      );
    });
  };

  const GenerateLoadingSkeleton = () => {
    return Array(3)
      .fill()
      .map((item) => (
        //  <section className="grid-view wishlist-items " key={item}>
        <Card className="ecommerce-card" key={`item_${item}`}>
          <div className="item-img text-center mx-auto">
            <Skeleton height={280} width={350} />
          </div>
          <CardBody>
            <div className="item-name">
              <h4>
                <Skeleton width={200} />
              </h4>
              <p>
                <Skeleton width={250} />
              </p>
            </div>
          </CardBody>
          <div className="item-options text-center">
            <Skeleton width={340} height={40} />
          </div>
        </Card>
        // </section>
      ));
  };

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  if (store.loading) {
    return (
      <div className="ecommerce-application">
        <div className="grid-view wishlist-items">
          {GenerateLoadingSkeleton()}
        </div>
      </div>
    );
  }

  return (
    <div className="ecommerce-application">
      {store.data.length ? (
        <>
          <section className="grid-view wishlist-items">
            {renderBuilding()}
          </section>
          <CustomPagination />
        </>
      ) : (
        <Alert color="info">
          <div className="alert-body">
            <Info size={14} />
            <span className="align-middle ms-50">No Building Found.</span>
            <a href="/property/create">Create Your First Building</a>
          </div>
        </Alert>
      )}
    </div>
  );
};

export default BuildingsView;
