import React from "react";
import { useDispatch } from "react-redux";
import { loginBuilding } from "../redux/home";

export const CurrentBuildingContext = React.createContext();

export default function CurrentBuildingProvider({ children }) {
  const [building, setBuilding] = React.useState();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const load = () => {
      let building = localStorage.getItem("HoyCurrentBuilding");

      if (building) {
        building = building ? JSON.parse(building) : null;
        setBuilding(building);
        dispatch(loginBuilding(building));
      }
    };
    load();
  }, []);

  const logInBuilding = (building) => {
    localStorage.setItem("HoyCurrentBuilding", JSON.stringify(building));
    setBuilding(building);
  };

  const logOutBuilding = (building) => {
    localStorage.removeItem("HoyCurrentBuilding");
    setBuilding(null);
  };

  return (
    <CurrentBuildingContext.Provider
      value={{ currentBuilding: building, logInBuilding, logOutBuilding }}
    >
      {children}
    </CurrentBuildingContext.Provider>
  );
}
