// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios, { AxiosError } from "axios";

export const getPropertyDashboard = createAsyncThunk(
  "dashboard/property",
  async (_, { getState }) => {
    let id = getState().CurrentBuilding?.data?._id;

    if (!id) {
      return rejectWithValue("No Property Selected");
    }

    const response = await axios.get(`/dashboard/property/${id}`);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }

    return response.data.data;
  }
);
export const getServiceDashboard = createAsyncThunk(
  "dashboard/service",
  async (service, { getState }) => {
    const response = await axios.get(`/dashboard/service/${service}`);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }

    return { service, data: response.data.data };
  }
);

export const getCompanyDashboard = createAsyncThunk(
  "dashboard/company",
  async () => {
    const response = await axios.get(`/dashboard/company`);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }

    return { data: response.data.data };
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    property: null,
    company: null,
    maintainance: null,
    salesAndBroker: null,
    consultation: null,
    loading: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getPropertyDashboard.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPropertyDashboard.fulfilled, (state, action) => {
        state.property = action.payload;
        state.company = null;
        if (action.payload.service == "maintainance") {
          state.maintainance = action.payload.data;
          state.salesAndBroker = null;
          state.consultation = null;
        } else if (action.payload.service == "salesAndBroker") {
          state.maintainance = null;
          state.salesAndBroker = action.payload.data;
          state.consultation = null;
        } else if (action.payload.service == "consultation") {
          state.maintainance = null;
          state.salesAndBroker = null;
          state.consultation = action.payload.data;
        }

        state.loading = false;
        state.error = null;
      })
      .addCase(getPropertyDashboard.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload ?? "error occured";
      })
      .addCase(getServiceDashboard.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getServiceDashboard.fulfilled, (state, action) => {
        state.property = null;
        state.company = null;
        if (action.payload.service == "maintainance") {
          state.maintainance = action.payload.data;
          state.salesAndBroker = null;
          state.consultation = null;
        } else if (action.payload.service == "salesAndBroker") {
          state.maintainance = null;
          state.salesAndBroker = action.payload.data;
          state.consultation = null;
        } else if (action.payload.service == "consultation") {
          state.maintainance = null;
          state.salesAndBroker = null;
          state.consultation = action.payload.data;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(getServiceDashboard.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload ?? "error occured";
      })
      .addCase(getCompanyDashboard.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyDashboard.fulfilled, (state, action) => {
        state.property = null;
        state.company = action.payload.data;
        state.maintainance = null;
        state.salesAndBroker = null;
        state.consultation = null;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCompanyDashboard.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload ?? "error occured";
      });
  },
});

export const {} = dashboardSlice.actions;
export default dashboardSlice.reducer;
