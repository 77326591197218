// ** React Imports
import { useEffect, useState } from "react";

// ** Third Party Components
import axios from "axios";
import Chart from "react-apexcharts";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  CardTitle,
} from "reactstrap";
import moment from "moment/moment";

var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];


const SalesRevenueReport = ({ paid=[], unpaid=[], colors }) => {

  let months = [
    ...paid?.map((pd) => pd?._id),
    ...unpaid?.map((upd) => upd?._id),
  ];
  months.sort((a, b) => a - b);
  months = [...new Set(months)];

  const InvoiceOptions = {
    chart: {
      stacked: true,
      type: "bar",
      toolbar: { show: false },
    },
    grid: {
      padding: {
        top: -20,
        bottom: -10,
      },
      yaxis: {
        lines: { show: false },
      },
    },
    xaxis: {
      categories: months.map((m)=>monthShortNames[m-1]),
      labels: {
        style: {
          colors: "#b9b9c3",
          fontSize: "0.86rem",
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "10%",
        borderRadius: [5],
      },
      distributed: true,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#b9b9c3",
          fontSize: "0.86rem",
        },
      },
    },
  },
    InvoiceSeries = [
      {
        name: "Paid",
        data: months.map(
          (month) =>
            paid?.find((pd) =>pd?._id == month)?.total ?? 0
        ),
      },
      {
        name: "Unpaid",
        data: months.map(
          (month) =>
             unpaid?.find((upd) =>upd?._id == month)?.total ?? 0
        ),
      },
    ];

  return (
    <Card className="card-revenue-budget">
      <Row className="mx-0">
        <Col className="revenue-report-wrapper" md="12" xs="12">
          <div className="d-sm-flex justify-content-between align-items-center mb-3">
            <CardTitle className="mb-50 mb-sm-0">Invoice Report By Month</CardTitle>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center me-2">
                <span className="bullet bullet-success me-50 cursor-pointer"></span>
                <span>Paid</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet bullet-warning me-50 cursor-pointer"></span>
                <span>Unpaid</span>
              </div>
            </div>
          </div>
          <Chart
            id="revenue-report-chart"
            type="bar"
            height="230"
            options={InvoiceOptions}
            series={InvoiceSeries}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SalesRevenueReport;
