import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ApiErrorHandler } from "../utility/error";
import { BASE_URL, getToken } from "../utility/Utils";

export const shiftApi = createApi({
  reducerPath: "shifts",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL, // this base_Url is not found.
    prepareHeaders: (headers, { getState }) => {
      // Get the JWT token from the auth state
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Shifts"],
  endpoints: (builder) => ({
    // create shift
    createShift: builder.mutation({
      query: (body) => ({ url: "/hrm/shifts/", method: "POST", body }),
      invalidatesTags: ["Shifts"],
    }),

    // get all shift
    getShifts: builder.query({
      query: ({ page = 1, perPage = 10, keyword = "" }) => {
        const params = new URLSearchParams();
        if (keyword || keyword !== "") {
          // build query string
          params.append("search", keyword);
          params.append("fields[0]", "name");
          params.append("fields[1]", "description");
          // &options[page]=${page}&options[limit]=${perPage}
          params.append("options[page]", page);
          params.append("options[limit]", perPage);
          params.append("options[populate][0][dir]", "users");
          params.append("options[populate][0][path]", "createdBy");
          params.append("options[populate][0][select]", "username");

          return `/hrm/shifts?${params.toString()}`;
        }
        params.append("options[page]", page);
        params.append("options[limit]", perPage);
        params.append("options[populate][0][dir]", "users");
        params.append("options[populate][0][path]", "createdBy");
        params.append("options[populate][0][select]", "username");

        return `/hrm/shifts?${params.toString()}`;
      },

      providesTags: (result, error) => {
        console.log("result == ", result);
        if (error) {
          ApiErrorHandler(error);
          return [];
        }

        const { data: shifts } = result;
        console.log("Shift !! ", shifts);
        if (Array.isArray(shifts) || shifts.docs) {
          let docs = Array.isArray(shifts) ? shifts : shifts.docs;
          console.log("Docs: ", docs);
          return [
            ...docs.map(({ _id }) => ({ type: "Shifts", id: _id })),
            { type: "Shifts", id: 1 },
          ];
        }
        return [{ type: "Shifts", id: 1 }];
      },
    }),

    // get shift by id or search
    getShiftById: builder.query({
      query: (id) => `/hrm/shifts/${id}`,
      providesTags: (result, error, id) => {
        if (error) {
          ApiErrorHandler(error);
          return [];
        }
        return [{ type: "Shifts", id: result?.data?._id }];
      },
    }),

    // update shift by id
    updateShift: builder.mutation({
      query: (body) => ({
        url: `/hrm/shifts/${body._id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { _id }) => {
        if (error) {
          ApiErrorHandler(error);
          return [];
        }
        return [{ type: "Shifts", id: _id }];
      },
    }),

    // delete shift by id
    deleteShift: builder.mutation({
      query: (id) => ({ url: `/hrm/shifts/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => {
        if (error) {
          ApiErrorHandler(error);
          return [];
        }
        return [{ type: "Shifts", id }];
      },
    }),
  }),
});

export const {
  useGetShiftsQuery,
  useDeleteShiftMutation,
  useGetShiftByIdQuery,
  useUpdateShiftMutation,
  useCreateShiftMutation,
} = shiftApi;
