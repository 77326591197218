import React from "react";
import { useSearchParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Table
} from "reactstrap";
import { ErrorHandler } from "../../common/utils/Error";

import Logo from "@src/assets/images/logo/logo-icon.png";
import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FileExcel, Printer } from "react-bootstrap-icons";
import { AlertTriangle } from "react-feather";
import { useReactToPrint } from "react-to-print";
import {
  convertDaysToMonthsAndYears,
  formatCurrency,
} from "../../utility/Utils";

export default function LeasesReports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [reportDate, setReportDate] = useState(new Date().now);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Leases Report`,
    pageStyle: `
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always !important;
      }
      .card{
        box-shadow:none !important;
      }
    }
    
    @page {
      size: auto;
    }
    `,
    bodyClass: "",
    // removeAfterPrint: true,
  });

  const queryFields = {
    status: searchParams.get("type") ?? "",
  };

  const getLeasesReport = async () => {
    try {
      const { status, data } = await axios.get("/reports/leases", {
        params: queryFields,
      });
      if (status == 200) {
        setData(data.data);
      } else {
        toast.error("Error Detected");
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    getLeasesReport();
  }, []);

  console.log("defaultValues : ", queryFields);

  return (
    <div>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h4 className="text-capitalize">
            {queryFields.status} Leases Report
          </h4>
          {/* <Button outline color="secondary" id="toggler" className="btn-icon">
            <Filter size={14} />
          </Button> */}
        </CardHeader>

        <CardHeader>
          {/* <UncontrolledCollapse toggler="#toggler"></UncontrolledCollapse> */}
        </CardHeader>
      </Card>
      <Card innerRef={printRef} dir="ltr">
        <CardHeader className="justify-content-end gap-1 mb-0 pb-0 no-print">
          <Printer
            size={23}
            className="cursor-pointer"
            title="Print"
            onClick={() => handlePrint()}
          />
          <FileExcel
            size={23}
            className="cursor-pointer"
            title="Export to Excell"
            onClick={() => {
              // beutify before print
              // downloadCSV(data)
            }}
          />
        </CardHeader>

        <CardHeader className="justify-content-between">
          <img src={Logo} alt="" width={70} />
          <div>
            <h3>Leases Report</h3>
            <p>{moment(reportDate).format("DD MMMM YYYY")}</p>
          </div>
        </CardHeader>
        <hr />
        <CardBody>
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Unit</th>
                <th>Rent</th>
                <th>
                  {queryFields.status == "move-outs"
                    ? "Move Out Date"
                    : " Moved In"}
                </th>
                <th>Remaining</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.user?.name}</td>
                  <td>{item.user?.phone}</td>
                  <td>{item.unit?.name}</td>
                  <td>
                    {formatCurrency(item.amount)}/{item.recursion} Months
                  </td>
                  <td>
                    {queryFields.status == "move-outs"
                      ? moment(item.endDate).format("DD/MM/YYYY")
                      : moment(item.agreementStartDate).format("DD/MM/YYYY")}
                  </td>
                  <td>
                    <div
                      className={classNames("fw-bolder", {
                        "text-danger":
                          moment(item.endDate).diff(moment()) < 0 &&
                          item.status == "Active",
                      })}
                    >
                      {moment(item.endDate).diff(moment()) < 0 &&
                      item.status != "Active" ? (
                        0
                      ) : item.status == "Terminated" ? (
                        0
                      ) : moment(item.endDate).diff(moment()) < 0 ? (
                        <>
                          <AlertTriangle
                            size={14}
                            className="text-danger align-middle me-1"
                          />
                          Expired
                          {/* {moment().to(moment(item.endDate))} */}
                        </>
                      ) : (
                        <>
                          {" "}
                          {convertDaysToMonthsAndYears(
                            moment(item.endDate).diff(moment(), "days")
                          )}{" "}
                        </>
                      )}
                      {/* {moment(row.endDate).format("DD MMM YYYY") ?? ""} */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={"100%"} className="fw-bolder">
                  Total Entries : {data.length}
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
