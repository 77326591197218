// ** React Imports
import { Link, useNavigate } from "react-router-dom";

// ** Custom Components
import Avatar from "@components/avatar";

// ** Third Party Components
import { Power, User } from "react-feather";

// ** Reactstrap Imports
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/logo/profile.png";
import { handleLogout } from "@store/authentication";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { CurrentBuildingContext } from "../../../../contexts/currentBuilding";
import { exitBuilding } from "../../../../redux/home";
import {
  getHomeRouteForLoggedInUser,
  getUserData,
} from "../../../../utility/Utils";
import { AbilityContext } from "../../../../utility/context/Can";

const UserDropdown = () => {
  const { currentBuilding, logOutBuilding } = useContext(
    CurrentBuildingContext
  );

  const ability = useContext(AbilityContext);

  const user = getUserData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = (e) => {
    e.preventDefault();
    if (currentBuilding) {
      dispatch(exitBuilding());
      logOutBuilding();
    }

    dispatch(handleLogout());
    navigate("/login");
  };

  const handleExitBuilding = (e) => {
    e.preventDefault();
    dispatch(exitBuilding());
    logOutBuilding();
    navigate(getHomeRouteForLoggedInUser(user.role, ability));
  };

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">{user?.name}</span>
          <span className="user-status">{user?.role?.name}</span>
        </div>
        <Avatar
          img={defaultAvatar}
          imgHeight="40"
          imgWidth="40"
          status="online"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <User size={14} className="me-75" />
          <span className="align-middle">Profile</span>
        </DropdownItem>
        {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <Mail size={14} className="me-75" />
          <span className="align-middle">Inbox</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <CheckSquare size={14} className="me-75" />
          <span className="align-middle">Tasks</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <MessageSquare size={14} className="me-75" />
          <span className="align-middle">Chats</span>
        </DropdownItem> */}
        {/* <DropdownItem divider /> */}
        {/* <DropdownItem
          tag={Link}
          to="/pages/"
          onClick={(e) => e.preventDefault()}
        >
          <Settings size={14} className="me-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <CreditCard size={14} className="me-75" />
          <span className="align-middle">Pricing</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <HelpCircle size={14} className="me-75" />
          <span className="align-middle">FAQ</span>
        </DropdownItem> */}

        {currentBuilding && (
          <DropdownItem tag={Link} onClick={handleExitBuilding}>
            <Power size={14} className="me-75" />
            <span className="align-middle">Exit Building</span>
          </DropdownItem>
        )}

        <DropdownItem tag={Link} onClick={handleSignOut}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
