import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Badge,
} from "reactstrap";
// ** Icons Imports
import {
  Briefcase,
  ChevronDown,
  MoreVertical,
  Archive,
  Trash2,
  FileText,
  LogIn,
  Printer,
  Share,
  Grid,
  Copy,
  File,
} from "react-feather";
import { Fragment } from "react";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import ReactPaginate from "react-paginate";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-hot-toast";
import moment from "moment/moment";
import {
  PencilFill,
  PencilSquare,
  PersonAdd,
  BuildingAdd,
  Stopwatch,
  PlusCircleDotted,
} from "react-bootstrap-icons";
import DepartmentModel from "./DepartmentModel";
import {
  deleteDepartment,
  getAllDepartments,
  getData,
  selectDepartment,
} from "../../../../redux/departments";
import HandleDispatch from "../../../../common/utils/HandledDispatch";
import Loading from "../../../loading";

const MySwal = withReactContent(Swal);

export default function Departments() {
  const [showmodal, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const store = useSelector((state) => state.departments);

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Delete Department ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await HandleDispatch(
          dispatch,
          deleteDepartment(id)
        );
        if (responseStatus == 204) {
          toast.success("Department Has been Deleted.");
        }
      }
    });
  };
  const columns = [
    {
      name: "Department Name",
      sortable: true,
      sortField: "name",
      selector: (row) => row.name ?? "",
      cell: (row) => (
        <div className="">
          <span className="fw-bolder">{row.name ?? ""}</span>
        </div>
      ),
    },
    {
      name: "User",
      minWidth: "100px",
      sortable: true,
      sortField: "user",
      selector: (row) => row.createdBy,
      cell: (row) => <span className="">{row.createdBy?.username}</span>,
    },
    {
      name: "Date",
      minWidth: "100px",
      sortable: true,
      sortField: "date",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <span className="text-capitalize">
          {moment(row.createdAt).format("DD MMM YYYY")}
        </span>
      ),
    },
    {
      name: "Status",
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <Badge
          color={row.status == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },
    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="column-action">
          <Button.Ripple
            className="btn-icon"
            size="sm"
            color="flat-primary"
            onClick={(e) => {
              dispatch(selectDepartment(row));
              setModalShow(true);
            }}
          >
            <PencilFill size={15} />
          </Button.Ripple>
          <Button.Ripple
            className="btn-icon"
            size="sm"
            color="flat-danger"
            onClick={(e) => {
              handleConfirmDelete(row._id, row.name);
            }}
          >
            <Trash2 size={15} />
          </Button.Ripple>
        </div>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };

  const dataToRender = () => {
    console.log(store.data);
    return store.data;
  };

  const CustomHeader = ({
    store,
    toggleSidebar,
    handlePerPage,
    rowsPerPage,
    handleFilter,
    searchTerm,
  }) => {
    // ** Converts table to CSV
    function convertArrayOfObjectsToCSV(array) {
      let result;

      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(store.data[0]);

      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach((item) => {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key];

          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    }
    // ** Downloads CSV
    function downloadCSV(array) {
      const link = document.createElement("a");
      let csv = convertArrayOfObjectsToCSV(array);
      if (csv === null) return;

      const filename = "export.csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    }

    return (
      <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
        <Row>
          <Col xl="6" className="d-flex align-items-center p-0">
            <div className="d-flex align-items-center w-100">
              <label htmlFor="rows-per-page">Show</label>
              <Input
                className="mx-50"
                type="select"
                id="rows-per-page"
                value={rowsPerPage}
                onChange={handlePerPage}
                style={{ width: "5rem" }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="50">100</option>
              </Input>
              <label htmlFor="rows-per-page">Entries</label>
            </div>
          </Col>
          <Col
            xl="6"
            className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
          >
            <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
              <label className="mb-0" htmlFor="search-invoice">
                Search:
              </label>
              <Input
                id="search-invoice"
                className="ms-50 w-100"
                type="text"
                value={searchTerm}
                // onChange={(e) => handleFilter(e.target.value)}
              />
            </div>

            <div className="d-flex align-items-center table-header-actions">
              <UncontrolledDropdown className="me-1">
                <DropdownToggle color="secondary" caret outline>
                  <Share className="font-small-4 me-50" />
                  <span className="align-middle">Export</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="w-100">
                    <Printer className="font-small-4 me-50" />
                    <span className="align-middle">Print</span>
                  </DropdownItem>
                  <DropdownItem
                    className="w-100"
                    onClick={() => downloadCSV(store.data)}
                  >
                    <FileText className="font-small-4 me-50" />
                    <span className="align-middle">CSV</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <Grid className="font-small-4 me-50" />
                    <span className="align-middle">Excel</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <File className="font-small-4 me-50" />
                    <span className="align-middle">PDF</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <Copy className="font-small-4 me-50" />
                    <span className="align-middle">Copy</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const ToggleModal = (show) => {
    if (typeof show == "boolean") {
      setModalShow(show);
    } else {
      setModalShow(!showmodal);
    }
  };

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    dispatch(
      getData({
        limit: value,
        page: currentPage,
      })
    );
    setRowsPerPage(value);
  };

  useEffect(() => {
    dispatch(getAllDepartments());
  }, []);

  return (
    <Fragment>
      <Card className="overflow-hidden">
        <CardHeader className="d-flex align-items-start">
          <CardTitle tag="h4">Departments Information</CardTitle>
          <DepartmentModel show={showmodal} ShowModal={ToggleModal} />
          <Button
            className=""
            color="primary"
            onClick={() => setModalShow(true)}
          >
            <BuildingAdd className="me-1" size={20} /> Add Department
          </Button>
        </CardHeader>
        <div className="react-dataTable">
          {store?.loading ? (
            <Loading cols={columns} />
          ) : (
            <DataTable
              noHeader
              subHeader
              sortServer
              pagination
              responsive
              paginationServer
              columns={columns}
              // onSort={handleSort}
              sortIcon={<ChevronDown />}
              className="react-dataTable"
              paginationComponent={CustomPagination}
              data={dataToRender()}
              subHeaderComponent={
                <CustomHeader
                  store={store}
                  // searchTerm={searchTerm}
                  rowsPerPage={rowsPerPage}
                  // handleFilter={handleFilter}
                  handlePerPage={handlePerPage}
                  // toggleSidebar={toggleSidebar}
                />
              }
            />
          )}
        </div>
      </Card>
    </Fragment>
  );
}
