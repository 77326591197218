import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Spinner,
} from "reactstrap";
import CreatableSelect from "react-select";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import toast from "react-hot-toast";
import { updateStaff } from "../../../../redux/staff";
import axios from "axios";
import moment from "moment";
import HandleDispatch from "../../../../common/utils/HandledDispatch";
import { ErrorHandler } from "../../../../common/utils/Error";

const Detail = ({ store }) => {
  const [loading, setLoading] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);

  const dispatch = useDispatch();

  const defaultValues = {
    id: store?.data?.staffInfo?._id || "",
    name: store?.data?.staffInfo?.name || "",
    gender: store?.data?.staffInfo?.gender || "",
    dateOfBirth:
      moment(store?.data?.staffInfo?.dateOfBirth).format("YYYY-MM-DD") || "",
    email: store?.data?.staffInfo?.email || "",
    contacts: store?.data?.staffInfo?.contacts || "",
    shift: store?.data?.staffInfo?.shift || "",
    emergencyContactName: store?.data?.staffInfo?.emergencyContactName || "",
    motherName: store?.data?.staffInfo?.motherName || "",
    placeOfBirth: store?.data?.staffInfo?.placeOfBirth || "",
    address: store?.data?.staffInfo?.address || "",
    job: store?.data?.staffInfo?.job || "",
    monthlySalary: store?.data?.staffInfo?.monthlySalary || "",
    emergencyContact: store?.data?.staffInfo?.emergencyContact || "",
    photo: store?.data?.staffInfo?.photo || "",
    documents: store?.data?.staffInfo?.documents || "",
    department: store?.data?.staffInfo?.department || "",
    hireDate:
      moment(store?.data?.staffInfo?.hireDate).format("YYYY-MM-DD") || "",
    user: store?.data?.staffInfo?.user || "",
    status: store?.data?.staffInfo?.status || "",
  };

  const getAllJobs = async () => {
    try {
      const { data, status } = await axios.get("/hrm/jobs", {
        params: {
          options: {
            select: "name",
          },
        },
      });

      if (status == 200) {
        var jobList = data.data.docs.map((job) => ({
          label: job.name,
          value: job._id,
        }));
        setJobs(jobList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };
  const getAllShifts = async () => {
    try {
      const { data, status } = await axios.get("/hrm/shifts", {
        params: {
          options: {
            select: "name",
          },
        },
      });

      if (status == 200) {
        var shiftList = data.data.docs.map((shift) => ({
          label: shift.name,
          value: shift._id,
        }));
        setShifts(shiftList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };
  const getAllDepartments = async () => {
    try {
      const { data, status } = await axios.get("/hrm/departments", {
        params: {
          options: {
            select: "name",
          },
        },
      });

      if (status == 200) {
        var departmentList = data.data.docs.map((department) => ({
          label: department.name,
          value: department._id,
        }));
        setDepartments(departmentList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const {
    register,
    reset,
    getValues,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      if (data.status) data.status = data.status.value;
      if (data.job) data.job = data.job.value;
      if (data.shift) data.shift = data.shift.value;
      if (data.department) data.department = data.department.value;
      if (data.gender) data.gender = data.gender.value;

      const updateResponse = await HandleDispatch(
        dispatch,
        updateStaff({ id: data.id, data })
      );

      if (updateResponse) {
        toast.success("Staff Information updated Successfully");
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllJobs();
    getAllShifts();
    getAllDepartments();
  }, []);

  useEffect(() => {
    const statusMapping = {
      active: { label: "Active", value: "active" },
      inactive: { label: "Inactive", value: "inactive" },
    };

    const genderMapping = {
      male: { label: "Male", value: "male" },
      female: { label: "Female", value: "female" },
    };

    const mapValue = (sourceValue, mapping) => {
      return mapping[sourceValue] || {};
    };

    let values = {
      ...defaultValues,
      status: mapValue(defaultValues?.status, statusMapping),
      gender: mapValue(defaultValues?.gender, genderMapping),
      job: jobs.find((job) => job.value === defaultValues?.job),
      department: departments.find(
        (department) => department.value === defaultValues?.department
      ),
      shift: shifts.find((shift) => shift.value === defaultValues?.shift),
    };

    reset(values);
  }, [store, shifts, jobs, departments]);

  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">Staff's Detail</CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody>
              <Row className="gy-2">
                <Col sm={12} md={12}>
                  <Label className="form-label" for="name">
                    Name
                  </Label>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="name"
                        placeholder="Name"
                        invalid={errors.name && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.name && (
                    <FormFeedback>Please enter a valid Name</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={12}>
                  <Label className="form-label" for="motherName">
                    Mother Name
                  </Label>
                  <Controller
                    name="motherName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="motherName"
                        placeholder="Mother Name"
                        invalid={errors.motherName && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.motherName && (
                    <FormFeedback>Please enter a valid Email</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="email">
                    Email
                  </Label>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="email"
                        placeholder="Email"
                        invalid={errors.email && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.email && (
                    <FormFeedback>Please enter a valid Email</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="contacts">
                    Phone
                  </Label>
                  <Controller
                    name="contacts"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="contacts"
                        type="text"
                        placeholder="Phone"
                        invalid={errors.contacts && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.contacts && (
                    <FormFeedback>Please enter a valid Phone</FormFeedback>
                  )}
                </Col>{" "}
                <Col sm={12} md={6}>
                  <Label className="form-label" for="placeOfBirth">
                    Place Of Birth
                  </Label>
                  <Controller
                    name="placeOfBirth"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="placeOfBirth"
                        placeholder="Place Of Birth"
                        invalid={errors.placeOfBirth && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.placeOfBirth && (
                    <FormFeedback>Please enter a valid Name</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="dateOfBirth">
                    Date Of Birth
                  </Label>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="dateOfBirth"
                        type="date"
                        placeholder="Date Of Birth"
                        invalid={errors.dateOfBirth && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.dateOfBirth && (
                    <FormFeedback>Please enter a valid Date</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={12}>
                  <Label className="form-label" for="address">
                    Address
                  </Label>
                  <Controller
                    name="address"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="address"
                        type="text"
                        placeholder="Address"
                        invalid={errors.address && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.address && (
                    <FormFeedback>Please enter a valid Address</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="emergencyContactName">
                    Emergency Name
                  </Label>
                  <Controller
                    name="emergencyContactName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="emergencyContactName"
                        type="text"
                        placeholder="Emergency Name"
                        invalid={errors.emergencyContactName && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.emergencyContactName && (
                    <FormFeedback>Please enter a valid Name</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="emergencyContact">
                    Emergency Phone
                  </Label>
                  <Controller
                    name="emergencyContact"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="emergencyContact"
                        type="text"
                        placeholder="Emergency Phone"
                        invalid={errors.emergencyContact && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.emergencyContact && (
                    <FormFeedback>Please enter a valid Phone</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="job">
                    Select Job
                  </Label>
                  <Controller
                    name="job"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CreatableSelect
                        id="job"
                        className={"react-select"}
                        classNamePrefix="select"
                        aria-invalid={errors.job && true}
                        placeholder="Choose Job"
                        options={jobs}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="shift">
                    Select Shift
                  </Label>
                  <Controller
                    name="shift"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CreatableSelect
                        id="shift"
                        className={"react-select"}
                        classNamePrefix="select"
                        aria-invalid={errors.shift && true}
                        placeholder="Choose shift"
                        options={shifts}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="department">
                    Select Department
                  </Label>
                  <Controller
                    name="department"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CreatableSelect
                        id="department"
                        className={"react-select"}
                        classNamePrefix="select"
                        aria-invalid={errors.department && true}
                        placeholder="Choose department"
                        options={departments}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="hireDate">
                    Hire Date
                  </Label>
                  <Controller
                    name="hireDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="hireDate"
                        type="date"
                        placeholder="Hire Date"
                        invalid={errors.hireDate && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.hireDate && (
                    <FormFeedback>Please enter a valid Date</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="monthlySalary">
                    Monthly Salary
                  </Label>
                  <Controller
                    name="monthlySalary"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="monthlySalary"
                        placeholder="Monthly Salary"
                        invalid={errors.monthlySalary && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.monthlySalary && (
                    <FormFeedback>Please enter a valid Email</FormFeedback>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Label className="form-label" for="status">
                    Select Status
                  </Label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <CreatableSelect
                        id="status"
                        className={"react-select"}
                        classNamePrefix="select"
                        aria-invalid={errors.status && true}
                        placeholder="Choose status"
                        options={[
                          { label: "Active", value: "active" },
                          { label: "InActive", value: "inactive" },
                        ]}
                        {...field}
                      />
                    )}
                  />
                </Col>
              </Row>

              <div className="text-center mt-3 mb-1">
                <Button color="success" type="submit" disabled={loading}>
                  <Spinner
                    color="light"
                    size="sm"
                    className="me-1"
                    hidden={!loading}
                  />
                  {loading ? "Saving..." : "Save Changes"}
                </Button>
              </div>
            </CardBody>
          </form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Detail;
