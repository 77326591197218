// ** React Imports
import { Fragment, useState, useEffect } from "react";

// ** Custom Components
import Sidebar from "@components/sidebar";
import Repeater from "@components/repeater";

// ** Third Party Components
import axios from "axios";
import Flatpickr from "react-flatpickr";
import { SlideDown } from "react-slidedown";
import { X, Plus, Hash, RefreshCw } from "react-feather";
import Select, { components } from "react-select";

// ** Reactstrap Imports
import { selectThemeColors } from "@utils";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Label,
  Button,
  CardBody,
  CardText,
  InputGroup,
  InputGroupText,
  FormFeedback,
} from "reactstrap";

// ** Styles
import "react-slidedown/lib/slidedown.css";
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/react/libs/flatpickr/flatpickr.scss";
import "@styles/base/pages/app-invoice.scss";
import { ErrorHandler } from "../../../common/utils/Error";
import { toast } from "react-hot-toast";
import { Controller, useFieldArray } from "react-hook-form";
import AlertValidation from "../../../common/utils/components/ValidationError";
import { formatCurrency } from "../../../utility/Utils";
import { useSearchParams } from "react-router-dom";

const AddCard = ({
  reset,
  control,
  register,
  clearErrors,
  getValues,
  setValue,
  setError,
  trigger,
  handleSubmit,
  errors,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  // const [value, setValue] = useState({})
  const [open, setOpen] = useState(false);
  const [clients, setClients] = useState(null);
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);

  const [properties, setProperties] = useState(null);
  const [propertiesOptions, setPropertiesOptions] = useState([]);
  const getClients = async () => {
    try {
      const response = await axios.get("/customers/list");
      if (response.status == 200) {
        setClients(response.data.data);
        setOptions([
          ...response.data.data?.map((cl) => ({
            value: cl._id,
            label: cl.name,
          })),
        ]);
      } else {
        toast.error(response.data.message ?? "Customers not found");
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const getProperties = async () => {
    try {
      const response = await axios.get("/buildings/list");
      if (response.status == 200) {
        setProperties(response.data);
        console.log(response.data.data);
        setPropertiesOptions([
          ...response.data.data?.map((cl) => ({
            value: cl._id,
            label: cl.name,
            price: cl.price,
          })),
        ]);
      } else {
        toast.error(response.data.message ?? "Property not found");
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    getClients();
    getProperties();
  }, []);

  const note =
    "It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!";

  const {
    fields: items,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "items",
  });

  const getInvoiceTotal = () => {
    let items = getValues().items;
    return {
      total: items?.reduce((prev, curr) => prev + Number(curr.amount), 0) ?? 0,
      discount:
        items?.reduce((prev, curr) => prev + Number(curr.discount), 0) ?? 0,
    };
  };

  return (
    <Fragment>
      <Card className="invoice-preview-card">
        {/* Header */}
        <CardBody className="invoice-padding pb-0">
          <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            <div>
              <div className="logo-wrapper">
                <svg viewBox="0 0 139 95" version="1.1" height="24">
                  <defs>
                    <linearGradient
                      id="invoice-linearGradient-1"
                      x1="100%"
                      y1="10.5120544%"
                      x2="50%"
                      y2="89.4879456%"
                    >
                      <stop stopColor="#000000" offset="0%"></stop>
                      <stop stopColor="#FFFFFF" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient
                      id="invoice-linearGradient-2"
                      x1="64.0437835%"
                      y1="46.3276743%"
                      x2="37.373316%"
                      y2="100%"
                    >
                      <stop
                        stopColor="#EEEEEE"
                        stopOpacity="0"
                        offset="0%"
                      ></stop>
                      <stop stopColor="#FFFFFF" offset="100%"></stop>
                    </linearGradient>
                  </defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g transform="translate(-400.000000, -178.000000)">
                      <g transform="translate(400.000000, 178.000000)">
                        <path
                          className="text-primary"
                          d="M-5.68434189e-14,2.84217094e-14 L39.1816085,2.84217094e-14 L69.3453773,32.2519224 L101.428699,2.84217094e-14 L138.784583,2.84217094e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L6.71554594,44.4188507 C2.46876683,39.9813776 0.345377275,35.1089553 0.345377275,29.8015838 C0.345377275,24.4942122 0.230251516,14.560351 -5.68434189e-14,2.84217094e-14 Z"
                          style={{ fill: "currentColor" }}
                        ></path>
                        <path
                          d="M69.3453773,32.2519224 L101.428699,1.42108547e-14 L138.784583,1.42108547e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L32.8435758,70.5039241 L69.3453773,32.2519224 Z"
                          fill="url(#invoice-linearGradient-1)"
                          opacity="0.2"
                        ></path>
                        <polygon
                          fill="#000000"
                          opacity="0.049999997"
                          points="69.3922914 32.4202615 32.8435758 70.5039241 54.0490008 16.1851325"
                        ></polygon>
                        <polygon
                          fill="#000000"
                          opacity="0.099999994"
                          points="69.3922914 32.4202615 32.8435758 70.5039241 58.3683556 20.7402338"
                        ></polygon>
                        <polygon
                          fill="url(#invoice-linearGradient-2)"
                          opacity="0.099999994"
                          points="101.428699 0 83.0667527 94.1480575 130.378721 47.0740288"
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </svg>
                <h3 className="text-primary invoice-logo">RUKUN</h3>
              </div>
              <p className="card-text mb-25">
                Office 149, 450 South Brand Brooklyn
              </p>
              <p className="card-text mb-25">San Diego County, CA 91905, USA</p>
              <p className="card-text mb-0">
                +1 (123) 456 7891, +44 (876) 543 2198
              </p>
            </div>
            <div className="invoice-number-date mt-md-0 mt-2">
              <div className="d-flex align-items-center mb-1">
                <span className="title">Date:</span>
                <Controller
                  name={`date`}
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Flatpickr
                      // onChange={(date) => setPicker(date)}
                      className="form-control invoice-edit-input date-picker"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </CardBody>
        {/* /Header */}

        <hr className="invoice-spacing" />

        {/* Address and Contact */}
        <CardBody className="invoice-padding pt-0">
          <Row className="row-bill-to invoice-spacing">
            <Col className="col-bill-to ps-0" xl="8">
              <h6 className="invoice-to-title">Invoice To:</h6>
              <div className="invoice-customer">
                {selected == null ? (
                  <Controller
                    name="customer"
                    control={control}
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        className="react-select"
                        classNamePrefix="select"
                        id="customer"
                        // value={value}
                        options={options}
                        {...field}
                        // components={{
                        //   Option: OptionComponent
                        // }}

                        placeholder="Select Customer"
                        isLoading={!clients}
                        onChange={(value) => {
                          var client = clients.find(
                            (cl) => cl._id == value.value
                          );
                          setSelected(client);
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                ) : null}

                {clients !== null ? (
                  <Fragment>
                    {selected !== null ? (
                      <div className="customer-details mt-1">
                        <p className="mb-25">{selected.name}</p>
                        {/* <p className="mb-25">{selected.company}</p> */}
                        <p className="mb-25">{selected.address}</p>
                        <p className="mb-25">{selected.country}</p>
                        <p className="mb-0">{selected.phone}</p>
                        <p className="mb-0">{selected.companyEmail}</p>

                        <p
                          onClick={() => setSelected(null)}
                          className="cursor-pointer text-primary  mt-2"
                        >
                          <RefreshCw size={13} className="me-1" /> Choose
                          Different Customer
                        </p>
                      </div>
                    ) : null}
                  </Fragment>
                ) : null}
              </div>
            </Col>
          </Row>
        </CardBody>
        {/* /Address and Contact */}

        {/* Product Details */}
        <CardBody className="invoice-padding invoice-product-details">
          {items.map((item, i) => {
            const Tag = i === 0 ? "div" : SlideDown;

            return (
              <Tag key={item.id} className="my-1">
                <Row>
                  <Col
                    className="d-flex product-details-border position-relative pe-0"
                    sm="12"
                  >
                    <Row className="w-100 pe-lg-0 pe-1 py-2">
                      <Col className="mb-lg-0 mb-2 mt-lg-0 mt-2" lg="4" sm="12">
                        <label className="px-1 mb-1">Proprty</label>
                        <Controller
                          name={`items.${i}.property`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              // className="react-select"
                              classNamePrefix="select"
                              // id="property"
                              // value={value}
                              options={propertiesOptions}
                              // {...field}
                              placeholder="Select Property"
                              isLoading={!properties}
                              // onChange={(value) => {
                              //   var property = properties.find(
                              //     (cl) => cl._id == value.value
                              //   );
                              //   setSelectedProperty(property);
                              //   field.onChange(value);
                              // }}

                              onChange={(value) => {
                                console.log(value);

                                setValue(`items.${i}.property`, value);
                                trigger(`items.${i}.property`);
                                setValue(`items.${i}.amount`, value.price);
                                trigger(`items.${i}.amount`);

                                field.onChange(value);
                              }}
                            />
                          )}
                        />
                        <label className="px-1 mb-1">Description</label>
                        <Controller
                          name={`items.${i}.description`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Input
                              className=""
                              type="textarea"
                              rows="1"
                              invalid={
                                errors?.items?.length &&
                                errors?.items[i]?.description &&
                                true
                              }
                              {...field}
                            />
                          )}
                        />
                      </Col>
                      <Col className="my-lg-0 my-2" lg="3" sm="12">
                        <label className="px-1 mb-1">Price</label>
                        <Controller
                          name={`items.${i}.amount`}
                          control={control}
                          rules={{ required: true, min: 1 }}
                          render={({ field }) => (
                            <Input
                              type="number"
                              placeholder="amount"
                              innerRef={field.ref}
                              {...field}
                              invalid={
                                errors?.items?.length &&
                                errors?.items[i]?.amount &&
                                true
                              }
                              onChange={(value) => {
                                setValue(
                                  `items.${i}.total`,
                                  value.target.value
                                );
                                trigger(`items.${i}.total`);

                                field.onChange(value);
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col className="my-lg-0 my-2" lg="3" sm="12">
                        <label className="px-1 mb-1">Discount</label>

                        <Controller
                          name={`items.${i}.discount`}
                          control={control}
                          rules={{ required: true, min: 0 }}
                          render={({ field }) => (
                            <Input
                              type="number"
                              placeholder="discount"
                              innerRef={field.ref}
                              {...field}
                              invalid={
                                errors?.items?.length &&
                                errors?.items[i]?.discount &&
                                true
                              }
                              onChange={(value) => {
                                let amount = getValues().items[i].amount;
                                if (
                                  Number(value.target.value) > Number(amount)
                                ) {
                                  setError(`items.${i}.discount`, {
                                    message: `Discount amount must be less than $${amount}`,
                                  });
                                  return;
                                } else {
                                  clearErrors(`items.${i}.discount`);
                                }
                                console.log(amount, value.target.value);
                                setValue(
                                  `items.${i}.total`,
                                  amount - value.target.value
                                );
                                field.onChange(value);
                                trigger(`items.${i}.total`);
                              }}
                            />
                          )}
                        />
                        <FormFeedback>
                          {errors?.items?.length
                            ? errors?.items[i]?.discount?.message ??
                              "Discount must be greater than 0"
                            : "e"}
                        </FormFeedback>
                      </Col>
                      {/* <Col className="my-lg-0 my-2" lg="2" sm="12">
                        <Input type="number" defaultValue="1" placeholder="1" />
                      </Col> */}
                      <Col className="my-lg-0 " lg="2" sm="12">
                        <label className="mb-1">Total</label>
                        <CardText className="mb-0 mt-1 fw-bolder">
                          ${getValues().items[i].total ?? 0}
                        </CardText>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-center border-start invoice-product-actions py-50 px-25">
                      <X
                        size={18}
                        className="cursor-pointer"
                        onClick={() => remove(i)}
                      />
                    </div>
                  </Col>
                </Row>
              </Tag>
            );
          })}

          <Row className="mt-1">
            <Col sm="12" className="px-0">
              <Button
                color="primary"
                size="sm"
                className="btn-add-new"
                onClick={() =>
                  append({
                    amount: 0,
                    description: "",
                    discount: 0,
                    total: 0,
                  })
                }
              >
                <Plus size={14} className="me-25"></Plus>
                <span className="align-middle">Add Item</span>
              </Button>
            </Col>
          </Row>
        </CardBody>

        {/* /Product Details */}

        {/* Invoice Total */}
        <CardBody className="invoice-padding">
          <Row className="invoice-sales-total-wrapper">
            <Col></Col>
            <Col
              className="d-flex justify-content-end"
              md={{ size: "6", order: 2 }}
              xs={{ size: 12, order: 1 }}
            >
              <div className="invoice-total-wrapper">
                <div className="invoice-total-item">
                  <p className="invoice-total-title">Subtotal:</p>
                  <p className="invoice-total-amount">
                    {formatCurrency(getInvoiceTotal().total)}
                  </p>
                </div>
                <div className="invoice-total-item">
                  <p className="invoice-total-title">Discount:</p>
                  <p className="invoice-total-amount">
                    {formatCurrency(getInvoiceTotal().discount)}
                  </p>
                </div>

                <hr className="my-50" />
                <div className="invoice-total-item">
                  <p className="invoice-total-title">Total:</p>
                  <p className="invoice-total-amount">
                    {formatCurrency(
                      getInvoiceTotal().total - getInvoiceTotal().discount
                    )}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        {/* /Invoice Total */}

        <hr className="invoice-spacing mt-0" />

        {/* Invoice Note */}
        <CardBody className="invoice-padding py-0">
          <Row>
            <Col>
              <div className="mb-2">
                <Label for="remarks" className="form-label fw-bold">
                  Note:
                </Label>
                <Controller
                  name={`remarks`}
                  defaultValue={note}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input type="textarea" rows="2" id="remarks" {...field} />
                  )}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
        {/* /Invoice Note */}
      </Card>
    </Fragment>
  );
};

export default AddCard;
