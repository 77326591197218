// ** Reactstrap Imports
import { Row, Col, Input, Button } from "reactstrap";
import SearchBar from "../SearchBar";

function CustomHeader({
  setShow,
  handleSearch,
  handleClearResults,
  rowsPerPage,
  handlePerPage,
}) {
  return (
    <Row className="text-nowrap w-100 my-75 g-0 permission-header">
      <Col xs={12} lg={4} className="d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
          <label htmlFor="rows-per-page">Show</label>
          <Input
            className="mx-50"
            type="select"
            id="rows-per-page"
            value={rowsPerPage}
            onChange={handlePerPage}
            style={{ width: "5rem" }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="50">100</option>
            <option value="50">200</option>
          </Input>
          <label htmlFor="rows-per-page">Entries</label>
        </div>
      </Col>
      <Col xs={12} lg={8}>
        <div className="d-flex align-items-center justify-content-lg-end justify-content-start flex-md-nowrap flex-wrap mt-lg-0 mt-1">
          <div className="d-flex align-items-center me-1">
            <label className="mb-0" htmlFor="search-permission">
              Search:
            </label>
            <SearchBar
              onSearchSubmit={handleSearch}
              clearResults={handleClearResults}
            />
          </div>
          <Button
            className="add-permission mt-sm-0 mt-1"
            color="primary"
            onClick={() => setShow(true)}
          >
            Add Shift
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default CustomHeader;
