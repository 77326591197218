import React, { useContext, useEffect } from "react";

// ** React Imports
import { Fragment, useRef, useState } from "react";

// ** Reactstrap Imports
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Table,
} from "reactstrap";

// ** Custom Components

// ** Icons Imports
import { Book } from "react-feather";

// ** Steps

// ** Styles
import "@styles/react/pages/modal-create-app.scss";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ErrorHandler } from "../../common/utils/Error";
import { useGetSectionalCategoriesQuery } from "../../redux/sectionalCategory";
import ReactSelect from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";

const BulkUnitsForm = () => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { data: { data: categories = [] } = {}, isLoading } =
    useGetSectionalCategoriesQuery({});

  const currentBuilding = useSelector((state) => state.CurrentBuilding?.data);

  const {
    register,
    reset,
    control,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const readFloors = async () => {
    try {
      if (currentBuilding) {
        const building = currentBuilding;
        var { status, data } = await axios.get(
          `/buildings/floors/${building._id}`
        );
        if (status === 200) {
          setOptions(data?.data ?? []);
        }
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    readFloors();
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle>Create Bulk Units</CardTitle>
        </CardHeader>
        <CardBody>
          <form onSubmit={onSubmit}>
            <Row>
              <Col xs={12} md={4}>
                <Label className="form-label" for="sectionalType">
                  Category
                </Label>
                <Controller
                  name="sectionalType"
                  control={control}
                  render={({ field }) => (
                    <Input id="sectionalType" min={0} type="select">
                      {categories?.docs?.map((category) => (
                        <option value={category._id}>{category.name}</option>
                      ))}
                    </Input>
                  )}
                />
                {errors.sectionalType && (
                  <FormFeedback>
                    Please enter a valid Sectional-Type
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12} md={4}>
                <Label className="form-label" for="floor">
                  Located Floor
                </Label>
                <Controller
                  name="floor"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      id="floor"
                      invalid={errors.floor && true}
                      {...register(
                        "floor",
                        { required: true },
                        "Floor is required"
                      )}
                      {...field}
                      options={options}
                    />
                  )}
                />
                {errors.floor && (
                  <FormFeedback>Please enter a valid floor</FormFeedback>
                )}
              </Col>
              <Col xs={12} md={4}>
                <Label className="form-label" for="serial">
                  Serial Number
                </Label>
                <Controller
                  name="serial"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="serial"
                      placeholder="5"
                      {...register(
                        "serial",
                        {
                          required: true,
                        },
                        "serial is required"
                      )}
                      invalid={errors.serial && true}
                      {...field}
                    />
                  )}
                />
                {errors.serial && (
                  <FormFeedback>Please enter a valid serial</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={4} className="mt-1">
                <Label className="form-label" for="prefix">
                  Unit Prefix Name
                </Label>
                <Controller
                  name="prefix"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="prefix"
                      placeholder="5"
                      {...register(
                        "prefix",
                        {
                          required: true,
                        },
                        "prefix is required"
                      )}
                      invalid={errors.prefix && true}
                      {...field}
                    />
                  )}
                />
                {errors.prefix && (
                  <FormFeedback>Please enter a valid prefix</FormFeedback>
                )}
              </Col>

              <Col className="text-center" xs={12}>
                <Button
                  type="submit"
                  className="me-1 mt-2"
                  color="primary"
                  disabled={loading}
                >
                  <Spinner className="me-1" size={"md"} hidden={!loading} />
                  Generate
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default BulkUnitsForm;
