// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllUtilities = createAsyncThunk(
  "Utilities/getAllData",
  async (_, { getState }) => {
    console.log(getState().CurrentBuilding.data);
    let bodyContent = {
      params: {
        query: {
          building: getState().CurrentBuilding?.data?._id ?? "",
        },
        options: {
          sort: { createdAt: "desc" },
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "username",
            },
            {
              path: "type",
              dir: "utilityTypes",
              select: "type unit rate",
            },
            {
              path: "agreement",
              dir: "agreements",
              select: "",
            },
          ],
        },
      },
    };
    const response = await axios.get("/Utilities", bodyContent);
    const docs = response.data?.data?.docs || [];
    return {
      docs,
      totalRows: response.data?.data?.totalDocs,
      params: bodyContent.params,
    };
  }
);

export const getData = createAsyncThunk(
  "Utilities/getData",
  async (params, { dispatch, getState }) => {
    var strparams = getState().utilities.params;
    var newparams = {
      ...strparams,
      options: {
        ...strparams.options,
        ...params,

        sort: { createdAt: "desc" },
      },
    };
    const response = await axios.get("/Utilities", {
      params: newparams,
    });
    return {
      params: newparams,
      data: response.data?.data?.docs,
    };
  }
);

export const createUtility = createAsyncThunk(
  "Utilities/create",
  async (formdata, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.post("/Utilities", formdata);
      if (response.status != 200 && response.status !== 201) {
        return rejectWithValue(response.data.message);
      }

      dispatch(addUtility(response.data?.data));
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(
          error.response?.data?.message || "An error occurred"
        );
      }
      return rejectWithValue("An error occurred");
    }
  }
);

export const updateUtility = createAsyncThunk(
  "Utilities/update",
  async ({ data, id }, { dispatch, getState }) => {
    var response = await axios.patch(`/Utilities/${id}`, data);
    // await dispatch(getData(getState().users.params));
    dispatch(editUtility(response.data?.data));
    return { status: response.status, message: response.message };
  }
);
export const cancelUtility = createAsyncThunk(
  "Utilities/cancel",
  async (id, { dispatch, getState }) => {
    var response = await axios.patch(`/Utilities/cancel/${id}`);
    // await dispatch(getData(getState().users.params));
    dispatch(getAllUtilities());
    return { status: response.status, message: response.message };
  }
);

export const deleteUtility = createAsyncThunk(
  "Utilities/delete",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/Utilities/${id}`);
    await dispatch(getAllUtilities());
    return response.status;
  }
);

export const Utilitieslice = createSlice({
  name: "Utilities",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedUtility: null,
  },
  reducers: {
    addUtility: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectUtility: (state, { payload }) => {
      state.selectedUtility = payload;
    },
    editUtility: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedUtility = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUtilities.pending, (state, action) => {
        state.loading = !state.data.length ? true : false;
        state.error = null;
      })
      .addCase(getAllUtilities.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.params = action.payload.params;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      });
  },
});

export const { addUtility, selectUtility, editUtility } = Utilitieslice.actions;
export default Utilitieslice.reducer;
