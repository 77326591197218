import { forwardRef } from "react";
import moment from "moment";
import { Card, CardBody, CardText, Row, Col, Table } from "reactstrap";
import { formatCurrency } from "../../../utility/Utils";
import Logo from "../../../assets/images/logo/logo-icon.png";
const PreviewCard = (props) => {
  const { data } = props;

  const total = data?.invoiceId?.items?.reduce(
    (prev, curr) => prev + curr.total,
    0
  );
  return data ? (
    <Card className="invoice-preview-card">
      <CardBody className="invoice-padding pb-0">
        {/* Header */}
        <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
          <div>
            <div className="logo-wrapper">
              <img src={Logo} alt="" width={50} />
              <h3 className="text-primary invoice-logo">Sakeena</h3>
            </div>
            <CardText className="mb-25">
              Opposite Makka Almukarama Hotel
            </CardText>
            <CardText className="mb-0">
              (+252) 7770223355 , (+252) 770223366
            </CardText>
            <CardText className="mb-25">
              www.sakeena.so , Info@sakeena.so
            </CardText>
          </div>
          <div className="mt-md-0 mt-2">
            <h4 className="invoice-title">
              Receipt <span className="invoice-number">#{data.receiptNo}</span>
            </h4>
            <div className="invoice-date-wrapper">
              <p className="invoice-date-title">Date Issued:</p>
              <p className="invoice-date">
                {moment(data.date).format("YYYY-MM-DD")}
              </p>
            </div>
          </div>
        </div>
        {/* /Header */}
      </CardBody>

      <hr className="invoice-spacing" />

      {/* Address and Contact */}
      <CardBody className="invoice-padding pt-0">
        <Row className="invoice-spacing">
          <Col className="p-0" xl="8">
            <h6 className="mb-2">Receipt To:</h6>
            <h6 className="mb-25">{data.invoiceId?.customer?.name}</h6>
            <CardText className="mb-25">
              {data.invoiceId?.customer?.phone}
            </CardText>
            <CardText className="mb-25">
              {data?.invoiceId?.customer?.email}
            </CardText>
            <CardText className="mb-25">
              {data?.invoiceId?.customer?.address}
            </CardText>
          </Col>
          <Col className="p-0 mt-xl-0 mt-2" xl="4">
            <h6 className="mb-2">Payment Details:</h6>
            <table>
              <tbody>
                <tr>
                  <td className="pe-1">Total Due:</td>
                  <td>
                    <span className="fw-bolder">
                      {formatCurrency(data?.amount)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="pe-1">Bank name:</td>
                  <td>{data?.accountId?.name}</td>
                </tr>

                {/* <tr>
                  <td className="pe-1">IBAN:</td>
                  <td>{}</td>
                </tr>
                <tr>
                  <td className="pe-1">SWIFT code:</td>
                  <td>{}</td>
                </tr> */}
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
      {/* /Address and Contact */}

      {/* Receipt Description */}
      <Table responsive>
        <thead>
          <tr>
            <th className="py-1">Description</th>
            <th className="py-1" width={"15%"}>
              Qty
            </th>
            <th className="py-1" width={"17%"}>
              Price
            </th>
            <th className="py-1" width={"17%"}>
              Discount
            </th>
            <th className="py-1" width={"17%"}>
              Paid
            </th>
            <th className="py-1" width={"17%"}>
              Balance
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.invoiceId[0]?.items.map((item, idx) => (
            <tr key={idx}>
              <td className="py-1">
                <p className="card-text text-nowrap">{item?.description}</p>
              </td>
              <td className="py-1" width={"15%"}>
                <span className="fw-bold">
                  {item?.utility
                    ? item?.utility?.current - item.utility.prev
                    : 1}
                </span>
              </td>

              <td className="py-1" width={"15%"}>
                <span className="fw-bold">
                  {formatCurrency(
                    item?.utility
                      ? item?.utility?.rate *
                          (item?.utility?.current - item?.utility?.prev)
                      : item?.total
                  )}
                </span>
              </td>

              <td className="py-1" width={"15%"}>
                <span className="fw-bold">
                  {formatCurrency(item?.discount)}
                </span>
              </td>

              <td className="py-1" width={"15%"}>
                <span className="fw-bold">{formatCurrency(data?.amount)}</span>
              </td>

              <td className="py-1" width={"15%"}>
                <span className="fw-bold">
                  {formatCurrency(item?.total - data?.amount)}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* /Receipt Description */}

      {/* Total & Sales Person */}
      <CardBody className="invoice-padding pb-0">
        <Row className="invoice-sales-total-wrapper">
          <Col className="mt-md-0 mt-3" md="6" order={{ md: 1, lg: 2 }}>
            {/* <CardText className="mb-0">
              <span className="fw-bold">Salesperson:</span>{" "}
              <span className="ms-75">Jtech</span>
            </CardText> */}
          </Col>
          <Col
            className="d-flex justify-content-end"
            md="6"
            order={{ md: 2, lg: 1 }}
          >
            <div className="invoice-total-wrapper">
              <div className="invoice-total-item">
                <p className="invoice-total-title">Price:</p>
                <p className="invoice-total-amount">
                  {formatCurrency(data?.invoiceId?.total)}
                </p>
              </div>
              <hr className="my-50" />
              <div className="invoice-total-item">
                <p className="invoice-total-title">Receipt:</p>
                <p className="invoice-total-amount">
                  {formatCurrency(data?.amount)}
                </p>
              </div>
              <hr className="my-50" />
              <div className="invoice-total-item">
                <p className="invoice-total-title">Balance:</p>
                <p className="invoice-total-amount">
                  {formatCurrency(data?.invoiceId?.total - data?.amount)}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
      {/* /Total & Sales Person */}

      <CardBody className="invoice-padding mt-2">
        <Row>
          <Col sm="12">
            <span className="fw-bold">Signature: </span>
          </Col>
          <Col sm="12" className="mt-1">
            <span>_________________________________</span>
          </Col>
        </Row>
      </CardBody>

      <hr className="invoice-spacing" />

      {/* Receipt Note */}
      <CardBody className="invoice-padding pt-0">
        <Row>
          <Col sm="12">
            <span className="fw-bold">Note: </span>
            <span>
              It was a pleasure working with you and your team. We hope you will
              keep us in mind for future freelance projects. Thank You!
            </span>
          </Col>
        </Row>
      </CardBody>

      {/* /Receipt Note */}
    </Card>
  ) : null;
};

export default PreviewCard;
