// ** Core Packages
import { Fragment, useEffect, useState, useContext } from "react";

// ** Third-Party Packages
import axios from "axios";
import moment from "moment/moment";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// ** Reactstrap Components
import {
  Alert,
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

// ** Common Components
import HandleDispatch from "@commons/utils/HandledDispatch";
import { AlertTriangle } from "react-feather";
import { ErrorHandler, ExtractError } from "../../../common/utils/Error";

// ** Redux
import { useDispatch, useSelector } from "react-redux";
import {
  createReceipt,
  selectReceipt,
  updateReceipt,
} from "../../../redux/receipt";

// ** Utilities
import { formatCurrency } from "../../../utility/Utils";

// ** Contexts
import { CurrentBuildingContext } from "../../../contexts/currentBuilding";

// ** Router
import { useParams } from "react-router-dom";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

const schema = yup.object({
  date: yup.date().required("Date is required"),
  reference: yup.string().required("Reference is required"),
  amount: yup
    .number()
    .required("Total amount is required")
    .typeError("Total amount is required"),
  customer: yup
    .object()
    .shape({
      value: yup.string().required("Customer is required"),
      label: yup.string(),
    })
    .required("Customer is required")
    .typeError("Customer is required"),

  agreement: yup
    .object()
    .shape({
      value: yup.string().required("Agreement is required"),
      label: yup.string(),
    })
    .required("Agreement is required")
    .typeError("Agreement is required"),

  accountId: yup
    .object()
    .shape({
      value: yup.string().required("Account is required"),
      label: yup.string(),
    })
    .required("Account is required")
    .typeError("Account is required"),

  // invoice: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       _id: yup.string().required("_id is required"),
  //       amount: yup.number().required("Amount is required"),
  //     })
  //   )
  //   .required("Invoice is required")
  //   .typeError("Account is required"),
});

const InvoicesComponent = ({
  invoices,
  register,
  control,
  errors,
  getValues,
  setValue,
  watch,
}) => {
  const total = invoices.reduce((sum, curr) => sum + curr.balance, 0);

  // Function to handle changes and update the form state
  const handleChangeAmount = (_id, amount) => {
    let existingValues = getValues("invoices") || [];

    // Filter out any entries without an _id
    existingValues = existingValues.filter((item) => item._id);

    // Find the index of the item with the matching _id
    const index = existingValues.findIndex((item) => item._id === _id);

    if (index !== -1) {
      // If the _id exists, update the amount
      existingValues[index].amount = Number(amount);
    } else {
      // If the _id does not exist, append the new item
      existingValues.push({ _id, amount: Number(amount) });
    }

    // Update the form-hook data
    setValue("invoices", existingValues);
    const paidAmount = getValues("invoices")?.reduce(
      (total, cc) => total + cc.amount,
      0
    );
    setValue("amount", paidAmount.toFixed(2));
  };

  return (
    <Table responsive>
      <tbody>
        <tr>
          <th>Invoice</th>
          <th width="40%">Description</th>
          <th>Balance</th>
          <th>Paid</th>
        </tr>

        {invoices?.map((invoice, i) => (
          <tr key={invoice.invoiceNo}>
            <td>{invoice.invoiceNo}</td>
            <td>{invoice?.items[0].description}</td>
            <td>{invoice.balance}</td>
            <td>
              <Controller
                name={`invoices.${invoice.invoiceNo}.amount`}
                control={control}
                defaultValue={0} // Set a default value
                render={({ field }) => (
                  <Input
                    id={`amount-${invoice.invoiceNo}`}
                    name={`amount-${invoice.invoiceNo}`}
                    type="number"
                    min={0}
                    max={invoice?.balance}
                    step="0.01" // Allow decimal values
                    {...field}
                    defaultValue={0}
                    onChange={(e) => {
                      const amount = e.target.value;
                      handleChangeAmount(invoice._id, amount); // Handle the change here
                      field.onChange(amount); // Also update the field value
                    }}
                  />
                )}
              />
              {errors.invoices?.[i]?.amount && (
                <p>{errors.invoices[i].amount.message}</p>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th className="fw-bold" colSpan={2}>
            Total
          </th>

          <th>${total?.toFixed(2)}</th>
          <th>${watch("amount")}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

const ReceiptForm = ({ show, ShowModal, data, appView }) => {
  const selectedReceipt = useSelector(
    (state) => state.receipts.selectedReceipt
  );
  const [errorMessage, setErrorMessage] = useState(null);

  const { id } = useParams();
  const [useBalance, setUseBalance] = useState(false);
  const [customerBalance, setCustomerBalance] = useState(0);

  const customersState = useSelector((state) => state.customers.allData);
  const sectionals = useSelector((state) => state.sectionals.allData);
  const CurrentBuilding = useSelector((state) => state.CurrentBuilding.data);
  const store = useSelector((state) => state.receipts);

  const { currentBuilding: selectedBuilding } = useContext(
    CurrentBuildingContext
  );

  const defaultValues = {
    receiptType: appView ? appView : "invoice",
    // customer: "",
    // invoiceId: "",
    accountId: "",
    // balance: 0,
    // paid: 0,
    // overpaidOption: "accountReceivable",
    amount: 0,
    // method: "",
    date: moment().format("YYYY-MM-DD"),
    reference: "",
    // description: "",
    invoices: [],
  };

  // ** Hooks
  const {
    register,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    trigger,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();

  const [customerInvoices, setCustomerInvoices] = useState([]);
  const [customerLeases, setCustomerLeases] = useState([]);
  const [loading, setLoading] = useState(false);

  const [CustomerInvoicesLoading, setInvoicesLoading] = useState(false);

  const getCustomerInvoices = async (idx) => {
    setInvoicesLoading(true);
    try {
      let cust = getValues().customer?.value;
      var { status, data } = await axios.get(
        `accountings/invoices/customer/${cust}/${idx}`
      );
      if (status == 200) {
        if (data.data?.length) {
          setCustomerInvoices(data.data);
        } else {
          // setValue("invoiceId", "");
          setCustomerInvoices([]);
        }
      } else {
        setValue("invoiceId", "");
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setInvoicesLoading(false);
    }
  };
  const getCustomerInvoicesType = async (idx) => {
    setInvoicesLoading(true);
    try {
      var { status, data } = await axios.get(
        `accountings/invoices/customer/${idx}` +
          (appView ? `?type=${appView}` : "")
      );
      if (status == 200) {
        if (data.data?.length) {
          setCustomerInvoices(data.data);
        } else {
          setValue("invoiceId", "");
          setCustomerInvoices([]);
        }
      } else {
        setValue("invoiceId", "");
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setInvoicesLoading(false);
    }
  };

  const getCustomerLeases = async (idx) => {
    setInvoicesLoading(true);
    try {
      var { status, data } = await axios.get(
        `/agreements/customer/${idx}/${selectedBuilding?._id}`
      );
      if (status == 200) {
        if (data.data?.length) {
          setCustomerLeases(data.data);
          setValue("invoiceId", "");
        } else {
          setValue("agreement", "");
          setValue("invoiceId", "");
          setCustomerLeases([]);
        }
      } else {
        setValue("agreement", "");
        setValue("invoiceId", "");
      }
      setCustomerInvoices([]);
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setInvoicesLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setErrorMessage(null);
      setLoading(true);

      const invoices = data?.invoices?.filter(
        (invoice) => invoice?._id && invoice?.amount
      );

      if (!invoices.length) throw new Error("No invoice (s) found");

      const totalAmount = data.invoices?.reduce((sum, item) => {
        return sum + Number(item.amount);
      }, 0);

      // Check if the data.amount is greater than the totalAmount
      if (data.amount?.toFixed(2) !== totalAmount.toFixed(2)) {
        throw new Error(
          `The provided amount (${data.amount}) and the total invoice amount (${totalAmount}) not equal.`
        );
      }

      data.invoices = invoices;

      const responseData = await HandleDispatch(
        dispatch,
        createReceipt({
          ...data,
          amount: data.amount,
          customer: data.customer.value,
          invoices: data.invoices,
          account: data.accountId.value,
          receiptType: data.receiptType,
          type: "multiple",
        })
      );

      if (responseData) {
        toast.success("New Receipt Created Successfully");
        setCustomerBalance(0);
        setUseBalance(false);
        reset();
        reset();
        ShowModal();
      }

      // if (!CurrentBuilding && !appView) {
      //   toast.error("Please Select Property");
      //   setLoading(false);
      //   return;
      // }

      // if (selectedReceipt) {
      //   data.building = CurrentBuilding._id;
      //   (data.amount = data.paid),
      //     (data.customer = data.customer.value),
      //     (data.invoiceId = data.invoiceId.value),
      //     (data.accountId = data.accountId.value),
      //     (data.method = data.method.value);
      //   data.useBalance = useBalance;

      //   const Ndata = await HandleDispatch(
      //     dispatch,
      //     updateReceipt({ id: selectedReceipt._id, data })
      //   );
      //   if (Ndata) {
      //     setCustomerBalance(0);
      //     setUseBalance(false);
      //     toast.success("Receipt updated Succesfully");
      //     reset();

      //     ShowModal();
      //   }
      // } else {
      //   const responseData = await HandleDispatch(
      //     dispatch,
      //     createReceipt({
      //       ...data,
      //       amount: data.paid,
      //       customer: data.customer.value,
      //       invoiceId: data.invoiceId.value,
      //       accountId: data.accountId.value,
      //       method: data.method.value,
      //       useBalance,
      //     })
      //   );
      //   if (responseData) {
      //     toast.success("New Receipt Created Successfully");
      //     setCustomerBalance(0);
      //     setUseBalance(false);
      //     reset();
      //     reset();
      //     ShowModal();
      //   }
      // }
    } catch (error) {
      setErrorMessage(ExtractError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    dispatch(selectReceipt(null));
    reset(defaultValues);
  };

  const formatLeases = () => {
    let leases =
      customerLeases.map((cust) => ({
        value: cust._id,
        label: `${cust.unit?.name} - ${cust.building?.name}`,
      })) ?? [];

    return [
      {
        value: "other",
        label: `Other Invoices`,
      },
      ...leases,
    ];
  };

  useEffect(() => {
    if (selectedReceipt) {
      reset({
        ...selectedReceipt,
        customer: {
          value: selectedReceipt?.invoiceId?.customer?._id,
          label: selectedReceipt?.invoiceId?.customer?.name,
        },
        accountId: {
          value: selectedReceipt?.accountId?._id,
          label: selectedReceipt?.accountId?.name ?? "",
        },
        invoiceId: {
          value: selectedReceipt?.invoiceId?._id,
          label: selectedReceipt?.invoiceId?.date ?? "",
        },
      });
    }
  }, [selectedReceipt]);

  const getCustomerBalance = async (cid) => {
    setCustomerBalance(0);
    setUseBalance(false);
    try {
      var { status, data } = await axios.get(`/customers/balance/${cid}`);
      if (status == 200) {
        if (data.data?.balance) {
          setCustomerBalance(data.data?.balance);
        } else {
          setCustomerBalance(0);
        }
      } else {
        setCustomerBalance(0);
        setuseBalance(false);
      }
    } catch (error) {
      ExtractError(error);
    }
  };

  useEffect(() => {
    const customer = data?.customers.find((customer) => customer._id == id);
    if (customer) {
      reset({
        ...defaultValues,
        customer: {
          value: customer?._id,
          label: customer?.name,
        },
      });
      getCustomerLeases(customer._id);
    }
  }, [show]);

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onDiscard}
        className="modal-lg"
        backdrop="static"
      >
        <ModalHeader className="bg-transparent" toggle={onDiscard}>
          {selectedReceipt ? "Update Receipt" : "Add New Receipt"}{" "}
        </ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5 px-sm-4 mx-50">
            {errorMessage && (
              <Alert color="danger" className="px-2 py-1">
                {errorMessage}
              </Alert>
            )}

            <Row className="gy-1 gx-2">
              <Col xs={12} md={6}>
                <Label className="form-label" for="date">
                  Receipt Date
                </Label>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="date"
                      type="date"
                      invalid={errors.date ? true : false}
                      {...field}
                    />
                  )}
                />
                {errors.date && (
                  <FormFeedback>{errors.date.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} md={6}>
                <Label className="form-label" for="reference">
                  Reference
                </Label>
                <Controller
                  name="reference"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="reference"
                      type="text"
                      invalid={errors.reference ? true : false}
                      {...field}
                    />
                  )}
                />
                {errors.reference && (
                  <FormFeedback>{errors.reference.message}</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={appView ? 6 : 6}>
                <Label className="form-label" for="customer">
                  Select Customer
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="customer"
                      className={`react-select ${
                        errors.customer ? "is-invalid" : ""
                      }`}
                      classNamePrefix="select"
                      invalid={errors.customer && true}
                      {...register("customer", { required: true })}
                      {...field}
                      placeholder={"Select Customer"}
                      options={
                        data.customers.map((cust) => ({
                          value: cust._id,
                          label: cust.name,
                        })) ?? []
                      }
                      onChange={(v) => {
                        field.onChange(v);

                        //   // if (appView) {
                        //   //   getCustomerInvoicesType(v.value);
                        //   // } else {
                        getCustomerLeases(v?.value);
                        //   // }
                        //   // if (v) {
                        //   //   getCustomerBalance(v.value);
                        //   // }
                      }}
                    />
                  )}
                />
                {errors.customer && (
                  <FormFeedback>
                    {errors.customer?.value?.message ||
                      errors.customer?.message}
                  </FormFeedback>
                )}
              </Col>
              {!appView ? (
                <Col xs={12} md={6}>
                  <Label className="form-label" for="agreement">
                    Select Lease
                  </Label>
                  <Controller
                    name="agreement"
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="agreement"
                        className={`react-select ${
                          errors.agreement ? "is-invalid" : ""
                        }`}
                        {...register("agreement", { required: true })}
                        classNamePrefix="select"
                        invalid={errors.agreement && true}
                        {...field}
                        placeholder={"Select Lease"}
                        options={formatLeases()}
                        isLoading={CustomerInvoicesLoading}
                        onChange={(v) => {
                          if (!getValues().customer) {
                            toast.error("Select Customer");
                            return;
                          }
                          field.onChange(v);
                          getCustomerInvoices(v.value);
                          setValue("invoices", []);
                        }}
                      />
                    )}
                  />
                  {errors.agreement && (
                    <FormFeedback>
                      {errors.agreement?.value?.message ||
                        errors.agreement?.message}
                    </FormFeedback>
                  )}
                </Col>
              ) : null}

              <Col xs={12} md={6}>
                <Label className="form-label" for="amount">
                  Total Amount
                </Label>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      readOnly={true}
                      id="amount"
                      type="text"
                      invalid={errors.amount ? true : false}
                      {...field}
                    />
                  )}
                />
                {errors.amount && (
                  <FormFeedback>{errors.amount.message}</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={6}>
                <Label className="form-label" for="accountId">
                  Deposit to
                </Label>
                <Controller
                  name="accountId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="accountId"
                      className={`react-select ${
                        errors.accountId ? "is-invalid" : ""
                      }`}
                      classNamePrefix="select"
                      invalid={errors.accountId && true}
                      {...register("accountId", { required: true })}
                      {...field}
                      options={data.accounts.map((ac) => ({
                        value: ac._id,
                        label: `${ac.name}`,
                        isDisabled: ac.systemAccount,
                      }))}
                    />
                  )}
                />
                {errors.accountId && (
                  <FormFeedback>
                    {errors.accountId?.value?.message ||
                      errors.accountId?.message}
                  </FormFeedback>
                )}
              </Col>

              {!!customerInvoices.length && (
                <InvoicesComponent
                  invoices={customerInvoices}
                  register={register}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  watch={watch}
                />
              )}

              {/* {getValues().balance < 0 ? (
                <Col xs={12} md={6} className="">
                  <Label className="form-label" for="balance">
                    Invoice Over Payment
                  </Label>
                  <div className=" ">
                    <div className="form-check form-check-warning">
                      <Controller
                        name="overpaidOption"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="radio"
                            id="account-recievable-radio"
                            {...field}
                            value={"accountRecievable"}
                            checked={field.value == "accountRecievable"}
                          />
                        )}
                      />
                      <Label
                        className="form-check-label"
                        for="account-recievable-radio"
                      >
                        Credit Account Receivable
                      </Label>
                    </div>
                    <div className="form-check form-check-warning">
                      <Controller
                        name="overpaidOption"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="radio"
                            id="customer-credit-radio"
                            {...field}
                            value={"customerCredit"}
                            checked={field.value == "customerCredit"}
                          />
                        )}
                      />

                      <Label
                        className="form-check-label"
                        for="customer-credit-radio"
                      >
                        Customer Credit
                      </Label>
                    </div>
                  </div>
                </Col>
              ) : null} */}
            </Row>
            {getValues().customer && customerBalance ? (
              <Alert
                color="warning"
                className="p-1 mt-2 d-flex justify-content-between align-items-center mb-0"
              >
                <div className="">
                  <AlertTriangle className="align-middle m-0 me-1" size={16} />
                  <span className="form-check-label" for="warning-checkbox">
                    Customer Have Credit balance off{" "}
                    <span style={{ fontWeight: "900" }}>
                      {formatCurrency(customerBalance)}{" "}
                    </span>
                  </span>
                </div>
                <Button
                  outline
                  color="warning"
                  size="sm"
                  onClick={() => {
                    if (useBalance) {
                      setValue("paid", 0);
                      setValue("balance", getValues().amount);
                    } else {
                      let paid =
                        getValues().amount <= customerBalance
                          ? getValues().amount
                          : customerBalance ?? 0;
                      setValue("paid", paid);
                      setValue("balance", getValues().amount - paid);
                    }
                    setuseBalance(!useBalance);
                  }}
                >
                  {useBalance ? "Cancel" : "Apply"}
                </Button>
              </Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              type="reset"
              className="mt-2"
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
            <Button
              type="submit"
              size="md"
              className="me-1 mt-2"
              color={selectedReceipt ? "success" : "primary"}
              disabled={loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading
                ? "Submitting..."
                : selectedReceipt
                ? "Save Changes"
                : "Submit"}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default ReceiptForm;
