import PropertyManagementIcon from "@src/assets/images/icons/propertyManagement.png";
import SalesIccon from "@src/assets/images/icons/sales.png";
import ConsultationIcon from "@src/assets/images/icons/consultation.png";
import MaintainanceIcon from "@src/assets/images/icons/mantainance.png";

export default [
  {
    id: "properties",
    title: "Properties management",
    action: ["read"],
    img: PropertyManagementIcon,
    link: `/dashboard?view=company`,
  },
  {
    id: "salesAndBroker",
    title: "Sales and Broker",
    action: ["read"],
    img: SalesIccon,
    link: `/dashboard?view=salesAndBroker`,
  },
  {
    id: "maintainance",
    title: "Maintenance",
    action: ["read"],
    img: MaintainanceIcon,
    link: `/dashboard?view=maintainance`,
  },
  {
    id: "consultancy",
    title: "consultancy",
    action: ["read"],
    img: ConsultationIcon,
    link: `/dashboard?view=consultation`,
  },
];
