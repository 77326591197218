import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import StatsVertical from "@components/widgets/stats/StatsVertical";
import StatsHorizontal from "../../@core/components/widgets/stats/StatsHorizontal";
import {
  Activity,
  AlertOctagon,
  Award,
  Circle,
  Cpu,
  Eye,
  Heart,
  MessageSquare,
  Server,
  ShoppingBag,
  Truck,
} from "react-feather";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import classNames from "classnames";

import { ThemeColors } from "@src/utility/context/ThemeColors";
import { useContext } from "react";
import {
  Building,
  BuildingCheck,
  BuildingDash,
  BuildingUp,
  CircleFill,
  EnvelopeAt,
  EnvelopeExclamation,
  File,
  Files,
  Inbox,
  Mailbox,
  ReceiptCutoff,
} from "react-bootstrap-icons";
import ConsultationRevenueReport from "./consultationRevenueReport";
import moment from "moment";
import { formatCurrency } from "../../utility/Utils";
import { useNavigate } from "react-router-dom";

export default function ConsultationDashboard() {
  const context = useContext(ThemeColors);
  const servicesDashboard = useSelector(
    (state) => state.dashboard.consultation
  );

  const navigate = useNavigate();
  // if (!servicesDashboard) {
  //   return null;
  // }

  const renderChartInfo = (data) => {
    return data?.map((item, index) => {
      return (
        <div
          key={index}
          className={classNames("d-flex justify-content-between", {
            "mb-1": false, // index !== data.listData.length - 1
          })}
        >
          <div className="d-flex align-items-center">
            <CircleFill size={15} className="text-primary" />
            <span className="fw-bold ms-75">{item._id}</span>
          </div>
          <span>{item.count}</span>
        </div>
      );
    });
  };

  let total = servicesDashboard?.invoices?.total[0];
  let allUnpaid = servicesDashboard?.invoices?.allUnpaid[0];
  let totalPaid = total?.total - allUnpaid?.total ?? 0;
  if (!totalPaid) {
    totalPaid = 0;
  }

  const invoiceOptions = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      labels: ["All", "Unpaid"],
      dataLabels: {
        enabled: false,
      },
      legend: { show: false },
      stroke: {
        width: 4,
      },
      colors: [context.colors.primary.main, context.colors.warning.main],
    },
    invoiceSeries = [total?.total, allUnpaid?.total];

  let monthlySales = null;
  let dailySales = null;
  servicesDashboard?.receipts?.thisMonthSalesCollection?.map(
    (item) => (monthlySales = item)
  );
  servicesDashboard?.receipts?.todaySalesCollection?.map(
    (item) => (dailySales = item)
  );

  return (
    <>
      <Row>
        <Col sm={12} md={8}>
          <Row>
            {/* Stats With Icons */}
            <Col
              md="4"
              sm="12"
              className="cursor-pointer"
              onClick={() => navigate("/invoices?view=consultation")}
            >
              <StatsVertical
                icon={<ReceiptCutoff size={21} />}
                color="info"
                stats={total?.total ?? 0}
                statTitle="Total Invoices"
              />
            </Col>

            <Col
              md="4"
              sm="12"
              className="cursor-pointer"
              onClick={() =>
                navigate("/invoices?view=consultation&status=paid")
              }
            >
              <StatsVertical
                icon={<ReceiptCutoff size={21} />}
                color="success"
                stats={totalPaid ?? 0}
                statTitle="Paid Invoices"
              />
            </Col>

            <Col
              md="4"
              sm="12"
              className="cursor-pointer"
              onClick={() =>
                navigate("/invoices?view=consultation&status=unpaid")
              }
            >
              <StatsVertical
                icon={<ReceiptCutoff size={21} />}
                color="danger"
                stats={allUnpaid?.total ?? 0}
                statTitle="Unpaid Invoices"
              />
            </Col>
          </Row>
          <Col md={12}>
            <ConsultationRevenueReport
              paid={servicesDashboard?.invoices?.paid}
              unpaid={servicesDashboard?.invoices?.unpaid}
              colors={[
                context.colors.success.main,
                context.colors.warning.main,
              ]}
            />
          </Col>
        </Col>
        {/* consultation revenue is not working ...   */}

        <Col>
          <Col sm={12} md={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-2">consultation Collection</CardTitle>
                <div className="font-small-3">Today</div>

                <h4 className="mb-2 text-primary">
                  {formatCurrency(dailySales?.total ?? 0)}
                </h4>

                <div className="font-small-3">This month</div>

                <h4 className="mb-1 text-primary">
                  {formatCurrency(monthlySales?.total ?? 0)}
                </h4>
              </CardBody>
            </Card>
          </Col>

          <Col sm={12} md={12}>
            <Card>
              <CardHeader className="align-items-end">
                <CardTitle tag="h4">Invoices</CardTitle>
              </CardHeader>
              <CardBody>
                {/* <Chart
                  options={invoiceOptions}
                  series={invoiceSeries}
                  type="pie"
                  height={325}
                /> */}
                <div className="pt-25">
                  {renderChartInfo([
                    {
                      _id: "All",
                      count: total?.total ?? 0,
                    },
                    {
                      _id: "Unpaid",
                      count: allUnpaid?.total ?? 0,
                    },
                  ])}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col></Col>
      </Row>
    </>
  );
}
