// ** React Imports
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

// ** Store & Actions

import { useSelector, useDispatch } from "react-redux";
import { Maximize, Maximize2, Minimize2 } from "react-feather";

// ** Reactstrap Imports
import { Row, Col, Alert, Spinner } from "reactstrap";

// ** User View Components
import OwnerTab from "./Tabs";
import OwnerInfo from "./OwnerInfo";

// ** Styles
import "@styles/react/apps/app-users.scss";
import { getOwnerDetail } from "../../../redux/owner";

const OwnerView = () => {
  // ** Store Vars

  const store = useSelector((state) => state.owners.ownerDetail);

  const dispatch = useDispatch();

  // ** Hooks
  const { id } = useParams();

  // ** Get suer on mount
  useEffect(() => {
    dispatch(getOwnerDetail(id));
  }, [dispatch, id]);

  const [active, setActive] = useState("1");

  const toggleTab = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const [isFull, setIsFull] = useState(false);

  const fullscreenModalStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: "1050",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const modalContentStyles = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    height: "100vh",
    maxHeight: "100vh",
    width: "100%",
    overflowY: "scroll",
  };

  return store?.data !== null && store?.data !== undefined && !store.loading ? (
    <div style={isFull ? fullscreenModalStyles : {}}>
      <div className="app-user-view" style={isFull ? modalContentStyles : {}}>
        <div className="float-end me-5">
          {!isFull ? (
            <Maximize2
              size={30}
              className="cursor-pointer"
              onClick={() => setIsFull(true)}
            />
          ) : (
            <Minimize2
              size={30}
              className="cursor-pointer"
              onClick={() => setIsFull(false)}
            />
          )}
        </div>
        <Row>
          <Col xl="4" lg="5" xs={{ order: 1 }} md={{ order: 0, size: 5 }}>
            <OwnerInfo owner={store?.data?.ownerInfo} />
            {/* <PlanCard /> */}
          </Col>
          <Col xl="8" lg="7" xs={{ order: 0 }} md={{ order: 1, size: 7 }}>
            <OwnerTab active={active} toggleTab={toggleTab} store={store} />
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    store.loading && (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    )
  );
};
export default OwnerView;
