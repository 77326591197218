import { Fragment, useState } from "react";
import {
  ChevronDown,
  FileText,
  Grid,
  PersonAdd,
  Printer,
  Share,
  Trash,
} from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { Copy, Edit, File } from "react-feather";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  useDeleteSectionalCategoryMutation,
  useGetSectionalCategoriesQuery,
} from "../../redux/sectionalCategory";
import { Can } from "../../utility/context/Can";
import Loading from "../loading";
import FormModel from "./components/formModel";
const MySwal = withReactContent(Swal);

const SectionalCategory = () => {
  const [show, setShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, isLoading } = useGetSectionalCategoriesQuery({
    page: currentPage,
    perPage: rowsPerPage,
    keyword: searchTerm,
  });

  const [deleteSectionalCategory] = useDeleteSectionalCategoryMutation();

  const ToggleModal = (show) => {
    if (!show) {
      setSelectedCategory(null);
    }
    if (typeof show === "boolean") {
      setShow(show);
    } else {
      setShow(!show);
    }
  };

  // ** Function in get data on rows per page
  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    setRowsPerPage(value);
  };

  const renderData = () => {
    const calculateIndex = (index) => {
      return (currentPage - 1) * rowsPerPage + index + 1;
    };
    const vendors = Array.isArray(data?.data)
      ? data.data
      : data?.data?.docs ?? [];
    return vendors.map((item, index) => {
      return { ...item, index: calculateIndex(index) };
    });
  };

  const handleDeleteClick = (data) => {
    return MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        const res = await deleteSectionalCategory(data._id);
        if (res.data !== undefined)
          toast.success("Vendor deleted successfully");
      }
    });
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      cell: ({ name }) => name,
      selector: (row) => row.name,
    },
    {
      name: "Type",
      sortable: true,
      cell: ({ sectionalType }) => sectionalType,
      selector: (row) => row.sectionalType,
    },
    {
      name: "No. rooms",
      sortable: true,
      cell: ({ rooms }) => rooms,
      selector: (row) => row.rooms,
    },
    {
      name: "No. Bathrooms",
      sortable: true,
      cell: ({ bathRooms }) => bathRooms,
      selector: (row) => row.bathRooms,
    },
    {
      name: "Rent fee",
      sortable: true,
      cell: ({ price }) => price,
      selector: (row) => row.price,
    },
    {
      name: "Reservation fee",
      sortable: true,
      cell: ({ reservationFee }) => reservationFee,
      selector: (row) => row.reservationFee,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="d-flex align-items-center permissions-actions">
            <Button
              id="update-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => {
                setSelectedCategory(row);
                setShow(true);
              }}
            >
              <Edit className="font-medium-2" />
              <UncontrolledTooltip placement="top" target="update-btn">
                Update
              </UncontrolledTooltip>
            </Button>
            <Button
              id="delete-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => handleDeleteClick(row)}
            >
              <Trash className="font-medium-2 text-danger" />
              <UncontrolledTooltip placement="top" target="delete-btn">
                Delete
              </UncontrolledTooltip>
            </Button>
          </div>
        );
      },
    },
  ];

  const CustomPagination = () => {
    const count = Number(Math.ceil(data?.data.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const CustomHeader = ({
    store,
    toggleSidebar,
    handlePerPage,
    rowsPerPage,
    handleSearch,
    searchTerm,
  }) => {
    // ** Converts table to CSV
    function convertArrayOfObjectsToCSV(array) {
      let result;

      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(store.data[0]);

      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach((item) => {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key];

          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    }
    // ** Downloads CSV
    function downloadCSV(array) {
      const link = document.createElement("a");
      let csv = convertArrayOfObjectsToCSV(array);
      if (csv === null) return;

      const filename = "export.csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    }

    return (
      <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
        <Row>
          <Col xl="6" className="d-flex align-items-center p-0">
            <div className="d-flex align-items-center w-100">
              <label htmlFor="rows-per-page">Show</label>
              <Input
                className="mx-50"
                type="select"
                id="rows-per-page"
                value={rowsPerPage}
                onChange={handlePerPage}
                style={{ width: "5rem" }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="50">100</option>
              </Input>
              <label htmlFor="rows-per-page">Entries</label>
            </div>
          </Col>
          <Col
            xl="6"
            className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
          >
            <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
              <label className="mb-0" htmlFor="search-invoice">
                Search:
              </label>
              <Input
                id="search-invoice"
                className="ms-50 w-100"
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>

            <div className="d-flex align-items-center table-header-actions">
              <UncontrolledDropdown className="me-1">
                <DropdownToggle color="secondary" caret outline>
                  <Share className="font-small-4 me-50" />
                  <span className="align-middle">Export</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="w-100">
                    <Printer className="font-small-4 me-50" />
                    <span className="align-middle">Print</span>
                  </DropdownItem>
                  <DropdownItem
                    className="w-100"
                    onClick={() => downloadCSV(store.data)}
                  >
                    <FileText className="font-small-4 me-50" />
                    <span className="align-middle">CSV</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <Grid className="font-small-4 me-50" />
                    <span className="align-middle">Excel</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <File className="font-small-4 me-50" />
                    <span className="align-middle">PDF</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <Copy className="font-small-4 me-50" />
                    <span className="align-middle">Copy</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Fragment>
      <Card className="overflow-hidden">
        <CardHeader className="d-flex align-items-start">
          <CardTitle tag="h4">Units Category</CardTitle>
          <FormModel
            show={show}
            ShowModal={ToggleModal}
            selectedCategory={selectedCategory}
          />
          <Can I="create" a="Vendor">
            <Button
              className=""
              color="primary"
              onClick={() => ToggleModal(true)}
            >
              <PersonAdd className="me-1" size={20} /> Add New Category
            </Button>
          </Can>
        </CardHeader>
        <CardBody>
          <div className="react-dataTable">
            {isLoading ? (
              <Loading cols={columns} />
            ) : (
              <DataTable
                noHeader
                pagination
                subHeader
                responsive
                paginationServer
                columns={columns}
                sortIcon={<ChevronDown />}
                className="react-dataTable"
                paginationComponent={() => (
                  <CustomPagination
                    data={data?.data}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    rowsPerPage={rowsPerPage}
                  />
                )}
                data={renderData()}
                subHeaderComponent={
                  <CustomHeader
                    setShow={setShow}
                    searchTerm={searchTerm}
                    handleSearch={setSearchTerm}
                    // handleClearResults={() => setSearchTerm("")}
                    rowsPerPage={rowsPerPage}
                    handlePerPage={handlePerPage}
                  />
                }
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default SectionalCategory;
