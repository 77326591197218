import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
// ** Icons Imports
import AvatarGroup from "@components/avatar-group";
import { selectThemeColors } from "@utils";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Check, PenFill, X } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import {
  Archive,
  Briefcase,
  ChevronDown,
  Copy,
  File,
  FileText,
  Grid,
  LogIn,
  MoreVertical,
  Printer,
  Share,
  Trash2,
  List,
  Edit,
} from "react-feather";
import { toast } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ErrorHandler, ExtractError } from "../../common/utils/Error";
import HandleDispatch from "../../common/utils/HandledDispatch";
import themeConfig from "../../configs/themeConfig";
import {
  deleteBuilding,
  getAllBuildings,
  getData,
  selectBuilding,
  updateFeaturedProperty,
} from "../../redux/building";

import Loading from "../loading";
import AddNewAddress from "./components/buildingForm";
import ViewBuildings from "./components/viewBuildings";
import { useContext } from "react";
import { CurrentBuildingContext } from "../../contexts/currentBuilding";
import { loginBuilding } from "../../redux/home";
import { AbilityContext, Can } from "../../utility/context/Can";
import { getFirstAvailableRoute } from "../../utility/Utils";
import Can2 from "../../utility/Can";
import { unwrapResult } from "@reduxjs/toolkit";

const MySwal = withReactContent(Swal);

const CustomLabel = ({ htmlFor }) => {
  return (
    <Label className="form-check-label" htmlFor={htmlFor}>
      <span className="switch-icon-left">
        <Check size={14} />
      </span>
      <span className="switch-icon-right">
        <X size={14} />
      </span>
    </Label>
  );
};

export default function BuildingList() {
  const [showmodal, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [view, setView] = useState("list");
  const ability = useContext(AbilityContext);
  const { currentBuilding, logInBuilding } = useContext(CurrentBuildingContext);

  const [featuredLoading, setfeaturedLoading] = useState(false);
  const [publishedLoading, setPublishedLoading] = useState(false);

  const store = useSelector((state) => state.buildings);

  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");
  const status = searchParams.get("status");

  const handleFeatured = async (id, checked) => {
    setfeaturedLoading(true);
    try {
      const response = await HandleDispatch(
        dispatch,
        updateFeaturedProperty({
          id,
          type: "featured",
          status: checked,
        })
      );
      if (response) {
        toast.success(
          checked
            ? "Property Added to Featured List"
            : "Property Removed From Featured List"
        );
      }
    } catch (error) {
      ErrorHandler(error);
    }
    setfeaturedLoading(false);
  };

  const handlePublished = async (id, checked) => {
    setPublishedLoading(true);
    try {
      const response = await HandleDispatch(
        dispatch,
        updateFeaturedProperty({
          id,
          type: "published",
          status: checked,
        })
      );
      if (response) {
        toast.success(checked ? "Property Published" : "Property Unpublished");
      }
    } catch (error) {
      ErrorHandler(error);
    }
    setPublishedLoading(false);
  };

  useEffect(() => {
    dispatch(getAllBuildings());
  }, []);

  const loginIntoBuilding = (buildings) => {
    logInBuilding(buildings);
    dispatch(loginBuilding(buildings));
    navigate(getFirstAvailableRoute(ability, buildings?._id, "building"));
  };

  // const handleConfirmDelete = async (id, name) => {
  //   return MySwal.fire({
  //     title: `Delete Building ${name}?`,
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, delete it!",
  //     customClass: {
  //       confirmButton: "btn btn-primary",
  //       cancelButton: "btn btn-danger ms-1",
  //     },
  //     buttonsStyling: false,
  //   }).then(async (result) => {
  //     if (result.value) {
  //       const responseStatus = dispatch(deleteBuilding(id));
  //       if (responseStatus == 204) {
  //         toast.success("Building Has been Deleted.");
  //       }
  //     }
  //   });
  // };

  const handleConfirmDelete = (id, name) => {
    MySwal.fire({
      title: `Delete Building ${name}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-danger me-1",
        cancelButton: "btn btn-primary",
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const result = await dispatch(deleteBuilding(id));
          const response = await unwrapResult(result);

          if (response) {
            toast.success("Owner Has been Deleted.");
          }
        } catch (error) {
          const errorMessage =
            error?.response?.data?.message ??
            error?.response?.data ??
            error.message ??
            "Something went wrong, please try again";

          const hasUnits = error?.response?.data?.hasUnits ?? false;

          if (hasUnits) {
            Swal.showValidationMessage(errorMessage);
          } else {
            Swal.showValidationMessage(errorMessage);
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const columns = [
    {
      name: "Property",
      sortable: true,
      width: "15%",
      // sortField: "name",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="d-flex align-items-start">
          {/* {row.photos.length ? ( */}
          {row.thumbnail ? (
            <img
              src={themeConfig.serverUrl + "uploads/" + row.thumbnail}
              className="img-fluid featured-img"
            />
          ) : null}

          {/* ) : (
            <img
              src={require("@src/assets/images/property_img.jpg").default}
              className="img-fluid featured-img"
            />
          )} */}
          <Link
            to={`/properties/${row._id}?view=${appView}`}
            className="text-body"
          >
            <span className="fw-bolder">{row.name}</span>
          </Link>

          {/* <div className="d-flex flex-column">
            

            <div className={classNames({ "d-none": !row.category?._id })}>
              <BuildingUp size={13} color="grey" className="me-1" />
              {row.category?.name}
            </div>
            <div className={classNames({ "d-none": !row.location?.address })}>
              <MapPin size={13} color="grey" className="me-1" />
              {row.location?.address}
            </div>
            <div className={classNames({ "d-none": !row.price })}>
              <CurrencyDollar size={13} color="grey" className="me-1" />
              {row.price}
            </div>
          </div> */}
        </div>
      ),
    },
    {
      name: "Units",
      sortable: true,

      sortField: "units",
      selector: (row) => row.units,
      cell: (row) => <span>{row.units?.length ?? 0}</span>,
    },
    {
      name: "Deposit",
      sortable: true,

      sortField: "deposit",
      selector: (row) => row.deposit,
      cell: (row) => <span>{row.deposit}</span>,
    },
    {
      name: "Advance",
      sortable: true,

      sortField: "advance",
      selector: (row) => row.advance,
      cell: (row) => <span>{row.advance}</span>,
    },
    {
      name: "Floors",
      sortable: true,

      sortField: "floors",
      selector: (row) => row.floors,
      cell: (row) => <span>{row.floors}</span>,
    },

    {
      name: "Photos",

      sortable: true,
      sortField: "photos",
      wrap: true,
      selector: (row) => row.photos,
      cell: (row) => {
        const images = (row.photos || []).slice(0, 3).map((img) => {
          return { img: `${themeConfig.serverUrl}uploads/${img}` };
        });

        // ({

        // }));
        return <AvatarGroup data={images} />;
      },
    },
    {
      name: "Published",

      sortable: true,
      sortField: "published",
      selector: (row) => row.published,
      cell: (row) => (
        <>
          <div className="form-switch form-check-success">
            <Input
              type="switch"
              checked={row.published}
              disabled={publishedLoading}
              id={`published_${row._id}`}
              name={`published_${row._id}`}
              onChange={(event) => {
                handlePublished(row._id, event.target.checked);
              }}
            />
            <CustomLabel htmlFor={`published_${row._id}`} />
          </div>
          {/* <Badge color={row.published ? 'light-success': 'light-warning'}>{row.published ?  'Yes' : 'No'} </Badge> */}
        </>
      ),
    },

    {
      name: "Featured",

      sortable: true,
      sortField: "published",
      selector: (row) => row.featured,
      cell: (row) => (
        <>
          <div className="form-switch form-check-success">
            <Input
              type="switch"
              checked={row.featured}
              disabled={featuredLoading}
              id={`featured_${row._id}`}
              name={`featured_${row._id}`}
              onChange={(event) => {
                handleFeatured(row._id, event.target.checked);
              }}
            />
            <CustomLabel htmlFor={`featured_${row._id}`} />
          </div>
          {/* <Badge color={row.featured ? 'light-success': 'light-warning'}>{row.featured ?  'Yes' : 'No'} </Badge> */}
        </>
      ),
    },
    {
      name: "Status",
      sortable: true,
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <>
          <Badge
            color={
              row.status == "Available"
                ? "light-success"
                : row.status == "Booked"
                ? "light-warning"
                : "light-danger"
            }
          >
            {row.status}
          </Badge>
        </>
      ),
    },
    {
      name: "Actions",
      width: "11%",
      cell: (row) => (
        <div className="d-flex  ">
          <Can I="read" a={row._id}>
            <Button
              id="select-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => {
                if (ability.can("read", row?._id)) {
                  loginIntoBuilding(row);
                }
              }}
            >
              <LogIn className="font-medium-2" />
              <UncontrolledTooltip placement="top" target="select-btn">
                Select
              </UncontrolledTooltip>
            </Button>
          </Can>

          <Can2 I="update" a="Building" y={appView}>
            <Button
              id="update-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => {
                dispatch(selectBuilding(row));
                setModalShow(true);
              }}
            >
              <Edit className="font-medium-2" color="green" />
              <UncontrolledTooltip placement="top" target="update-btn">
                Edit
              </UncontrolledTooltip>
            </Button>
          </Can2>

          <Can2 I="delete" a="Building" y={appView}>
            <Button
              id="delete-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={(e) => {
                e.preventDefault();
                handleConfirmDelete(row._id, row.name);
              }}
            >
              <Trash2 className="font-medium-2" color="red" />
              <UncontrolledTooltip placement="top" target="delete-btn">
                Delete
              </UncontrolledTooltip>
            </Button>
          </Can2>

          {/* <UncontrolledDropdown>
            <DropdownToggle tag="div" className="btn btn-sm">
              <MoreVertical size={14} className="cursor-pointer" />
            </DropdownToggle>
            <DropdownMenu>
              <Can I="read" a={row._id}>
                <DropdownItem
                  tag={Link}
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    if (ability.can("read", row?._id)) {
                      loginIntoBuilding(row);
                    }
                  }}
                >
                  <LogIn size={14} className="me-50" />
                  <span className="align-middle">Select</span>
                </DropdownItem>
              </Can>

              <Can2 I="update" a="Building" y={appView}>
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(selectBuilding(row));
                    setModalShow(true);
                  }}
                >
                  <Archive size={14} className="me-50" />
                  <span className="align-middle">Edit</span>
                </DropdownItem>
              </Can2>
              <Can2 I="delete" a="Building" y={appView}>
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    handleConfirmDelete(row._id, row.name);
                  }}
                >
                  <Trash2 size={14} className="me-50" />
                  <span className="align-middle">Delete</span>
                </DropdownItem>
              </Can2>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </div>
      ),
    },
  ];

  const [sort, setSort] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState({
    value: "",
    label: "Select Role",
  });
  const [currentPlan, setCurrentPlan] = useState({
    value: "",
    label: "Select Plan",
  });
  const [currentStatus, setCurrentStatus] = useState({
    value: "",
    label: "Select Status",
    number: 0,
  });

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };

  const roleOptions = [
    { value: "", label: "Select Role" },
    { value: "admin", label: "Admin" },
    { value: "author", label: "Author" },
    { value: "editor", label: "Editor" },
    { value: "maintainer", label: "Maintainer" },
    { value: "subscriber", label: "Subscriber" },
  ];

  const statusOptions = [
    { value: "", label: "Select Status", number: 0 },
    { value: "pending", label: "Pending", number: 1 },
    { value: "active", label: "Active", number: 2 },
    { value: "inactive", label: "Inactive", number: 3 },
  ];

  const dataToRender = () => {
    return store.data; //.filter((building) => ability.can("read", building._id));
  };
  const CustomHeader = ({
    store,
    toggleSidebar,
    handlePerPage,
    rowsPerPage,
    handleFilter,
    searchTerm,
  }) => {
    // ** Converts table to CSV
    function convertArrayOfObjectsToCSV(array) {
      let result;

      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(store.data[0]);

      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach((item) => {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key];

          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    }
    // ** Downloads CSV
    function downloadCSV(array) {
      const link = document.createElement("a");
      let csv = convertArrayOfObjectsToCSV(array);
      if (csv === null) return;

      const filename = "export.csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    }

    return (
      <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
        <Row>
          <Col xl="6" className="d-flex align-items-center p-0">
            <div className="d-flex align-items-center w-100">
              <label htmlFor="rows-per-page">Show</label>
              <Input
                className="mx-50"
                type="select"
                id="rows-per-page"
                value={rowsPerPage}
                onChange={handlePerPage}
                style={{ width: "5rem" }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="50">100</option>
              </Input>
              <label htmlFor="rows-per-page">Entries</label>
            </div>
          </Col>
          <Col
            xl="6"
            className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
          >
            <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
              <label className="mb-0" htmlFor="search-invoice">
                Search:
              </label>
              <Input
                id="search-invoice"
                className="ms-50 w-100"
                type="text"
                value={searchTerm}
                // onChange={(e) => handleFilter(e.target.value)}
              />
            </div>

            <div className="d-flex align-items-center table-header-actions">
              <UncontrolledDropdown className="me-1">
                <DropdownToggle color="secondary" caret outline>
                  <Share className="font-small-4 me-50" />
                  <span className="align-middle">Export</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="w-100">
                    <Printer className="font-small-4 me-50" />
                    <span className="align-middle">Print</span>
                  </DropdownItem>
                  <DropdownItem
                    className="w-100"
                    onClick={() => downloadCSV(store.data)}
                  >
                    <FileText className="font-small-4 me-50" />
                    <span className="align-middle">CSV</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <Grid className="font-small-4 me-50" />
                    <span className="align-middle">Excel</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <File className="font-small-4 me-50" />
                    <span className="align-middle">PDF</span>
                  </DropdownItem>
                  <DropdownItem className="w-100">
                    <Copy className="font-small-4 me-50" />
                    <span className="align-middle">Copy</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  const ToggleModal = (show) => {
    if (typeof show == "boolean") {
      setModalShow(show);
    } else {
      setModalShow(!showmodal);
    }
  };

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    dispatch(
      getData({
        limit: value,
        page: currentPage,
      })
    );
    setRowsPerPage(value);
  };

  const [categories, setCategories] = useState([]);
  const getCategories = async () => {
    try {
      var { status, data } = await axios.get("/categories/list");
      if (status == 200) {
        setCategories(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getAllBuildings({ appView, status }));
    getCategories();
  }, [appView, status]);

  return (
    <Fragment>
      <Card className="d-none">
        <CardHeader>
          <CardTitle tag="h4">Property Management</CardTitle>
          {/* <Button
            className="add-new-user align-middle"
            color="primary"
            onClick={() => setModalShow(true)}
          >
            <Briefcase className="me-1" /> Add New Property
          </Button> */}
          <AddNewAddress
            show={showmodal}
            ShowModal={ToggleModal}
            data={{ categories }}
          />
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <Label for="role-select">Select Type</Label>
              <Select
                isClearable={false}
                value={currentRole}
                options={roleOptions}
                className="react-select"
                classNamePrefix="select"
                theme={selectThemeColors}
                onChange={(data) => {}}
              />
            </Col>
            <Col md="6">
              <Label for="status-select">Status</Label>
              <Select
                theme={selectThemeColors}
                isClearable={false}
                className="react-select"
                classNamePrefix="select"
                options={statusOptions}
                value={currentStatus}
                onChange={(data) => {}}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="overflow-hidden">
        <CardBody>
          <div className="d-flex justify-content-between">
            <CardTitle tag="h4">Property Management</CardTitle>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex me-1 gap-1 p-">
                <Grid
                  size={40}
                  role="button"
                  className={`${view === "grid" ? "bg-light" : ""}`}
                  style={{ padding: "5px" }}
                  onClick={() => setView("grid")}
                />
                <List
                  size={40}
                  role="button"
                  className={`${view === "list" ? "bg-light" : ""}`}
                  style={{ padding: "5px" }}
                  onClick={() => setView("list")}
                />
              </div>
              <Can2 I="create" a="Building" y={appView}>
                <Button
                  className="add-new align-middle"
                  color="primary"
                  onClick={() => navigate(`/properties/create?view=${appView}`)}
                >
                  <Briefcase className="me-1" /> Add New Property
                </Button>
              </Can2>
            </div>
          </div>
          {view === "list" ? (
            <div className="react-dataTable ">
              {store.loading ? (
                <Loading cols={columns} />
              ) : (
                <DataTable
                  noHeader
                  subHeader
                  sortServer
                  pagination
                  responsive
                  paginationServer
                  columns={columns}
                  // onSort={handleSort}
                  sortIcon={<ChevronDown />}
                  className="react-dataTable"
                  striped
                  allowOverflow={true}
                  paginationComponent={CustomPagination}
                  highlightOnHover={true}
                  data={dataToRender()} //
                  subHeaderComponent={
                    <CustomHeader
                      store={store}
                      // searchTerm={searchTerm}
                      rowsPerPage={rowsPerPage}
                      // handleFilter={handleFilter}
                      handlePerPage={handlePerPage}
                      // toggleSidebar={toggleSidebar}
                    />
                  }
                />
              )}
            </div>
          ) : (
            <ViewBuildings />
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
}
