import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Input, Label, Table } from "reactstrap";

import { AbilityContext } from "../../../utility/context/Can";
import vertical from "../../../navigation/vertical";
import general from "../../../navigation/general";
import { subject } from "@casl/ability";
import { capitalize } from "lodash";

const TabItem = ({ activeTab, building = null, setTabs, formHooks }) => {
  const {
    setFormData,
    formData,
    resetForm,
    addAbility,
    updateAbility,
    removeAbility,
    getAbility,
    getAbilityIndex,
    getAbilities,
  } = formHooks;

  const [permissions, setPermissions] = useState([]);

  const ability = useContext(AbilityContext);

  const hasAbilityTo = (action, _subject) => {
    const view = building
      ? building
      : activeTab === "properties"
      ? "company"
      : activeTab;

    if (view !== "general") {
      return ability.can(action, subject(_subject, { view: view }));
    }

    return ability.can(action, _subject);
  };

  const checkAll = (e) => {
    const selected = e.target.checked;
    let rules = [...formData.ability];

    if (selected) {
      if (
        activeTab.startsWith("building") &&
        getAbilityIndex(activeTab) === -1
      ) {
        const building = {
          index: activeTab,
          subject: activeTab.split("-")[1],
          action: ["read"],
        };

        rules = [...rules, building];
      }

      permissions?.forEach((ability) => {
        const abilityFound = rules.find((rule) => rule.index === ability.index);

        if (abilityFound) {
          ability.action?.forEach((action) => {
            const actionFound = rules.find(
              (rule) =>
                rule.index === ability.index && rule.action?.includes(action)
            );

            if (!actionFound) {
              const index = rules.findIndex(
                (field) => field.index === ability.index
              );

              if (hasAbilityTo(action, ability.id)) {
                const newAction = [...ability.action, action];
                rules[index] = { ...ability, action: newAction };
              }
            }
          });
        } else {
          let newRole = {
            index: ability.index,
            subject: ability.id,
            action: ability.action?.filter((action) =>
              hasAbilityTo(action, ability.id)
            ),
          };

          newRole = ability?.local
            ? {
                ...newRole,
                condition: {
                  view: building
                    ? building
                    : activeTab === "properties"
                    ? "company"
                    : activeTab,
                },
              }
            : newRole;

          if (newRole.action?.length) rules.push(newRole);
        }
      });
    } else {
      rules =
        activeTab === "general"
          ? []
          : rules?.filter((role) => !role.index?.startsWith(activeTab));
    }

    setFormData({ ...formData, ability: rules });
  };

  const isChecked = (role, action) => {
    return (
      getAbility(getAbilityIndex(role.index))?.action?.includes(action) || false
    );
  };

  const isCheckedAll = () => {
    return permissions?.every((permission) => {
      const found = getAbilities().find(
        (role) => permission.index === role.index
      );

      if (found) {
        return permission.action?.every((action) =>
          found.action.includes(action)
        );
      } else {
        return false;
      }
    });
  };

  const handleAbilityChange = (role, action, selected) => {
    const abilities = getAbilities();
    const selectedSubject = abilities.find(
      (field) => field.index === role.index
    );

    const index = selectedSubject ? getAbilityIndex(role.index) : -1;

    const newActions = selected
      ? [...(selectedSubject?.action || []), action]
      : (selectedSubject?.action || []).filter((item) => item !== action);

    if (!newActions.length && !selected) {
      removeAbility(index);
      return;
    }

    const abilityData = {
      index: role.index,
      subject: role.id,
      action: newActions,
    };

    const updatedAbilityData = role?.local
      ? {
          ...abilityData,
          condition: {
            view: building
              ? building
              : activeTab === "properties"
              ? "company"
              : activeTab,
          },
        }
      : abilityData;

    if (index !== -1) {
      updateAbility(index, updatedAbilityData);
    } else {
      addAbility(updatedAbilityData, activeTab);
    }
  };

  useEffect(() => {
    let nav = [];
    if (activeTab === "general") {
      nav = general
        .map((role) => ({
          index: `${activeTab}-${role.id}`,
          id: role.id,
          title: role.title,
          action: role.action,
          local: false,
        }))
        .filter((role) => hasAbilityTo("read", role.id));
    } else if (activeTab.startsWith("building")) {
      nav = vertical
        .filter((item) => !item.header && !item.view?.includes("!default"))
        .flatMap((role) => {
          if (role?.children) {
            return role.children.map((item) => ({
              index: `building-${building}-${item.id}`,
              id: item.resource,
              title: item.title,
              action: item.action,
              local: true,
            }));
          } else {
            return {
              index: `building-${building}-${role.id}`,
              id: role.resource,
              title: role.title,
              action: role.action,
              local: true,
            };
          }
        });
    } else {
      const currentTab = activeTab === "properties" ? "company" : activeTab;
      nav = vertical
        .filter((item) => !item.header && item.view?.includes(currentTab))
        .flatMap((role) => {
          if (role?.children) {
            return role.children.map((item) => ({
              index: `${activeTab}-${item.id}`,
              id: item.resource,
              title: item.title,
              action: item.action,
              local: true,
            }));
          } else {
            return {
              index: `${activeTab}-${role.id}`,
              id: role.resource,
              title: role.title,
              action: role.action,
              local: true,
            };
          }
        });
    }

    nav = nav
      .map((item) => {
        const allowedActions = item.action.filter((action) =>
          hasAbilityTo(action, item.id)
        );

        // If the user has read access to at least one action, return the item with filtered actions
        if (allowedActions.length > 0) {
          return {
            ...item,
            action: allowedActions,
          };
        }

        return null;
      })
      .filter(Boolean);

    setPermissions(nav);
  }, [activeTab]);

  return (
    <Table className="table-flush-spacing">
      <thead>
        <tr>
          <th width="50%">Subject</th>
          <th>
            <Input
              type="checkbox"
              className="me-1"
              checked={isCheckedAll()}
              onChange={checkAll}
            />
            Ability
          </th>
        </tr>
      </thead>
      <tbody>
        {permissions?.map((role) => (
          <tr key={role.subject}>
            <td>{role.title}</td>
            <td>
              {role?.action?.map((action) => (
                <div key={role + action}>
                  <Input
                    // disabled={!hasAbilityTo(action, role.id)}
                    type="checkbox"
                    id={`${role?.index}-${action}`}
                    checked={isChecked(role, action)}
                    onChange={(e) => {
                      const selected = e.target.checked;
                      handleAbilityChange(role, action, selected);
                    }}
                  />
                  <Label
                    className="form-check-label ms-1"
                    for={`${role?.index}-${action}`}
                  >
                    {capitalize(action)}
                  </Label>
                </div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TabItem;
