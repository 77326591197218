// ** Reactstrap Imports
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

export const Loading = () => {
  return (
    <Row>
      {Array(4)
        .fill()
        .map((item) => (
          <Col key={item} xl={4} md={6}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <span>
                    <Skeleton width={200} height={25} />
                  </span>
                  {/* <AvatarGroup data={item?.users ?? []} /> */}
                </div>
                <div className="d-flex justify-content-between align-items-end mt-1 pt-25">
                  <div className="role-heading">
                    <h4 className="fw-bolder">
                      <Skeleton width={150} height={20} />
                    </h4>
                    <Link
                      to="/"
                      className="role-edit-modal "
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Skeleton width={200} height={10} />
                    </Link>
                  </div>
                  <div>
                    <Skeleton width={20} height={20} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
    </Row>
  );
};
