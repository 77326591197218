import React from "react";
import { Bar } from "react-chartjs-2";

const TenantChart = ({ activeTenants }) => {
  const data = {
    labels: ["Active Tenants"],
    datasets: [
      {
        label: "Number of Active Tenants",
        data: [activeTenants],
        backgroundColor: ["#36a2eb"],
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <div>
      <h2>Active Tenant Report</h2>
      <Bar data={data} options={options} />
    </div>
  );
};

export default TenantChart;
