import React, { useContext } from "react";

// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Input,
  Label,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledAccordion,
} from "reactstrap";

// ** Custom Components

// ** Icons Imports

// ** Steps

// ** Styles
import "@styles/react/pages/modal-create-app.scss";

import { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorHandler } from "../../../common/utils/Error";
import HandleDispatch from "../../../common/utils/HandledDispatch";
import general from "../../../navigation/general";
import { getAllBuildings } from "../../../redux/building";
import { createRole, selectRole, updateRole } from "../../../redux/roles";
import { AbilityContext } from "../../../utility/context/Can";
import TabItem from "./TabItem";

const Form = () => {
  const [loading, setLoading] = useState(false);
  const selectedRole = useSelector((state) => state.roles.selectedRole);
  const [searchParams, setSearchParams] = useSearchParams();
  const action = searchParams.get("action");
  const appView = searchParams.get("view");

  const navigate = useNavigate();

  const [active, setActive] = useState("properties");
  const ability = useContext(AbilityContext);

  const buildings = useSelector((state) => state.buildings.data);
  const dispatch = useDispatch();

  const toggle = (tab) => {
    setActive(tab);
  };

  const [tabs, setTabs] = useState([
    {
      id: "general",
      title: "General Permissions",
    },
    ...general,
  ]);
  const defaultValue = {
    name: "",
    ability: [],
  };

  const [formData, setFormData] = useState(defaultValue);
  const [nameError, setNameError] = useState(null);

  const resetForm = () => {
    setFormData(defaultValue);
  };

  const addAbility = (ability, tab = null) => {
    let abilities = [ability];

    if (tab && tab.startsWith("building") && getAbilityIndex(tab) === -1) {
      const building = {
        index: tab,
        subject: tab.split("-")[1],
        action: ["read"],
      };

      abilities = [building, ...abilities];
    }

    setFormData({ ...formData, ability: [...formData.ability, ...abilities] });
  };

  const updateAbility = (indexToUpdate, ability) => {
    const updatedAbility = [...formData.ability];
    updatedAbility[indexToUpdate] = ability;
    setFormData({
      ...formData,
      ability: updatedAbility,
    });
  };

  const getAbility = (index) => {
    if (index >= 0 && index < formData.ability.length) {
      return formData.ability[index];
    }
    return null;
  };

  const getAbilityIndex = (index) =>
    formData.ability.findIndex((field) => field.index === index);

  const getAbilities = () => {
    return formData.ability;
  };

  const removeAbility = (indexToRemove) => {
    const ability = getAbility(indexToRemove);
    const id = ability?.index.split("-")[1];

    let newAbilities = formData.ability?.filter(
      (item, index) => index !== indexToRemove && !item.index?.startsWith(id)
    );

    if (ability?.subject === "properties") {
      newAbilities = newAbilities?.filter(
        (item) => !item.index?.startsWith("building")
      );
    }

    setFormData({
      ...formData,
      ability: newAbilities,
    });
  };

  const checkBuilding = (building, selected) => {
    if (selected) {
      addAbility({
        index: "building-" + building._id,
        subject: building._id,
        action: ["read"],
      });
    } else {
      const abilities = getAbilities().filter(
        (ability) =>
          !ability.index.startsWith("building-" + building._id) &&
          ability.index !== "building-" + building._id
      );

      setFormData({ ...formData, ability: abilities });
    }
  };

  const formHooks = {
    formData,
    setFormData,
    resetForm,
    addAbility,
    updateAbility,
    removeAbility,
    getAbility,
    getAbilityIndex,
    getAbilities,
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      if (formData.name == "") {
        setNameError("Please enter a valid role name");
        return;
      }
      setLoading(true);

      if (!formData.ability.length) {
        toast.error("Please Choose at least One Role");
        return;
      }

      if (action == "edit") {
        const updatedRole = await HandleDispatch(
          dispatch,
          updateRole({ data: formData, id: selectedRole._id })
        );
        if (updatedRole) {
          toast.success("Role Created Successfully");

          navigate(`/roles?view=${appView}`);
        }
      } else {
        const newRole = await HandleDispatch(dispatch, createRole(formData));
        if (newRole) {
          toast.success("Role Created Successfully");

          navigate(`/roles?view=${appView}`);
        }
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRole) {
      setFormData({
        name:
          action === "duplicate"
            ? selectedRole?.name + " Copy"
            : selectedRole?.name,
        ability: selectedRole?.ability,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getAllBuildings({ appView }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(selectRole(null));
      navigate(`/roles?view=${appView}`);
    };
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle>Create Role</CardTitle>
          <CardSubtitle>Set role permissions</CardSubtitle>
        </CardHeader>
        <CardBody>
          <form onSubmit={onSubmit}>
            <Col md={12}>
              <Label className="form-label" for="roleName">
                Role Name
              </Label>

              <Input
                id="roleName"
                name="name"
                value={formData.name}
                placeholder="Enter role name"
                onChange={(e) => {
                  setNameError(null);
                  setFormData({ ...formData, name: e.target.value });
                }}
              />

              {nameError && <i className="text-danger mt-1">{nameError}</i>}
            </Col>
            <Col md={12}>
              <h4 className="mt-2 pt-50">Role Permissions</h4>
            </Col>

            {/* roles */}

            {/* <Nav pills fill>
              {tabs.map((tab, i) => (
                <NavItem
                  className="text-left rounded-end shadow-sm"
                  key={tab.title}
                >
                  <NavLink
                    className="p-1"
                    active={active === tab.id}
                    onClick={() => toggle(tab.id)}
                    disabled={
                      tab.id !== "general" &&
                      !getAbility(getAbilityIndex(`general-${tab.id}`))
                    }
                  >
                    {tab.title}
                  </NavLink>
                </NavItem>
              ))}
            </Nav> */}

            <TabContent className="py-50" activeTab={active}>
              {tabs.map((tab) => (
                <TabPane tabId={tab.id}>
                  <TabItem
                    activeTab={tab.id}
                    setTabs={setTabs}
                    formHooks={formHooks}
                  />
                </TabPane>
              ))}
            </TabContent>

            {/* end */}

            {/* Buildings */}
            {/* {getAbility(getAbilityIndex("general-properties")) && ( */}
            {/* <> */}
            <Col md={12}>
              <h4 className="mt-2 pt-50">Buildings</h4>
            </Col>
            <UncontrolledAccordion
              className="accordion-margin mt-2"
              defaultOpen="0"
            >
              {buildings
                ?.filter((building) => ability.can("read", building?._id))
                .map((building, indx) => (
                  <AccordionItem key={`${building._id}`}>
                    <AccordionHeader tag="h2" targetId={`${building._id}`}>
                      <div className="form-check me-3 me-lg-5">
                        <Input
                          type="checkbox"
                          id={`${building._id}`}
                          // checked={getAbility(getAbilityIndex(building._id))}
                          checked={
                            getAbility(
                              getAbilityIndex("building-" + building._id)
                            ) || false
                          }
                          onChange={(e) => {
                            const selected = e.target.checked;
                            checkBuilding(building, selected);
                          }}
                        />

                        <Label
                          className="form-check-label"
                          for={`${building._id}`}
                        >
                          {building?.name}
                        </Label>
                      </div>
                    </AccordionHeader>
                    <AccordionBody accordionId={`${building._id}`}>
                      <TabItem
                        activeTab={`building-${building._id}`}
                        building={building._id}
                        setTabs={setTabs}
                        formHooks={formHooks}
                      />
                    </AccordionBody>
                  </AccordionItem>
                ))}
            </UncontrolledAccordion>
            {/* </> */}
            {/* // )} */}

            {/* end */}

            <Col className="text-center mt-2" md={12}>
              <Button
                type="submit"
                className="me-1"
                color="primary"
                disabled={loading}
              >
                <Spinner
                  color="light"
                  size="sm"
                  className="me-1"
                  hidden={!loading}
                />
                {loading ? "Submitting" : "Submit"}
              </Button>
              <Button
                type="reset"
                outline
                onClick={() => {
                  navigate(`/roles?view=${appView}`);
                }}
              >
                Discard
              </Button>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Form;
