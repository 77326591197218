// ** React Imports
import { Download, Printer } from "react-bootstrap-icons";
import generatePDF, { Margin, Resolution } from "react-to-pdf";

// ** Reactstrap Imports
import { Button, Card, CardBody } from "reactstrap";
import useStorage from "../../../utility/hooks/useStorage";

const PreviewActions = ({
  id,
  setSendSidebarOpen,
  setAddPaymentOpen,
  data,
  handlePrint,
  receiptRef,
}) => {
  const { setItem } = useStorage();

  const handleToPDF = () => {
    const filename =
      `Receipt ${data?.receiptNo} for ${data.invoiceId?.customer?.name}.pdf`.trim();
    const options = {
      filename,
      method: "download",
      resolution: Resolution.HIGH,
      page: {
        margin: Margin.SMALL,
      },
      canvas: {
        qualityRatio: 1,
      },

      overrides: {
        pdf: {
          compress: true,
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
          useCORS: true,
        },
      },
    };

    generatePDF(receiptRef, options);
  };

  return (
    <Card className="invoice-action-wrapper">
      <CardBody className="d-flex d-md-flex d-sm-flex flex-column flex-md-column flex-sm-column gap-1 gap-md-1 gap-sm-1">
        {/* <Button
          color="primary"
          block
          className="mb-75"
          onClick={() => setSendSidebarOpen(true)}
        >
          <SendCheck className="me-1" />
          Send Receipt
        </Button> */}
        <Button
          color="primary"
          block
          outline
          className="mb-75"
          onClick={handleToPDF}
        >
          <Download className="me-1" />
          Download
        </Button>

        <Button
          to="print"
          block
          outline
          className="mb-75 btn btn-outline-secondary d-block"
          onClick={() => handlePrint()}
        >
          <Printer className="me-1" />
          Print
        </Button>
        {/* <Button tag={Link} to={`/apps/invoice/edit/${id}`} color='secondary' block outline className='mb-75'>
          Edit
        </Button> */}
        {/* {
          data.status.toLowerCase() != 'paid' ?
           <Button color="success" block onClick={() =>{}}>
         Make Payment
        </Button>
          :null
        } */}
      </CardBody>
    </Card>
  );
};

export default PreviewActions;
