import React from 'react';
import Skeleton from "react-loading-skeleton";
import {
  Row,
  Col, 
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input, 
  Table
} from "reactstrap";
import { 
  FileText, 
  Printer,
  Share,
  Grid,
  Copy,
  File, 
} from "react-feather";
const Loading = ({ cols }) => {
  
  return (
    <div className='m-2'>
      <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
         <Row>
      <Col xl="6" className="d-flex align-items-center p-0">
        <div className="d-flex align-items-center w-100">
          <label htmlFor="rows-per-page">Show</label>
          <Input
            className="mx-50"
            type="select"
            id="rows-per-page"
            style={{ width: "5rem" }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="50">100</option>
          </Input>
          <label htmlFor="rows-per-page">Entries</label>
        </div>
      </Col>
      <Col
        xl="6"
        className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
      >
        <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
          <label className="mb-0" htmlFor="search-invoice">
            Search:
          </label>
          <Input
            id="search-invoice"
            className="ms-50 w-100"
            type="text"
            // value={searchTerm}
            // onChange={(e) => handleFilter(e.target.value)}
          />
        </div>

        <div className="d-flex align-items-center table-header-actions">
          <UncontrolledDropdown className="me-1">
            <DropdownToggle color="secondary" caret outline>
              <Share className="font-small-4 me-50" />
              <span className="align-middle">Export</span>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem className="w-100">
                <Printer className="font-small-4 me-50" />
                <span className="align-middle">Print</span>
              </DropdownItem>
              <DropdownItem
                className="w-100"
                // onClick={() => downloadCSV(store.data)}
              >
                <FileText className="font-small-4 me-50" />
                <span className="align-middle">CSV</span>
              </DropdownItem>
              <DropdownItem className="w-100">
                <Grid className="font-small-4 me-50" />
                <span className="align-middle">Excel</span>
              </DropdownItem>
              <DropdownItem className="w-100">
                <File className="font-small-4 me-50" />
                <span className="align-middle">PDF</span>
              </DropdownItem>
              <DropdownItem className="w-100">
                <Copy className="font-small-4 me-50" />
                <span className="align-middle">Copy</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Col>
    </Row>
      </div>
      
      <Table> 
        <thead>
        <tr>
          {
            cols.map((col, index) => (<th key={index} style={{width:`${col.width}`}}>{ col.name }</th>))
          }
        </tr> 
        </thead>
        <tbody>
          {Array(10)
            .fill()
            .map((item, index) => (
              <tr key={index}>
              {
                cols.map((col, index) => (<td  key={index} style={{width:`${col.width}`}}><Skeleton height={30} /></td>))
              }
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Loading;
