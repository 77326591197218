import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
// ** Icons Imports
import axios from "axios";
import moment from "moment/moment";
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Archive,
  ChevronDown,
  Copy,
  Eye,
  File,
  FileText,
  Grid,
  MoreVertical,
  PlusCircle,
  Printer,
  Share,
  Trash2,
} from "react-feather";
import { toast } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableLoading from "../../@core/components/base/TableLoading";
import { ErrorHandler } from "../../common/utils/Error";
import HandleDispatch from "../../common/utils/HandledDispatch";
import { getData } from "../../redux/receipt";
import {
  deleteReceipt,
  getAllreceipts,
  selectReceipt,
  searchFilter,
} from "../../redux/receipt";
import Loading from "../loading";
import ReceiptForm from "./components/form";
import Can2 from "../../utility/Can";

const MySwal = withReactContent(Swal);

// import "@styles/react/apps/app-invoice.scss";

const CustomHeader = ({
  store,
  toggleSidebar,
  handlePerPage,
  rowsPerPage,
  handleFilter,
  searchTerm,
}) => {
  // ** Converts table to CSV
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(store.data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row>
        <Col xl="6" className="d-flex align-items-center p-0">
          <div className="d-flex align-items-center w-100">
            <label htmlFor="rows-per-page">Show</label>
            <Input
              className="mx-50"
              type="select"
              id="rows-per-page"
              value={rowsPerPage}
              onChange={handlePerPage}
              style={{ width: "5rem" }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="50">100</option>
            </Input>
            <label htmlFor="rows-per-page">Entries</label>
          </div>
        </Col>
        <Col
          xl="6"
          className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
        >
          <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
            <label className="mb-0" htmlFor="search-invoice">
              Search:
            </label>
            <Input
              id="search-invoice"
              className="ms-50 w-100"
              type="text"
              value={searchTerm}
              onChange={(e) => handleFilter(e.target.value)}
            />
          </div>

          <div className="d-flex align-items-center table-header-actions">
            <UncontrolledDropdown className="me-1">
              <DropdownToggle color="secondary" caret outline>
                <Share className="font-small-4 me-50" />
                <span className="align-middle">Export</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="w-100">
                  <Printer className="font-small-4 me-50" />
                  <span className="align-middle">Print</span>
                </DropdownItem>
                <DropdownItem
                  className="w-100"
                  onClick={() => downloadCSV(store.data)}
                >
                  <FileText className="font-small-4 me-50" />
                  <span className="align-middle">CSV</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Grid className="font-small-4 me-50" />
                  <span className="align-middle">Excel</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <File className="font-small-4 me-50" />
                  <span className="align-middle">PDF</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Copy className="font-small-4 me-50" />
                  <span className="align-middle">Copy</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default function Receipts() {
  const [showmodal, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const store = useSelector((state) => state.receipts);
  const customers = useSelector((state) => state.customers.allData);
  const sectionals = useSelector((state) => state.sectionals.allData);

  const [InvoiceCustomers, setInvoiceCustomers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [AllCustomers, setAllCustomers] = useState([]);

  const handleConfirmDelete = (id, name) => {
    return MySwal.fire({
      title: `Delete Reciept`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await HandleDispatch(
          dispatch,
          deleteReceipt(id)
        );

        if (responseStatus.status == 204) {
          toast.success("Reciept Has been Deleted.");
        }
      }
    });
  };

  const columns = [
    {
      name: "#",
      sortable: true,
      // minWidth: "10px",
      // sortField: "name",
      selector: (row) => row.receiptNo,
      cell: (row) => (
        <div className="d-flex justify-content-left align-items-center">
          <div className="d-flex flex-column">
            <span className="fw-bolder text-primary">
              #{row?.receiptNo ?? ""}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Date",
      // minWidth: "100px",
      // sortable: true,
      // sortField: "status",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <span className="text-capitalize">
          {moment(row?.createdAt).format("DD-MM-YYYY")}
        </span>
      ),
    },

    {
      name: "Customer",
      sortable: true,
      // minWidth: "300px",
      // sortField: "name",
      selector: (row) => row?.invoiceId,
      cell: (row) => (
        <div className="d-flex justify-content-left align-items-center">
          <div className="d-flex flex-column">
            <Link
              to={`/customers/${row?.invoiceId?.[0]?.customer?._id}`}
              target="_blank"
              className="user_name text-body" // Remove text-truncate class
              style={{ wordWrap: "break-word", maxWidth: "100%" }} // Add inline styles for text wrapping
            >
              <span className="fw-bolder">
                {row.invoiceId?.[0]?.customer?.name}
              </span>
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Invoice",
      sortable: true,
      // minWidth: "172px",
      // sortField: "floors",
      selector: (row) => row.invoiceId,
      cell: (row) => (
        <div>
          {row.invoiceId?.map((invoice) => (
            <Link
              to={
                `/invoices/${invoice?._id}` +
                (appView ? `?view=${appView}` : "")
              }
            >
              {" "}
              <span>{invoice?.invoiceNo}</span>
            </Link>
          ))}
        </div>
      ),
    },
    {
      name: "Amount",
      // minWidth: "138px",
      // sortable: true,
      // sortField: "discount",
      selector: (row) => row.amount,
      cell: (row) => (
        <Badge color="success" className="text-capitalize fw-bolder">
          ${row.amount}
        </Badge>
      ),
    },
    // {
    //   name: "Balance",
    //   // minWidth: "138px",
    //   // sortable: true,
    //   // sortField: "discount",
    //   selector: (row) => row.apartment?.price,
    //   cell: (row) => (
    //     <Badge color='success' className="text-capitalize fw-bolder">
    //       ${row.balance??0}
    //     </Badge>
    //   ),
    // },
    // {
    //   name: "Method",
    //   // minWidth: "138px",
    //   // sortable: true,
    //   // sortField: "discount",
    //   selector: (row) => row.method,
    //   cell: (row) => (
    //     <span className="text-capitalize fw-bolder">{row.method}</span>
    //   ),
    // },
    {
      name: "Reference",
      // minWidth: "138px",
      // sortable: true,
      // sortField: "discount",
      selector: (row) => row.reference,
      cell: (row) => <span className="text-capitalize">{row.reference}</span>,
    },

    {
      name: "User",
      // minWidth: "100px",
      // sortable: true,
      // sortField: "status",
      selector: (row) => row.createdBy,
      cell: (row) => row.createdBy?.username,
    },
    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="column-action d-flex align-items-center justify-content-center">
          <Link
            // to={`${row._id}` + (appView ? `?view=` + appView : ``)}
            to={`${row._id}` + (appView ? `?view=` + appView : ``)}
            className="mx-1"
            id={`pw-tooltip-${row._id}`}
          >
            <Eye size={17} />
          </Link>
          {/* <UncontrolledTooltip placement="top" target={`pw-tooltip-${row._id}`}>
            Print Receipt
          </UncontrolledTooltip> */}
          {/* <Mail size={17} /> */}
          <UncontrolledDropdown>
            <DropdownToggle tag="div" className="btn btn-sm">
              <MoreVertical size={14} className="cursor-pointer" />
            </DropdownToggle>
            <DropdownMenu>
              <Can2 I="update" a="Receipt">
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(selectReceipt(row));
                    setModalShow(true);
                  }}
                >
                  <Archive size={14} className="me-50" />
                  <span className="align-middle">Edit</span>
                </DropdownItem>
              </Can2>
              <Can2 I="delete" a="Receipt">
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    handleConfirmDelete(row._id, row.name);
                  }}
                >
                  <Trash2 size={14} className="me-50" />
                  <span className="align-middle">Delete</span>
                </DropdownItem>
              </Can2>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const [sort, setSort] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next next-item"}
        previousClassName={"page-item prev prev-item"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };

  const handleFilter = (val) => {
    setSearchTerm(val);
    dispatch(searchFilter(val));
  };

  const dataToRender = () => {
    return store.data;
  };

  const ToggleModal = (show) => {
    if (typeof show == "boolean") {
      setModalShow(show);
    } else {
      setModalShow(!showmodal);
    }
  };

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    dispatch(
      getData({
        limit: value,
        page: currentPage,
      })
    );
    setRowsPerPage(value);
  };

  const getAccounts = async () => {
    try {
      var { status, data } = await axios.get(
        "/accountings/accounts/hierarchy/64089b489d122d411c67abe4"
      );
      if (status == 200) {
        setAccounts(data?.data ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCustomers = async () => {
    try {
      var { status, data } = await axios.get("/customers/list");
      if (status == 200) {
        setAllCustomers(data?.data ?? []);
      }
    } catch (error) {
      console.log(error);
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    dispatch(
      getAllreceipts(
        appView ? [appView] : ["Booking", "Rent", "Bill", "invoice"]
      )
    );
    getAccounts();
    getAllCustomers();
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Receipt Management</CardTitle>

          <Can2 I="create" a="Receipt">
            <Button
              className="add-new-user align-middle"
              color="primary"
              onClick={() => setModalShow(true)}
            >
              <PlusCircle size={18} /> New Receipt
            </Button>
          </Can2>
          <ReceiptForm
            show={showmodal}
            ShowModal={ToggleModal}
            data={{
              accounts,
              customers: AllCustomers,
            }}
            appView={appView}
          />
        </CardHeader>
        <CardBody></CardBody>
      </Card>

      <Card className="overflow-hidden">
        <div className="react-dataTable">
          {store.loading ? (
            <Loading cols={columns} />
          ) : (
            <DataTable
              subHeader
              sortServer
              pagination
              responsive
              paginationServer
              striped
              columns={columns}
              // onSort={handleSort}
              sortIcon={<ChevronDown />}
              noHeader
              className="react-dataTable card-company-table"
              paginationComponent={CustomPagination}
              data={dataToRender()}
              progressPending={store.loading && !store.data.length}
              progressComponent={<TableLoading rows={3} cols={7} />}
              subHeaderComponent={
                <CustomHeader
                  store={store}
                  searchTerm={searchTerm}
                  rowsPerPage={rowsPerPage}
                  handleFilter={handleFilter}
                  handlePerPage={handlePerPage}
                  // toggleSidebar={toggleSidebar}
                />
              }
            />
          )}
        </div>
      </Card>
    </Fragment>
  );
}
