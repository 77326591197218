import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FileExcel, FilterSquare, Printer } from "react-bootstrap-icons";
import { Filter } from "react-feather";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  UncontrolledCollapse,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { ErrorHandler } from "../../common/utils/Error";

import Logo from "@src/assets/images/logo/logo-icon.png";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import TenantChart from "./components/tenants";

export default function TenantsReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [reportDate, setReportDate] = useState(new Date().now);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Tenants Report`,
    pageStyle: `
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always !important;
      }
      .card{
        box-shadow:none !important;
      }
    }
    
    @page {
      size: auto;
    }
    `,
    bodyClass: "",
    // removeAfterPrint: true,
  });

  const queryFields = {
    status: searchParams.get("type") ?? "",
  };

  const getTenantsReport = async () => {
    try {
      const { status, data } = await axios.get("/reports/tenants", {
        params: queryFields,
      });
      if (status == 200) {
        setData(data.data);
      } else {
        toast.error("Error Detected");
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    getTenantsReport();
  }, []);

  return (
    <div>
      <Card className="invoice-preview-card">
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h4> Tenants Report</h4>
          <Button outline color="secondary" id="toggler" className="btn-icon">
            <Filter size={14} />
          </Button>
        </CardHeader>

        <CardHeader>
          <UncontrolledCollapse toggler="#toggler"></UncontrolledCollapse>
        </CardHeader>
      </Card>
      <Card innerRef={printRef} dir="ltr">
        <CardHeader className="justify-content-end gap-1 mb-0 pb-0 no-print">
          <Printer
            size={23}
            className="cursor-pointer"
            title="Print"
            onClick={() => handlePrint()}
          />
          <FileExcel
            size={23}
            className="cursor-pointer"
            title="Export to Excell"
            onClick={() => {
              // beutify before print
              // downloadCSV(data)
            }}
          />
        </CardHeader>
        <CardBody className="invoice-padding px-3 pt-2 pb-0 ">
          {/* Header */}
          <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            <div>
              <div className="logo-wrapper d-flex align-items-center">
                <img src={Logo} alt="" width={50} />
                <h2 className="text-primary invoice-logo">Sakeena</h2>
              </div>
              <CardText className="mb-25">
                Opposite Makka Almukarama Hotel
              </CardText>
              <CardText className="mb-0">
                (+252) 7770223355 , (+252) 770223366
              </CardText>
              <CardText className="mb-25">
                www.sakeena.so , Info@sakeena.so
              </CardText>
            </div>
            <div className="mt-md-0 mt-2">
              <h4 className="invoice-title">
                Active Tenant Report
                <span className="invoice-number">{data.receiptNo}</span>
              </h4>
              <div div className="invoice-date-wrapper d-flex gap-1">
                <p className="invoice-date-title">Date Issued:</p>
                <p className="invoice-date">
                  {moment(data.date).format("YYYY-MM-DD")}
                </p>
              </div>
              <div div className="invoice-date-wrapper d-flex gap-1">
                <p className="invoice-date-title">Property Name(s):</p>
                <p className="invoice-date">
                  {moment(data.date).format("YYYY-MM-DD")}
                </p>
              </div>
            </div>
          </div>
          {/* /Header */}
        </CardBody>
        <hr />

        <CardBody className="invoice-padding px-3 pt-2 pb-0 ">
          {/* Header */}
          <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            <div className="mt-md-0 mt-2">
              <h4 className="invoice-title">Summery</h4>
              <div div className="invoice-date-wrapper ">
                <div className="d-flex gap-1">
                  <p className="invoice-date-title">
                    Total Number of Active Tenants:
                  </p>
                  <p className="invoice-date fw-bold">{data?.length || 0}</p>
                </div>
              </div>
            </div>
          </div>
          {/* /Header */}
        </CardBody>

        <CardBody className="invoice-padding px-3 pt-2 pb-0 ">
          <TenantChart activeTenants={data.length} />
        </CardBody>

        <CardBody className="invoice-padding px-3 pt-2 pb-0">
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Phone</th>
                {/* <th>Address</th> */}
                <th>Unit</th>
                <th>Moved-In</th>
                <th>Expires</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.user?.name}</td>
                  <td>{item.user?.phone}</td>
                  {/* <td>{item.user?.address}</td> */}
                  <td>{item.unit?.name}</td>
                  <td>
                    {moment(item.agreementStartDate).format("DD/MM/YYYY")}
                  </td>
                  <td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={"100%"} className="fw-bolder">
                  Total Entries : {data.length}
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
