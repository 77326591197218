// ** React Imports
import { Fragment, lazy } from "react";
import { Navigate } from "react-router-dom";

// ** Layouts
import BlankLayout from "@layouts/BlankLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import HorizontalLayout from "@src/layouts/HorizontalLayout";
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper";

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute";
import PrivateRoute from "@components/routes/PrivateRoute";

// ** Utils
import { isObjEmpty } from "@utils";
import BuildingList from "../../views/buildings";
import Dashboard from "../../views/dasboard";
import Booking from "../../views/bookings/";
import Invoices from "../../views/invoices";
import Receipts from "../../views/receipts";
import AddListing from "../../views/buildings/AddListing";
import Owners from "../../views/owners";
import Staff from "../../views/hrm/staff";
import Shifts from "../../views/hrm/shifts";
import Leaves from "../../views/hrm/leaves";
import Jobs from "../../views/hrm/jobs";
import StaffForm from "../../views/hrm/staff/form";
import Customers from "../../views/customers";
import Agreements from "../../views/agreements/";
import AgreementForm from "../../views/agreements/form";
import InvoicePreview from "../../views/invoices/preview";
import PrintInvoice from "../../views/invoices/preview/print";
import ReceiptPreview from "../../views/receipts/preview";
import PrintReceipt from "../../views/receipts/preview/print";
import ViewBuilding from "../../views/buildings/components/viewBuildings";
import InvoiceAdd from "../../views/invoices/newInvoice/";
import BuildingView from "../../views/buildings/view";
import Roles from "../../views/roles";
import UtilitiesList from "../../views/utilities";
import UtilityTypes from "../../views/utilityTypes";
import Reports from "../../views/reports";
import TenantsReport from "../../views/reports/tenants";
import PropertiesReport from "../../views/reports/properties";
import UnitsReport from "../../views/reports/units";
import OwnersReport from "../../views/reports/owners";
import LeasesReports from "../../views/reports/leases";
import ServicesReports from "../../views/reports/services";
import AppSetting from "../../views/settings";
import JournalEntryList from "../../views/accountings/journal";
import AccountStatement from "../../views/accountings/financialStatements/accountStatement";
import BalanceSheetStatement from "../../views/accountings/financialStatements/balancesheet";
import IncomeStatement from "../../views/accountings/financialStatements/incomeStatement";
import TrialBalance from "../../views/accountings/financialStatements/trialbalance";
import AccountingBooksList from "../../views/accountings/accountingBooks";
import ViewBuildings from "../../views/buildings/components/viewBuildings";
import Payroll from "../../views/hrm/payroll";
import AgreementDetail from "../../views/agreements/components/agreementDetail";
import Vendors from "../../views/vendors";
import Form from "../../views/roles/components/form";
import BuildingsView from "../../views/buildings/buildingsView";
import { UnitsView } from "../../views/sectionals/view";
import CustomerProfile from "../../views/customers/profile";
import OwnerView from "../../views/owners/profile";
import StaffProfile from "../../views/hrm/staff/profile";
import SectionalCategory from "../../views/sectional-category";
import PrintStatement from "../../views/customers/statement/print";
import BulkUnitsForm from "../../views/sectionals/BulkUnitsForm";
const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
};

// ** Document title
const TemplateTitle = "%s - Property Management System";

// ** Default Route
const DefaultRoute = "/home";

const Home = lazy(() => import("../../views/home/"));
const CompanyHome = lazy(() => import("../../views/home/company"));
const SecondPage = lazy(() => import("../../views/SecondPage"));
const Login = lazy(() => import("../../views/auth/Login"));
const NotAuthorized = lazy(() => import("../../views/misc/NotAuthorized"));
const Register = lazy(() => import("../../views/auth/Register"));
const ForgotPassword = lazy(() => import("../../views/auth/ForgotPassword"));
const UserList = lazy(() => import("../../views/user/list/index"));
const SectionalList = lazy(() => import("../../views/sectionals"));
const ExpensesList = lazy(() => import("../../views/expenses"));
const AccountsList = lazy(() => import("../../views/chartOfAccount"));
const PaymentsList = lazy(() => import("../../views/payments"));
const CategoryList = lazy(() => import("../../views/categories"));
const AmenityList = lazy(() => import("../../views/amenities"));

// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
  },
  {
    path: "/home",
    element: <Home />,
    meta: {
      menuHidden: true,
    },
  },
  {
    path: "/company",
    layout: "vertical",
    element: <CompanyHome />,
    meta: {
      // menuHidden: true,
    },
  },
  {
    path: "/login",
    element: <BlankLayout />,
    children: [{ path: "/login", element: <Login /> }],
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/register",
    element: <Register />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/not-authorized",
    element: <BlankLayout />,
    children: [{ path: "/not-authorized", element: <NotAuthorized /> }],
  },
  {
    path: "/users",
    element: <UserList />,
    meta: {
      action: "read",
      resource: "User",
    },
  },
  {
    path: "/roles",
    element: <Roles />,
    meta: {
      action: "read",
      resource: "Role",
    },
  },
  {
    path: "/roles/form",
    element: <Form />,
    meta: {
      action: "create",
      resource: "Role",
    },
  },
  {
    path: "/hrm/staff",
    element: <Staff />,
    meta: {
      action: "read",
      resource: "Staff",
    },
  },
  {
    path: "/hrm/staff/:id",
    element: <StaffProfile />,
    meta: {
      action: "read",
      resource: "Staff",
    },
  },
  {
    path: "/hrm/staff/create",
    element: <StaffForm />,
    meta: {
      action: "read",
      resource: "Staff",
    },
  },
  {
    path: "/hrm/shifts",
    element: <Shifts />,
    meta: {
      action: "read",
      resource: "Shift",
    },
  },
  {
    path: "/hrm/leaves",
    element: <Leaves />,
    meta: {
      action: "read",
      resource: "Leaves",
    },
  },
  {
    path: "/hrm/jobs",
    element: <Jobs />,
    meta: {
      action: "read",
      resource: "Job",
    },
  },
  {
    path: "/hrm/payroll",
    element: <Payroll />,
    meta: {
      action: "read",
      resource: "payroll",
    },
  },
  {
    path: "/properties",
    element: <BuildingList />,
    meta: {
      action: "read",
      resource: "Building",
    },
  },
  {
    path: "/properties/view",
    element: <ViewBuildings />,
    meta: {
      action: "read",
      resource: "Building",
    },
  },
  {
    path: "/properties/create",
    element: <AddListing />,
    meta: {
      action: "create",
      resource: "Building",
    },
  },

  {
    path: "/buildings",
    element: <BuildingsView />,
    meta: {
      menuHidden: true,
    },
  },
  {
    path: "/properties/:buildingId",
    element: <BuildingView />,
    meta: {
      action: "read",
      resource: "Building",
    },
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    meta: {
      action: "read",
      resource: "Dashboard",
    },
  },
  {
    path: "/rental_applications",
    element: <Booking />,
    meta: {
      action: "read",
      resource: "Booking",
    },
  },
  {
    path: "/units",
    element: <SectionalList />,
    meta: {
      action: "read",
      resource: "Sectional",
    },
  },
  {
    path: "/units/create-bulk",
    element: <BulkUnitsForm />,
    meta: {
      action: "read",
      resource: "Sectional",
    },
  },
  {
    path: "/units/:id",
    element: <UnitsView />,
    meta: {
      action: "read",
      resource: "Sectional",
    },
  },
  {
    path: "/units-category",
    element: <SectionalCategory />,
    meta: {
      action: "read",
      resource: "SectionalCategory",
    },
  },
  {
    path: "/expenses",
    element: <ExpensesList />,
    meta: {
      action: "read",
      resource: "Expense",
    },
  },
  {
    path: "/accounts",
    element: <AccountsList />,
    meta: {
      action: "read",
      resource: "Account",
    },
  },

  {
    path: "/chart-of-accounts",
    element: <AccountsList />,
    meta: {
      action: "read",
      resource: "Account",
    },
  },
  {
    path: "/payments",
    element: <PaymentsList />,
    meta: {
      action: "read",
      resource: "Payment",
    },
  },
  {
    path: "/categories",
    element: <CategoryList />,
    meta: {
      action: "read",
      resource: "Category",
    },
  },
  {
    path: "/amenities",
    element: <AmenityList />,
    meta: {
      action: "read",
      resource: "Amenity",
    },
  },
  {
    path: "/invoices",
    element: <Invoices />,
    meta: {
      action: "read",
      resource: "Invoice",
    },
  },
  {
    path: "/invoices/new",
    element: <InvoiceAdd />,
    meta: {
      action: "create",
      resource: "Invoice",
    },
  },
  {
    path: "/invoices/:id",
    element: <InvoicePreview />,
    meta: {
      action: "read",
      resource: "Invoice",
    },
  },
  {
    path: "/invoices/:id/print",
    element: <PrintInvoice />,
    meta: {
      action: "read",
      resource: "Invoice",
      layout: "blank",
    },
  },
  {
    path: "/receipts",
    element: <Receipts />,
    meta: {
      action: "read",
      resource: "Receipt",
    },
  },
  {
    path: "/receipts/:id",
    element: <ReceiptPreview />,
    meta: {
      action: "read",
      resource: "receipts",
    },
  },
  {
    path: "/receipts/:id/print",
    element: <PrintReceipt />,
    meta: {
      action: "read",
      resource: "receipts",
      layout: "blank",
    },
  },
  {
    path: "/buildings/create",
    element: <AddListing />,
    meta: {
      action: "read",
      resource: "Building",
    },
  },
  {
    path: "/owners",
    element: <Owners />,
    meta: {
      action: "read",
      resource: "Owner",
    },
  },
  {
    path: "/owners/:id",
    element: <OwnerView />,
    meta: {
      action: "read",
      resource: "Owner",
    },
  },
  {
    path: "/customers",
    element: <Customers />,
    meta: {
      action: "read",
      resource: "Customer",
    },
  },
  {
    path: "/customers/:id",
    element: <CustomerProfile />,
    meta: {
      action: "read",
      resource: "Customer",
    },
  },
  {
    path: "/customers/:id/statement/print",
    element: <PrintStatement />,
    meta: {
      layout: "blank",
      action: "read",
      resource: "Customer",
    },
  },
  {
    path: "/vendors",
    element: <Vendors />,
    meta: {
      action: "read",
      resource: "Vendor",
    },
  },
  {
    path: "/leases",
    element: <Agreements />,
    meta: { action: "read", resource: "Agreement" },
  },
  {
    path: "/leases/:id",
    element: <AgreementDetail />,
    meta: { action: "read", resource: "Agreement" },
  },
  {
    path: "/leases/create",
    element: <AgreementForm />,
    meta: { action: "create", resource: "Agreement" },
  },
  {
    path: "/utilities",
    element: <UtilitiesList />,
    meta: { action: "read", resource: "Utility" },
  },
  {
    path: "/utility/type",
    element: <UtilityTypes />,
    meta: {},
  },
  {
    path: "/reports",
    element: <Reports />,
    meta: {
      resource: "Report",
      action: "read",
    },
  },
  {
    path: "/reports/tenants",
    element: <TenantsReport />,
    meta: { resource: "Reports", action: "read" },
  },
  {
    path: "/reports/tenants/statement",
    element: <TenantsReport />,
    meta: { resource: "Reports", action: "read" },
  },
  {
    path: "/reports/properties/rent_roll",
    element: <PropertiesReport />,
    meta: { resource: "Reports", action: "read" },
  },
  {
    path: "/reports/units/:type",
    element: <UnitsReport />,
    meta: { resource: "Reports", action: "read" },
  },
  {
    path: "/reports/owners/ownership",
    element: <OwnersReport />,
    meta: { resource: "Reports", action: "read" },
  },
  {
    path: "/reports/owners",
    element: <OwnersReport />,
    meta: { resource: "Reports", action: "read" },
  },
  {
    path: "/reports/leases",
    element: <LeasesReports />,
    meta: { resource: "Reports", action: "read" },
  },
  {
    path: "/reports/services",
    element: <ServicesReports />,
    meta: { resource: "Reports", action: "read" },
  },
  {
    path: "/settings",
    element: <AppSetting />,
    meta: { resource: "Setting", action: "read" },
  },
  {
    path: "/configs",
    element: <AppSetting />,
    meta: { resource: "Setting", action: "read" },
  },
  {
    path: "accountings/ledger",
    element: <JournalEntryList />,
    meta: { resource: "Journal", action: "read" },
  },
  {
    path: "accountings/account-statement",
    element: <AccountStatement />,
    meta: { resource: "Account Statement", action: "read" },
  },
  {
    path: "accountings/income-statement",
    element: <IncomeStatement />,
    meta: { resource: "icnomeStatement", action: "read" },
  },
  {
    path: "accountings/balance-sheet",
    element: <BalanceSheetStatement />,
    meta: { resource: "balancesheet", action: "read" },
  },
  {
    path: "accountings/cashflow",
    element: <BalanceSheetStatement />,
    meta: { resource: "cashflow", action: "read" },
  },
  {
    path: "accountings/trial-balance",
    element: <TrialBalance />,
    meta: { resource: "trialbalance", action: "read" },
  },
  {
    path: "accountings/books",
    element: <AccountingBooksList />,
    meta: { resource: "Accounting_books", action: "read" },
  },
];

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = [];

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute;

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute;
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment;

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          );
        }
        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical";
  const layouts = ["vertical", "horizontal", "blank"];

  const AllRoutes = [];

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });

  return AllRoutes;
};

export { DefaultRoute, TemplateTitle, Routes, getRoutes };
