import React, { useEffect, useState } from "react";
import { LogIn, MapPin } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { getAllBuildings, getData } from "../../redux/building";

import StatsVertical from "@components/widgets/stats/StatsVertical";
import "@styles/base/pages/app-ecommerce.scss";
import { Chart, registerables } from "chart.js";
import { useContext } from "react";
import { EyeFill } from "react-bootstrap-icons";
import ReactPaginate from "react-paginate";
import themeConfig from "../../configs/themeConfig";
import { CurrentBuildingContext } from "../../contexts/currentBuilding";
import { getCompanyDashboard } from "../../redux/dashboard";

Chart.register(...registerables);

import { Building, Files } from "react-bootstrap-icons";
import Loading from "./Loading";
import Barchart from "./charts/barchart";
import vertical from "../../navigation/vertical";
import { AbilityContext } from "../../utility/context/Can";
import { subject } from "@casl/ability";
import { getFirstAvailableRoute } from "../../utility/Utils";

const tooltipShadow = "rgba(0, 0, 0, 0.25)",
  gridLineColor = "rgba(200, 200, 200, 0.2)",
  lineChartPrimary = "#666ee8",
  lineChartDanger = "#ff4961",
  warningColorShade = "#ffbd1f",
  warningLightColor = "#FDAC34",
  successColorShade = "#28dac6",
  primaryColorShade = "#836AF9",
  infoColorShade = "#299AFF",
  yellowColor = "#ffe800",
  greyColor = "#4F5D70",
  blueColor = "#2c9aff",
  blueLightColor = "#84D0FF",
  greyLightColor = "#EDF1F4",
  labelColor = "#6e6b7b";

export default function CompanyHome() {
  const store = useSelector((state) => state.buildings);
  const CurrentBuilding = useSelector((state) => state.CurrentBuilding);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);

  const { currentBuilding, logInBuilding } = useContext(CurrentBuildingContext);
  const companyDashboard = useSelector((state) => state.dashboard.company);

  useEffect(() => {
    if (currentBuilding) {
      navigate(
        getFirstAvailableRoute(ability, currentBuilding?._id, "building")
      );
    }
  }, [currentBuilding]);

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  function generateColors(size) {
    const colors = [];
    const hueStep = 360 / size;

    for (let i = 0; i < size; i++) {
      const hue = i * hueStep;
      const color = `hsl(${hue}, 100%, 50%)`;
      colors.push(color);
    }

    return colors;
  }

  const getAvailableUnitsPropertychartdata = (availableUnitsPerProperty) => {
    return {
      labels: availableUnitsPerProperty?.map((n) => n.name),
      datasets: [
        {
          maxBarThickness: 30,
          backgroundColor: successColorShade,
          borderColor: "transparent",
          borderRadius: { topRight: 0, topLeft: 0 },
          data: availableUnitsPerProperty?.map((n) => n.total),
        },
      ],
    };
  };

  const getUnitsperProperty = (unitsPerProperty) => {
    const count = unitsPerProperty?.length;
    return {
      labels: unitsPerProperty?.map((n) => n.name),
      datasets: [
        {
          maxBarThickness: 30,
          backgroundColor: successColorShade,
          borderColor: "transparent",
          borderRadius: { topRight: 0, topLeft: 0 },
          data: unitsPerProperty?.map((n) => n.count),
        },
      ],
    };
  };

  const chartOptions = ({ max = 100 }) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      animation: { duration: 500 },
      scales: {
        x: {
          grid: {
            color: gridLineColor,
            borderColor: gridLineColor,
          },
          ticks: { color: labelColor },
        },
        y: {
          min: 0,
          max: max / 2 == 0 ? max : max + 1,
          grid: {
            color: gridLineColor,
            borderColor: gridLineColor,
          },
          ticks: {
            stepSize: Math.ceil(max / 10),
            color: labelColor,
          },
        },
      },
      plugins: {
        legend: { display: false },
      },
    };
  };

  const statusValue = (list, status) => {
    const result = list?.find((item) => item._id === status);
    return result ? result?.count : 0;
  };

  if (!companyDashboard) return <Loading />;

  return (
    <div>
      <Row>
        {/* Stats With Icons */}
        <Col
          md="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => navigate("/properties?view=company")}
        >
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={companyDashboard?.property?.all?.total ?? 0}
            statTitle="All Properties"
          />
        </Col>

        <Col
          md="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => navigate("/properties?view=company&status=Available")}
        >
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={statusValue(
              companyDashboard?.property?.buildingByStatus,
              "Available"
            )}
            statTitle="Available Properties"
          />
        </Col>

        <Col
          md="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => navigate("/properties?view=company&status=Reserved")}
        >
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={statusValue(
              companyDashboard?.property?.buildingByStatus,
              "Reserved"
            )}
            statTitle="Occupied Properties"
          />
        </Col>

        <Col
          md="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => navigate("/properties?view=company&status=Sold")}
        >
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={statusValue(
              companyDashboard?.property?.buildingByStatus,
              "Sold"
            )}
            statTitle="Sold Properties"
          />
        </Col>

        <Col
          md="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => navigate("/units?view=company")}
        >
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={companyDashboard?.units?.all?.total ?? 0}
            statTitle="All Units"
          />
        </Col>
        <Col
          md="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => navigate("/units?view=company&status=Available")}
        >
          <StatsVertical
            icon={<Files size={21} />}
            color="success"
            stats={statusValue(
              companyDashboard?.units?.unitsByStatus,
              "Available"
            )}
            statTitle="Available Units"
          />
        </Col>
        <Col
          md="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => navigate("/units?view=company&status=Booked")}
        >
          <StatsVertical
            icon={<Files size={21} />}
            color="warning"
            stats={statusValue(
              companyDashboard?.units?.unitsByStatus,
              "Booked"
            )}
            statTitle="Booked Units"
          />
        </Col>
        <Col
          md="3"
          sm="6"
          className="cursor-pointer"
          onClick={() => navigate("/units?view=company&status=Reserved")}
        >
          <StatsVertical
            icon={<Files size={21} />}
            color="danger"
            stats={statusValue(
              companyDashboard?.units?.unitsByStatus,
              "Reserved"
            )}
            statTitle="Occupied Units"
          />
        </Col>

        <Col sm={12} md={6}>
          <Card>
            <CardBody>
              <CardTitle>Available Units Per Property</CardTitle>
              <Barchart
                options={chartOptions({
                  max: companyDashboard?.units?.all?.total,
                })}
                data={getAvailableUnitsPropertychartdata(
                  companyDashboard?.property?.availableUnitsPerProperty
                )}
              />
            </CardBody>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card>
            <CardBody>
              <CardTitle>Total Units Per Property</CardTitle>
              <Barchart
                options={chartOptions({
                  max: companyDashboard?.units?.all?.total,
                })}
                data={getUnitsperProperty(
                  companyDashboard?.units?.unitsPerProperty
                )}
              />
            </CardBody>
          </Card>
        </Col>

        {/* Stats With Icons */}
      </Row>

      {/* <div className="ecommerce-application">
        {store.data.length ? (
          <>
            <section className="grid-view wishlist-items">
              {renderBuilding()}
            </section>
            <CustomPagination />
          </>
        ) : (
          <Alert color="info">
            <div className="alert-body">
              <Info size={14} />
              <span className="align-middle ms-50">No Building Found.</span>
              <a href="/property/create">Create Your First Building</a>
            </div>
          </Alert>
        )}
      </div> */}
    </div>
  );
}
