// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import {
  createCategory,
  getAllcategories,
  updateCategory,
} from "../../../redux/category";
import { createOwner, getAllOwners, updateOwner } from "../../../redux/owner";
import HandleDispatch from "../../../common/utils/HandledDispatch";

const OwnerModel = ({ show, ShowModal }) => {
  const selectedOwner = useSelector((state) => state.owners.selectedOwner);

  const [loading, setloading] = useState(false);

  const defaultValues = {
    name: "",
    phone: "",
    address: "",
    docs: [],
  };
  // ** Hooks
  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  useEffect(() => {
    reset({ ...selectedOwner });
  }, [selectedOwner]);
  const dispatch = useDispatch();
  //   resolver: yupResolver(schema),
  const [files, setFiles] = useState([]);

  const onSubmit = async (data) => {
    setloading(true);
    try {
      if (selectedOwner) {
        var updatedOwner = await HandleDispatch(
          dispatch,
          updateOwner({ id: selectedOwner._id, data })
        );
        if (updatedOwner) {
          toast.success("Owner Information updated Succesfully");
          reset();
          ShowModal();
        }
      } else {
        var response = await HandleDispatch(dispatch, createOwner(data));
        if (response) {
          toast.success("New Owner Created Succesfully");
          reset();
          ShowModal();
        }
      }
    } catch (error) {
      console.error(error);
    }
    setloading(false);
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    reset();
  };
  useEffect(() => {
    dispatch(getAllOwners());
  }, []);
  // const store = useSelector((state) => state.buildings);
  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={ShowModal}
        className=""
        modalClassName="modal-dark"
      >
        <ModalHeader toggle={ShowModal} className="bg-white">
          Add New Owner
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5 px-sm-4 mx-20">
            <Row className="gy-1 gx-2 flex-column">
              <Col xs={12}>
                <Label className="form-label" for="name">
                  Owner Name
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="name"
                      placeholder="Owner name"
                      {...register(
                        "name",
                        { required: true },
                        "Owner Name is required"
                      )}
                      invalid={errors.name && true}
                      {...field}
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <Label className="form-label" for="phone">
                  Phone
                </Label>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="phone"
                      placeholder="+2526"
                      invalid={errors.phone && true}
                      {...field}
                    />
                  )}
                />
                {errors.phone && (
                  <FormFeedback>Please enter a valid Phone</FormFeedback>
                )}
              </Col>

              <Col xs={12}>
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="email"
                      placeholder="admin@example.com"
                      invalid={errors.email && true}
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <FormFeedback>Please enter a valid Email</FormFeedback>
                )}
              </Col>

              <Col xs={12}>
                <Label className="form-label" for="emergency_contact">
                  Emergency Contact
                </Label>
                <Controller
                  name="emergency_contact"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="emergency_contact"
                      placeholder="+00 0000 0000"
                      invalid={errors.emergency_contact && true}
                      {...field}
                    />
                  )}
                />
                {errors.emergency_contact && (
                  <FormFeedback>Please enter a valid Contact</FormFeedback>
                )}
              </Col>

              <Col xs={12}>
                <Label className="form-label" for="address">
                  Address
                </Label>
                <Controller
                  name="address"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="address"
                      placeholder="Taleex,Hodan Mogadishu Somalia"
                      invalid={errors.address && true}
                      {...field}
                    />
                  )}
                />
                {errors.address && (
                  <FormFeedback>Please enter a valid Address</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading ? "Submiting" : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default OwnerModel;
