import { Fragment, useState, useEffect } from "react";
import * as yup from "yup";
import {
  Row,
  Col,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
  Alert,
  Tooltip,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import {
  createUtility,
  getAllUtilities,
  updateUtility,
} from "../../redux/utilities";
import HandleDispatch from "../../common/utils/HandledDispatch";
import { ErrorHandler, ExtractError } from "../../common/utils/Error";
import CreatableSelect from "react-select/creatable";
import axios from "axios";
import Select from "react-select";
import { Check, X } from "react-feather";
import { formatCurrency } from "../../utility/Utils";
import moment from "moment";

const UtilityForm = ({ row, show, ShowModal, toggleType, selectedRow }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [used, setUsed] = useState(0);
  const [rate, setRate] = useState("");
  const [includeVAT, setIncludeVAT] = useState(false); // State for VAT inclusion
  const [vatTooltipOpen, setVatTooltipOpen] = useState(false); // Tooltip state for VAT

  const defaultValues = {
    type: "",
    agreement: "",
    prev: 0,
    current: 0,
    amount: "",
    recurring: false,
    recurringFrequency: "",
    recurringStarts: "",
    recurringEnds: "",
    date: moment().format("YYYY-MM-DD"),
    reference: "",
    includeVAT: false, // Default value for VAT inclusion
  };

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    setValue,
    setFocus,
    trigger,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();
  const [btnloading, setBtnloading] = useState(false);
  const [utilityTypes, setUtilityTypes] = useState([]);
  const [activeAggreements, setActiveAggreements] = useState([]);
  const currentBuilding = useSelector((state) => state.CurrentBuilding.data);

  // Function to calculate VAT amount and total with VAT
  const calculateVAT = (amount) => {
    if (includeVAT) {
      const vatAmount = parseFloat(amount) * 0.05;
      return {
        vatAmount: vatAmount.toFixed(2),
        totalWithVAT: (parseFloat(amount) + vatAmount).toFixed(2),
      };
    }
    return {
      vatAmount: "0.00",
      totalWithVAT: parseFloat(amount).toFixed(2),
    };
  };

  const onSubmit = async (data) => {
    setBtnloading(true);
    setErrorMessage(null);
    try {
      if (!currentBuilding) {
        toast.error("Select a building First");
        return;
      }

      data.building = currentBuilding._id;

      if (data.unit && used <= 0) {
        throw new Error("New Value cannot be the same as last");
      }

      if (!data.amount || data.amount <= 0) {
        throw new Error(
          "Please enter missing values to calculate total amount"
        );
      }

      // Apply VAT if included
      if (data.includeVAT) {
        const vatResult = calculateVAT(data.amount);
        data.vatAmount = vatResult.vatAmount;
        data.totalWithVAT = vatResult.totalWithVAT;
      }

      if (row) {
        const response = await HandleDispatch(
          dispatch,
          updateUtility({ id: row._id, data })
        );
        if (response) {
          toast.success("Bill Information updated Successfully");
          reset();
          ShowModal();
        }
      } else {
        data.billType = data.type.label;
        data.rate = data.type.rate;
        data.unit = data.type.unit;
        data.type = data.type.value;
        data.agreement = data.agreement.value;

        const response = await HandleDispatch(dispatch, createUtility(data));
        if (response) {
          toast.success("Bill Registered Successfully");
          reset();
          ShowModal();
        }
      }
      dispatch(getAllUtilities());
    } catch (error) {
      setErrorMessage(ExtractError(error));
    } finally {
      setBtnloading(false);
    }
  };

  useEffect(() => {
    if (row) {
      reset({
        unit: row.unit,
        rate: row.rate,
        type: row.type,
        includeVAT: row.includeVAT,
      });
    }
  }, [row]);

  const onDiscard = () => {
    ShowModal();
    reset(defaultValues);
    setUsed("");
    setRate("");
  };

  const getAllTypes = async () => {
    try {
      const params = {};
      if (currentBuilding) {
        params.building = currentBuilding?._id;
      }
      const response = await axios.get("/utilityTypes/list", { params });

      if ((response.status = 200)) {
        setUtilityTypes(response.data.data);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const getAllActiveAgreements = async () => {
    try {
      const params = {};
      if (currentBuilding) {
        params.building = currentBuilding?._id;
      }
      const response = await axios.get("/agreements/status/Active", { params });

      if ((response.status = 200)) {
        setActiveAggreements(response.data.data);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const getLastBillInfo = async (type, agreement) => {
    try {
      const { status, data } = await axios.get("/utilities/last", {
        params: {
          type,
          agreement,
        },
      });

      if (status == 200) {
        if (data.data) {
          let lastbill = data.data;
          setValue("prev", lastbill.current);
          setValue("current", "");
          setValue("amount", "");
        } else {
          setValue("current", 0);
          setValue("prev", 0);
          setValue("amount", 0);
        }
      } else {
        toast.error("No last bill information");
      }
    } catch (error) {
      ExtractError(error);
    }
  };

  useEffect(() => {
    Promise.all([getAllTypes(), getAllActiveAgreements()]);
  }, []);

  const totalAmount = parseFloat(watch("amount")) || 0;
  const vatResults = calculateVAT(totalAmount);

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onDiscard}
        modalClassName="modal-lg"
        size="md"
        backdrop="static"
      >
        <ModalHeader toggle={onDiscard} className="py-1 bg-white">
          {row ? "Edit Utility Bill" : "New Utility Bill"}
        </ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5 mx-50">
            {errorMessage && (
              <Alert color="danger" className="px-2 py-1">
                {errorMessage}
              </Alert>
            )}

            <Row>
              {/* Existing form fields */}
              <Col className="mb-1" sm={12} md={6}>
                <Label className="form-label" for="type">
                  Bill Type
                </Label>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      id="type"
                      placeholder="Select Type"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.type && true}
                      onCreateOption={(value) => {
                        if (toggleType) {
                          toggleType();
                        }
                      }}
                      options={utilityTypes.map((type) => ({
                        value: type._id,
                        label: type.type,
                        ...type,
                      }))}
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        setValue("amount", 0);
                        setValue("agreement", "");
                        trigger("type");

                        var { type } = getValues();

                        setUsed("");
                        setRate(type.rate);
                      }}
                    />
                  )}
                />
              </Col>
              <Col className="mb-1" sm={12} md={6}>
                <Label className="form-label" for="agreement">
                  Select Agreement
                </Label>
                <Controller
                  name="agreement"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      id="agreement"
                      placeholder="Select Type"
                      className="react-select"
                      classNamePrefix="select"
                      isClearable={true}
                      invalid={errors.type && true}
                      options={activeAggreements.map((agg) => ({
                        value: agg._id,
                        label: `${agg.agreementName}   - ${
                          agg.unit?.name ?? agg.building?.name
                        }`,
                      }))}
                      {...field}
                      onChange={(value) => {
                        if (value) {
                          var selectedType = getValues().type;
                          if (!selectedType) {
                            setFocus("type");
                            toast.error("Please select a Type");
                            return;
                          }

                          if (selectedType.unit) {
                            getLastBillInfo(selectedType.value, value.value);
                          } else {
                            setValue("amount", selectedType.rate);
                          }
                        }

                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </Col>
              {getValues().type?.unit ? (
                <>
                  <Col className="mb-1" sm={12} md={4}>
                    <Label className="form-label" for="prev">
                      Last {getValues().type?.unit}
                    </Label>
                    <Controller
                      name="prev"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          id="prev"
                          type="number"
                          placeholder=""
                          disabled
                          invalid={errors.unit && true}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col className="mb-1" sm={12} md={4}>
                    <Label className="form-label" for="current">
                      New {getValues().type?.unit}
                    </Label>
                    <Controller
                      name="current"
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="current"
                          placeholder=""
                          type="number"
                          min={watch("prev")}
                          step="0.0001"
                          invalid={errors.unit && true}
                          {...field}
                          onChange={(e) => {
                            if (e.target.value) {
                              var { type, prev } = getValues();

                              if (!type) {
                                setFocus("type");
                                toast.error("Please select a Type First");
                                return;
                              }

                              var totalAmount =
                                (Number(e.target.value) - prev) * type.rate;

                              setUsed(Number(e.target.value) - prev);
                              setRate(type.rate);

                              setValue("amount", totalAmount?.toFixed(2));
                            } else {
                              setValue("amount", 0);
                            }
                            trigger("amount");
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col className="mb-1" sm={12} md={4}>
                    <Label className="form-label" for="current">
                      Used {getValues().type?.unit}
                    </Label>
                    <Controller
                      name="used"
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="used"
                          disabled
                          placeholder=""
                          type="number"
                          value={used}
                        />
                      )}
                    />
                  </Col>
                  <Col className="mb-1" sm={12} md={4}>
                    <Label className="form-label" for="rate">
                      Rate
                    </Label>
                    <Controller
                      name="rate"
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="rate"
                          disabled
                          placeholder=""
                          type="number"
                          value={rate}
                        />
                      )}
                    />
                  </Col>
                </>
              ) : null}

              <Col className="mb-1" sm={12} md={4}>
                <Label className="form-label" for="tamount">
                  Total Amount
                </Label>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="tamount"
                      placeholder="0"
                      disabled
                      invalid={errors.unit && true}
                      {...field}
                    />
                  )}
                />
              </Col>

              {/* New VAT Inclusion Field */}
              <Col xs={12} md={4} className="mt-2">
                <div className="form-check form-switch">
                  <Controller
                    name="includeVAT"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="switch"
                        id="includeVAT"
                        name="includeVAT"
                        checked={field.value}
                        onChange={(e) => {
                          setIncludeVAT(e.target.checked);
                          field.onChange(e.target.checked);
                        }}
                        className="form-check-input"
                      />
                    )}
                  />
                  <Label
                    className="form-check-label"
                    for="includeVAT"
                    id="vatTooltip"
                  >
                    Include VAT (5%)
                  </Label>
                  <Tooltip
                    isOpen={vatTooltipOpen}
                    target="vatTooltip"
                    toggle={() => setVatTooltipOpen(!vatTooltipOpen)}
                  >
                    This will add a 5% VAT to the invoice amount.
                  </Tooltip>
                </div>
              </Col>

              {/* VAT Amount Field */}
              <Col className="mb-1" sm={12} md={4}>
                <Label className="form-label" for="vatAmount">
                  VAT Amount (5%)
                </Label>
                <Input
                  id="vatAmount"
                  placeholder="0"
                  disabled
                  value={includeVAT ? vatResults.vatAmount : "0.00"}
                />
              </Col>

              {/* Total with VAT Field */}
              <Col className="mb-1" sm={12} md={4}>
                <Label className="form-label" for="totalWithVAT">
                  Total with VAT
                </Label>
                <Input
                  id="totalWithVAT"
                  placeholder="0"
                  disabled
                  value={
                    includeVAT
                      ? vatResults.totalWithVAT
                      : totalAmount.toFixed(2)
                  }
                />
              </Col>

              <Col className="mb-1" sm={12} md={4}>
                <Label className="form-label" for="date">
                  Issue Date
                </Label>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="date"
                      type="date"
                      invalid={errors.unit && true}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col className="mb-1" sm={12} md={4}>
                <Label className="form-label" for="reference">
                  Reference {}
                </Label>
                <Controller
                  name="reference"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="reference"
                      placeholder="Ex. 1"
                      invalid={errors.unit && true}
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>

            {/* Updated Bottom Total Section */}
            <Row className="justify-content-end mt-2">
              <Col md={3}>
                <div>
                  <p>Total</p>
                  <h3 className="fw-bolder">
                    {includeVAT
                      ? formatCurrency(vatResults.totalWithVAT)
                      : formatCurrency(totalAmount)}
                  </h3>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={btnloading}
            >
              <Spinner size={"sm"} hidden={!btnloading} className="me-1" />
              {btnloading ? "Loading.." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
              disabled={btnloading}
            >
              Discard
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default UtilityForm;
