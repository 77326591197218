// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import ReactSelect from "react-select";
import {
  createCustomer,
  selectCustomer,
  updateCustomer,
} from "../../../redux/customer";
import { store } from "../../../redux/store";
import HandleDispatch from "@commons/utils/HandledDispatch";

const CustomerModel = ({ show, ShowModal }) => {
  const customerStore = useSelector((state) => state.customers);
  // var store = useSelector((state) => state.customers);
  const selectedCustomer = customerStore.selectedCustomer;

  const [btnLoading, setbtnLoading] = useState(false);

  const defaultValues = {
    name: "",
    phone: "",
    email: "",
    gender: "male",
    address: "",
  };

  // ** Hooks
  const {
    register,
    reset,
    getValues,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset({ ...selectedCustomer });
  }, [selectedCustomer]);
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  const onSubmit = async (data) => {
    setbtnLoading(true);
    data.gender = data.gender.value;
    try {
      if (selectedCustomer) {
        const updateResponse = await HandleDispatch(
          dispatch,
          updateCustomer({ id: selectedCustomer._id, data })
        );

        if (updateResponse) {
          toast.success("Customer Information updated Successfully");
          reset();
          ShowModal();
        }
      } else {
        var createResponse = await HandleDispatch(
          dispatch,
          createCustomer(data)
        );
        if (createResponse) {
          toast.success("New Customer Created Successfully");
          reset();
          ShowModal();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setbtnLoading(false);
    }
  };

  const onDiscard = () => {
    console.log("discard called");
    dispatch(selectCustomer(null));
    clearErrors();
    reset();
    ShowModal(false);
  };

  // const store = useSelector((state) => state.buildings);
  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={ShowModal}
        className=""
        modalClassName=""
      >
        <ModalHeader toggle={ShowModal} className="bg-white">
          New Customer
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}

            <Row className="gy-1 gx-2">
              <Col xs={6}>
                <Label className="form-label" for="name">
                  Customer Name
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="name"
                      placeholder="Customer name"
                      {...register(
                        "name",
                        { required: true },
                        "Customer Name is required"
                      )}
                      invalid={errors.name && true}
                      {...field}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>
              <Col xs={6}>
                <Label className="form-label" for="gender">
                  Gender
                </Label>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      id="gender"
                      {...register(
                        "gender",
                        { required: true },
                        "Gender is required"
                      )}
                      options={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                      ]}
                      invalid={errors.gender && true}
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>
              <Col xs={6}>
                <Label className="form-label" for="phone">
                  Phone
                </Label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="phone"
                      type="tell"
                      placeholder="+2526XX"
                      {...register(
                        "phone",
                        { required: true },
                        "Phone Number is required"
                      )}
                      invalid={errors.phone && true}
                      {...field}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>
              <Col xs={6}>
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="email"
                      type="email"
                      placeholder="admin@rukun.so"
                      {...register("email")}
                      {...field}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>
              <Col xs={12}>
                <Label className="form-label" for="address">
                  Address
                </Label>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="address"
                      placeholder="Hodan"
                      {...register(
                        "address",
                        { required: true },
                        "Address is required"
                      )}
                      {...field}
                    />
                  )}
                />
                {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={customerStore.loading}
            >
              {customerStore.loading ? "Submitting" : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default CustomerModel;
