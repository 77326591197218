import { useState } from "react";
import DataTable from "react-data-table-component";

import defaultImg from "@src/assets/images/logo/logo.png";
import ReactPaginate from "react-paginate";
import TableLoading from "../../../../@core/components/base/TableLoading";

import { Badge, Card, CardTitle, CardHeader, CardBody } from "reactstrap";

// ** Icons Imports
import classNames from "classnames";
import moment from "moment";
import { AlertTriangle, ChevronDown } from "react-feather";
import { convertDaysToMonthsAndYears } from "../../../../utility/helper";
import { Fragment } from "react";
const AgreementsTab = ({ property }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [agreements, setAgreements] = useState(property.agreements);

  const columns = [
    {
      name: "Customer",
      sortable: true,
      sortField: "name",
      minWidth: "300px",
      // width: "20%",
      selector: (row) => row.user?.name ?? "",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <div className="avatar rounded">
            <div className="avatar-content">
              <img
                src={defaultImg}
                className="w-100 h-100 object-fit-scale"
                alt={row.user?.name}
              />
            </div>
          </div>
          <div className="ms-1">
            <div className="fw-bolder">{row.user?.name}</div>
            <div className="font-small-2 text-muted">{row.user?.email}</div>
          </div>
        </div>
      ),
    },
    {
      name: "Amount",
      sortable: true,
      selector: (row) => row.amount ?? "",
      cell: (row) => <Badge color="light-primary">${row.amount ?? ""}</Badge>,
    },

    {
      name: "Start Date",
      sortable: true,
      sortField: "email",
      selector: (row) => row.startDate ?? "",
      cell: (row) => (
        <div className="">
          {moment(row.startDate).format("DD MMM YYYY") ?? ""}
        </div>
      ),
    },
    {
      name: "End Date",
      sortable: true,
      sortField: "email",
      selector: (row) => row.endDate ?? "",
      cell: (row) => (
        <div className="">
          {moment(row.endDate).format("DD MMM YYYY") ?? ""}
        </div>
      ),
    },
    {
      name: "Remaining",
      sortable: true,
      selector: (row) => null,
      cell: (row) => (
        <div
          className={classNames("fw-bolder", {
            "text-danger":
              moment(row.endDate).diff(moment()) < 0 && row.status == "Active",
          })}
        >
          {moment(row.endDate).diff(moment()) < 0 && row.status != "Active" ? (
            0
          ) : row.status == "Terminated" ? (
            0
          ) : moment(row.endDate).diff(moment()) < 0 ? (
            <>
              <AlertTriangle
                size={14}
                className="text-danger align-middle me-1"
              />
              Expired
              {/* {moment().to(moment(row.endDate))} */}
            </>
          ) : (
            <>
              {" "}
              {convertDaysToMonthsAndYears(
                moment(row.endDate).diff(moment(), "days")
              )}
            </>
          )}
          {/* {moment(row.endDate).format("DD MMM YYYY") ?? ""} */}
        </div>
      ),
    },
    {
      name: "Status",
      minWidth: "100px",
      sortable: true,
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <Badge
          color={row.status.toLowerCase() == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },
  ];

  const handlePagination = (page) => {
    console.log(page);
    const startIndex = page * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const currentPageData = property.agreements.slice(startIndex, endIndex);

    setAgreements(currentPageData);
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(agreements / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const dataToRender = () => {
    return agreements;
  };

  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">
          <CardTitle> Agreement List</CardTitle>
          {/* <Button color="primary">New Agreement</Button> */}
        </CardHeader>
        <CardBody>
          <div className="react-dataTable user-view-account-projects">
            {property.agreements.length === 0 ? (
              <h5>No Data</h5>
            ) : (
              <DataTable
                sortServer
                pagination
                responsive
                paginationServer
                striped
                columns={columns}
                // onSort={handleSort}
                sortIcon={<ChevronDown />}
                className="react-dataTable react-dataTable-selectable-rows"
                paginationComponent={CustomPagination}
                data={dataToRender()}
                progressPending={!property.agreements.length}
                progressComponent={<TableLoading rows={1} cols={7} />}
                // selectableRowsComponent={Boos}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default AgreementsTab;
