import React, { useEffect } from "react";

import BuildingDashboard from "./building";
import SalesAndBrokerDashboard from "./SalesAndBrokerDashboard";
import ConsultationDashboard from "./consultationDashboard";
import MaintainanceDashboard from "./maintainanceDashboard";

import { useSearchParams } from "react-router-dom";
import RevenueReport from "./revenueReport";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyDashboard,
  getPropertyDashboard,
  getServiceDashboard,
} from "../../redux/dashboard";
import CompanyHome from "../home/company";

export default function Dashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const dispatch = useDispatch();

  useEffect(() => {
    if (appView) {
      if (appView === "company") {
        dispatch(getCompanyDashboard());
      } else {
        dispatch(getServiceDashboard(appView));
      }
    } else {
      dispatch(getPropertyDashboard());
    }
  }, []);

  return (
    <div>
      {appView == "salesAndBroker" ? (
        <SalesAndBrokerDashboard />
      ) : appView == "consultation" ? (
        <ConsultationDashboard />
      ) : appView == "maintainance" ? (
        <MaintainanceDashboard />
      ) : appView == "company" ? (
        <CompanyHome />
      ) : (
        <BuildingDashboard />
      )}
    </div>
  );
}
