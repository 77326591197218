import React from "react";
import { Card, CardBody, CardTitle, Col, Row, Spinner } from "reactstrap";

import StatsVertical from "@components/widgets/stats/StatsVertical";
import "@styles/base/pages/app-ecommerce.scss";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

import { Building, Files } from "react-bootstrap-icons";
import Skeleton from "react-loading-skeleton";

export default function Loading() {
  return (
    <div>
      <Row>
        {/* Stats With Icons */}
        <Col md="3" sm="6">
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={<Spinner />}
            statTitle="All Properties"
          />
        </Col>

        <Col md="3" sm="6">
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={<Spinner />}
            statTitle="Available Properties"
          />
        </Col>

        <Col md="3" sm="6">
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={<Spinner />}
            statTitle="Occupied Properties"
          />
        </Col>

        <Col md="3" sm="6">
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={<Spinner />}
            statTitle="Sold Properties"
          />
        </Col>

        <Col md="3" sm="6">
          <StatsVertical
            icon={<Building size={21} />}
            color="info"
            stats={<Spinner />}
            statTitle="All Units"
          />
        </Col>
        <Col md="3" sm="6">
          <StatsVertical
            icon={<Files size={21} />}
            color="success"
            stats={<Spinner />}
            statTitle="Available Units"
          />
        </Col>
        <Col md="3" sm="6">
          <StatsVertical
            icon={<Files size={21} />}
            color="warning"
            stats={<Spinner />}
            statTitle="Booked Units"
          />
        </Col>
        <Col md="3" sm="6">
          <StatsVertical
            icon={<Files size={21} />}
            color="danger"
            stats={<Spinner />}
            statTitle="Reserved Units"
          />
        </Col>

        <Col sm={12} md={6}>
          <Card>
            <CardBody>
              <CardTitle>Available Units Per Property</CardTitle>
              <Skeleton height={500} />
            </CardBody>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card>
            <CardBody>
              <CardTitle>Total Units Per Property</CardTitle>
              <Skeleton height={500} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
