import React from "react";

import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import themeConfig from "../../../configs/themeConfig.js";

// ** Icons Imports
import {
  ChevronDown,
  Copy,
  File,
  FileText,
  Grid,
  Printer,
  Share,
  Trash2,
  User,
} from "react-feather";

import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import ReactPaginate from "react-paginate";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StatsHorizontal from "@components/widgets/stats/StatsHorizontal";
import { PencilFill, PersonAdd } from "react-bootstrap-icons";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import TableLoading from "../../../@core/components/base/TableLoading";
import HandleDispatch from "../../../common/utils/HandledDispatch";
import {
  deleteStaff,
  getAllStaff,
  getData,
  getStaffFigures,
  searchStaff,
} from "../../../redux/staff";
import Loading from "../../loading";

const MySwal = withReactContent(Swal);

const CustomHeader = ({
  store,
  toggleSidebar,
  handlePerPage,
  rowsPerPage,
  searchTerm,
  handleFilter,
}) => {
  // ** Converts table to CSV
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(store.data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  // ** Downloads CSV
  function downloadCSV(array) {
    array = array.map((item, idx) => ({}));

    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "customers.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row>
        <Col xl="6" className="d-flex align-items-center p-0">
          <div className="d-flex align-items-center w-100">
            <label htmlFor="rows-per-page">Show</label>
            <Input
              className="mx-50"
              type="select"
              id="rows-per-page"
              value={rowsPerPage}
              onChange={handlePerPage}
              style={{ width: "5rem" }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="50">100</option>
            </Input>
            <label htmlFor="rows-per-page">Entries</label>
          </div>
        </Col>
        <Col
          xl="6"
          className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
        >
          <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
            <label className="mb-0" htmlFor="search-invoice">
              Search:
            </label>
            <Input
              id="search-invoice"
              className="ms-50 w-100"
              type="text"
              value={searchTerm}
              onChange={(e) => {
                handleFilter(e.target?.value);
              }}
            />
          </div>

          <div className="d-flex align-items-center table-header-actions">
            <UncontrolledDropdown className="me-1">
              <DropdownToggle color="secondary" caret outline>
                <Share className="font-small-4 me-50" />
                <span className="align-middle">Export</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="w-100">
                  <Printer className="font-small-4 me-50" />
                  <span className="align-middle">Print</span>
                </DropdownItem>
                <DropdownItem
                  className="w-100"
                  onClick={() => {
                    // var exct = store.data.map((v)=>({...v,}))
                    downloadCSV(store.data);
                  }}
                >
                  <FileText className="font-small-4 me-50" />
                  <span className="align-middle">CSV</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Grid className="font-small-4 me-50" />
                  <span className="align-middle">Excel</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <File className="font-small-4 me-50" />
                  <span className="align-middle">PDF</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <Copy className="font-small-4 me-50" />
                  <span className="align-middle">Copy</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default function Staff() {
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const store = useSelector((state) => state.staff);
  // console.log(store);

  const handleConfirmDelete = async (id) => {
    const result = await MySwal.fire({
      title: `Delete Staff?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    });
    if (result.value) {
      const deletedID = await HandleDispatch(dispatch, deleteStaff(id));
      if (deletedID) {
        dispatch(getStaffFigures());
      } else {
        toast.success("Staff Deleted Successfully");
      }
    }
  };

  const columns = [
    {
      name: "Name",
      minWidth: "20%",
      sortable: true,
      sortField: "name",
      selector: (row) => row.name ?? "",
      cell: (row) => (
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            const url =
              `/hrm/staff/${row._id}` + (appView ? "?view=" + appView : "");
            navigate(url);
            // dispatch(selectCustomer(row));
          }}
        >
          <div className="avatar rounded">
            <div className="avatar-content">
              {row.photo && row.photo !== "no-image" ? (
                <img
                  src={`${themeConfig.serverUrl}uploads/${row?.photo}`}
                  alt="User Avatar"
                />
              ) : (
                <User />
              )}
            </div>
          </div>
          <div className="ms-1">
            <div className="fw-bolder">{row.name}</div>
            <div className="font-small-2 text-muted">{row.email}</div>
          </div>
        </div>
      ),
    },
    {
      name: "Title",
      sortable: true,
      cell: (row) => <div>{row.job?.name ?? ""}</div>,
    },
    {
      name: "Phone",
      sortable: true,
      cell: (row) => <div>{row.contacts ?? ""}</div>,
    },
    {
      name: "Department",
      sortable: true,
      cell: (row) => <div>{row.department?.name ?? ""}</div>,
    },
    {
      name: "Emergency",
      sortable: true,
      minWidth: "15%",

      cell: (row) => (
        <div
          className="d-flex justify-content-left align-items-center"
          style={{ whiteSpace: "normal", minWidth: "150px" }}
        >
          <div className="d-flex flex-column">
            <span className="fw-bold">{row.emergencyContactName}</span>
            <small
              className="text-truncate text-muted mb-0"
              style={{ wordWrap: "break-word", maxWidth: "100%" }}
            >
              {row.emergencyContact}
            </small>
          </div>
        </div>
      ),
    },

    {
      name: "Status",

      sortable: true,
      sortField: "status",
      cell: (row) => (
        <Badge
          color={row.status.toLowerCase() === "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },
    {
      name: "Actions",

      cell: (row) => (
        <div
          className="column-action"
          style={{ whiteSpace: "normal", minWidth: "100px" }}
        >
          <Link
            to={`/hrm/staff/create?staff=${row._id}`}
            size="sm"
            color="flat-primary"
          >
            <PencilFill size={15} />
          </Link>
          <Button.Ripple
            className="btn-icon"
            size="sm"
            color="flat-danger"
            onClick={(e) => {
              handleConfirmDelete(row._id, row.name);
            }}
          >
            <Trash2 size={15} />
          </Button.Ripple>
        </div>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };
  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const handleFilter = (val) => {
    setSearchTerm(val);
    if (val.length > 0) return dispatch(searchStaff(val));
    dispatch(getAllStaff());
    // handleSearch(val);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    // only call redux method
  };

  const dataToRender = () => {
    return store.data;
  };

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    dispatch(
      getData({
        limit: value,
        page: currentPage,
      })
    );
    setRowsPerPage(value);
  };

  useEffect(() => {
    dispatch(getAllStaff());
    HandleDispatch(dispatch, getStaffFigures());
  }, []);

  return (
    <Fragment>
      <Row>
        <Col lg="4" sm="6">
          <StatsHorizontal
            color="primary"
            statTitle="All Staff"
            icon={<FileText size={20} />}
            renderStats={
              store.figuresLoading && store.figures.total < 1 ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {store.figures.total} </h3>
              )
            }
          />
        </Col>
        <Col lg="4" sm="6">
          <StatsHorizontal
            color="success"
            statTitle="Active Staff"
            icon={<FileText size={20} />}
            renderStats={
              store.figuresLoading && store.figures.total < 1 ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {store.figures.active} </h3>
              )
            }
          />
        </Col>
        <Col lg="4" sm="6">
          <StatsHorizontal
            color="danger"
            statTitle="InActive Staff"
            icon={<FileText size={20} />}
            renderStats={
              store.figuresLoading && store.figures.total < 1 ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {store.figures.inactive} </h3>
              )
            }
          />
        </Col>
      </Row>
      <Card className="overflow-hidden">
        <CardHeader className="d-flex align-items-start">
          <CardTitle tag="h4">Staff Information</CardTitle>
          <Link
            to={"/hrm/staff/create"}
            className="btn btn-primary"
            color="primary"
          >
            <PersonAdd className="me-1" size={20} /> Add Staff
          </Link>
        </CardHeader>

        <div className="">
          {store.loading ? (
            <Loading cols={columns} />
          ) : (
            <DataTable
              subHeader
              sortServer
              pagination
              responsive
              paginationServer
              striped
              columns={columns}
              // onSort={handleSort}
              sortIcon={<ChevronDown />}
              className="react-dataTable react-dataTable-selectable-rows"
              paginationComponent={CustomPagination}
              data={dataToRender()}
              progressPending={store.loading && !store.data.length}
              progressComponent={<TableLoading rows={1} cols={7} />}
              subHeaderComponent={
                <CustomHeader
                  store={store}
                  searchTerm={searchTerm}
                  rowsPerPage={rowsPerPage}
                  handleFilter={handleFilter}
                  handlePerPage={handlePerPage}
                  // toggleSidebar={toggleSidebar}
                />
              }
            />
          )}
        </div>
      </Card>
    </Fragment>
  );
}
