import { forwardRef, useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  Table,
  Input,
  Button,
} from "reactstrap";
import { formatCurrency, getDateRangeByFilter } from "../../../utility/Utils";
import { FilePdf, Printer } from "react-bootstrap-icons";
import { Mail } from "react-feather";
import { useDispatch } from "react-redux";
import { getCustomerStatement } from "../../../redux/customer";
import Preview from "./preview";
import { useReactToPrint } from "react-to-print";
import PrintStatement from "./print";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import { useNavigate } from "react-router-dom";

const Statement = ({ store }) => {
  const customer = store.data.customerInfo;
  const statement = store.data?.statement || [];
  const [filter, setFilter] = useState("this month");
  const [filterDate, setFilterDate] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().endOf("month").format("YYYY-MM-DD"),
  });

  const navigate = useNavigate();

  const statementRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (filter !== "custom") setFilterDate(getDateRangeByFilter(filter));
  }, [filter]);

  useEffect(() => {
    if (customer)
      dispatch(getCustomerStatement({ ...filterDate, id: customer?._id }));
  }, [filterDate, store.data.customerInfo]);

  const handlePrint = useReactToPrint({
    content: () => statementRef.current,
    documentTitle: `${customer?.name} Statement from ${filterDate?.start} to ${filterDate?.end}`,
    pageStyle: `
    @page {
      size: 210mm 148mm;
      }
      @media print {
      @page {  size: A4 portrait; }
          margin: 5mm !important;
      }
    }
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
       
        display: block;
        page-break-before: always !important;
      }
      .card{
        box-shadow:none !important;
      }
    }
    
    @page {
      size: auto;
    }
    `,
    bodyClass: "",
  });

  const handleToPDF = () => {
    const filename =
      `${customer?.name} Statement from ${filterDate?.start} to ${filterDate?.end}.pdf`.trim();
    const options = {
      filename,
      method: "open",
      resolution: Resolution.HIGH,
      page: {
        margin: Margin.SMALL,
      },
      canvas: {
        qualityRatio: 1,
      },

      overrides: {
        pdf: {
          compress: true,
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
          useCORS: true,
        },
      },
    };

    generatePDF(statementRef, options);
  };

  return (
    <>
      {/* <PrintStatement
        statement={statement}
        customer={customer}
        filterDate={filterDate}
        statementRef={statementRef}
      /> */}

      <div ref={statementRef}>
        <Card className="invoice-preview-card p-1">
          <CardBody className="invoice-padding pb-0">
            {/* Header */}
            <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <div className="mb-1">
                  <Input
                    type="select"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="mb-1"
                  >
                    <option value="today">Today</option>
                    <option value="this week">This Week</option>
                    <option value="this month">This Month</option>
                    <option value="last month">Last Month</option>
                    <option value="this year">This Year</option>
                    <option value="last year">Last Year</option>
                    <option value="custom">Custom</option>
                  </Input>
                  {filter === "custom" && (
                    <>
                      <Input
                        type="date"
                        name="start"
                        onChange={(e) =>
                          setFilterDate({
                            ...filterDate,
                            start: e.target.value,
                          })
                        }
                        className="mb-1"
                      />
                      <Input
                        type="date"
                        name="end"
                        onChange={(e) =>
                          setFilterDate({ ...filterDate, end: e.target.value })
                        }
                        className="mb-1"
                      />
                    </>
                  )}
                </div>

                <div className="logo-wrapper mb-2">
                  <h4>Customer Statement For {customer?.name}</h4>
                </div>
              </div>

              <div className="mt-md-0 mt-2">
                <div className="text-end">
                  <Button
                    outline
                    onClick={() =>
                      navigate(
                        `statement/print?start=${filterDate?.start}&end=${filterDate?.end}`
                      )
                    }
                  >
                    <Printer size={20} className="text-dark" />
                  </Button>
                  <Button outline className=" ms-1" onClick={handleToPDF}>
                    <FilePdf size={20} className="text-dark " />
                  </Button>
                  <Button outline className=" ms-1">
                    <Mail size={20} className="text-dark" />
                  </Button>
                </div>
                <div className="text-end my-3">
                  <CardText className="mb-25">
                    <b>Jamhuriya Technology Solutions - Jtech</b>
                  </CardText>
                  <CardText className="mb-25">
                    Apartment 103, 1st Floor, Adani Tower 2, Makka Almukaramah
                    Street
                  </CardText>
                  <CardText className="mb-0">Mogadishu Benadir</CardText>
                  <CardText className="mb-0">Somalia</CardText>
                </div>
              </div>
            </div>
            {/* /Header */}
          </CardBody>

          <hr className="invoice-spacing" />

          {/* Address and Contact */}
          <CardBody className="invoice-padding pt-0">
            <Row className="invoice-spacing">
              <Col className="p-0" xl="8">
                <h6 className="mb-1">To:</h6>
                <h6 className="mb-25"> {customer?.name}</h6>
              </Col>
              <Col className="p-0 mt-xl-0 mt-2 text-end" xl="4">
                <h4>Account Summary</h4>
                <p>
                  {filterDate.start} To {filterDate.end}
                </p>
              </Col>
            </Row>
            <Row className="invoice-spacing ">
              <Col className="p-0" xl="7"></Col>
              <Col className="p-0 mt-xl-0 mt-2  " xl="5">
                <hr className="invoice-spacing" />
                <div className="d-flex justify-content-between">
                  <div>
                    <p>Beginning Balance:</p>
                    <p>Invoiced Amount: </p>
                    <p>Amount Paid:</p>
                    <p>
                      <b>Balance Due:</b>
                    </p>
                  </div>
                  <div className="mb-0 text-end">
                    <p>
                      {formatCurrency(
                        store.data?.statement?.summery?.beginningBalance
                      )}
                    </p>
                    <p>
                      {formatCurrency(
                        store.data?.statement?.summery?.invoiceAmount
                      )}
                    </p>
                    <p>
                      {formatCurrency(
                        store.data?.statement?.summery?.receiptAmount
                      )}
                    </p>
                    <p>
                      <b>
                        {formatCurrency(
                          store.data?.statement?.summery?.balanceDue
                        )}
                      </b>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <hr className="invoice-spacing" /> */}
            {/* Beginning Balance: $2,154.00 
        Invoiced Amount: $390.00 
        Amount Paid:
            $2,139.00 Balance Due: $405.00 */}
            {/* <hr className="invoice-spacing" /> */}
          </CardBody>
          {/* /Address and Contact */}

          <div className="text-center mb-2">
            <b>
              Showing all invoices and payments between {filterDate.start} and{" "}
              {filterDate.end}
            </b>
          </div>
          <h5></h5>

          {/* Invoice Description */}
          <Table responsive>
            <thead>
              <tr>
                <th className="py-1" width={"10%"}>
                  Date
                </th>
                <th className="py-1" width={"45%"}>
                  Details
                </th>
                <th className="py-1 text-end" width={"15%"}>
                  Invoice
                </th>
                <th className="py-1 text-end" width={"15%"}>
                  Receipt
                </th>
                <th className="py-1 text-end" width={"15%"}>
                  Balance
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-1">
                  <p className="card-text text-nowrap">{filterDate.start}</p>
                </td>
                <td className="py-1" width={"15%"}>
                  <span className="fw-bold">Beginning Balance</span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold"> </span>
                </td>
                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold"> </span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold">
                    {formatCurrency(
                      store.data?.statement?.summery?.beginningBalance
                    )}
                  </span>
                </td>
              </tr>
              {/* {data.items.map((item, idx) => (
           ))}   */}

              {store.data?.statement?.detail?.map((item) => (
                <tr>
                  <td className="py-1">
                    <p className="card-text text-nowrap">{item.date}</p>
                  </td>
                  <td className="py-1" width={"15%"}>
                    <span>{item.type}</span>
                    <span>
                      {" "}
                      (
                      <a
                        href={`/${
                          item.type === "Invoice" ? "invoices" : "receipts"
                        }/${item._id}`}
                        target="_blank"
                      >
                        #{item.id}
                      </a>
                      ){" "}
                    </span>
                    <span>{item.detail}</span>
                  </td>

                  <td className="py-1 text-end" width={"15%"}>
                    <span className="fw-bold">
                      {item.type === "Invoice" && formatCurrency(item.amount)}
                    </span>
                  </td>
                  <td className="py-1 text-end" width={"15%"}>
                    <span className="fw-bold">
                      {item.type === "Receipt" && formatCurrency(item.amount)}
                    </span>
                  </td>

                  <td className="py-1 text-end" width={"15%"}>
                    <span className="fw-bold">
                      {formatCurrency(item.balance || 0)}
                    </span>
                  </td>
                </tr>
              ))}

              <tr>
                <td className="py-1">
                  <p className="card-text text-nowrap"> </p>
                </td>
                <td className="py-1" width={"15%"}>
                  <span className="fw-bold"> </span>
                </td>

                <td className="py-1  " width={"30%"} colSpan={2}>
                  <span className="fw-bold"> Balance Due</span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold">
                    {formatCurrency(store.data?.statement?.summery?.balanceDue)}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
          {/* /Invoice Description */}

          <hr className="invoice-spacing" />

          {/* /Invoice Note */}
        </Card>
      </div>
    </>
  );
};

export default Statement;
