// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useEffect } from "react";

import { createJob, getAllJobs, updateJob } from "../../../../redux/jobs";
import HandleDispatch from "../../../../common/utils/HandledDispatch";

const JobModel = ({ show, ShowModal }) => {

  const selectedJob = useSelector(
    (state) => state.jobs.selectedJob
  );

  const [loading, setloading] = useState(false);

  const defaultValues = {
    name: "",
  };
  // ** Hooks
  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  useEffect(() => {
    reset({ ...selectedJob });
  }, [selectedJob]);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const onSubmit = async (data) => {
    setloading(true);
    try {
     
      if (selectedJob) {
        var updatedJob = await HandleDispatch(
          dispatch,
          updateJob({ id: selectedJob._id, data })
        );
        if (updatedJob) {
          toast.success("Job Information updated Successfully");
          reset();
          ShowModal();
        }
      } else {
        var response = await HandleDispatch(dispatch, createJob(data));
        if (response) {
          toast.success("New Job Created Successfully");
          reset();
          ShowModal();
        }
      }
    } catch (error) {
      console.error(error);
    }
    setloading(false);
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    reset();
  };
  
  useEffect(() => {
    dispatch(getAllJobs());
  }, []);
  // const store = useSelector((state) => state.buildings);
  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={ShowModal}
        className=""
        modalClassName="modal-dark"
      >
        <ModalHeader toggle={ShowModal} className="bg-white">
          Add New Job
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}

            <Row className="gy-1 gx-2 flex-column">
              <Col xs={12}>
                <Label className="form-label" for="name">
                  Job Name
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="name"
                      placeholder="job name"
                      {...register(
                        "name",
                        { required: true },
                        "Job Name is required"
                      )}
                      invalid={errors.name && true}
                      {...field}
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button type="submit" className="me-1" color="primary" disabled={loading}>
            <Spinner color="light" size="sm" className="me-1" hidden={!loading} />
              {loading?"Submiting":"Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default JobModel;
