import React from 'react'
import ReactPaginate from 'react-paginate'

function CustomPagination({ data, currentPage, setCurrentPage, rowsPerPage }) {
    const count = Number(Math.ceil(data?.data?.totalDocs / rowsPerPage))

    return (
        <ReactPaginate
            previousLabel={''}
            nextLabel={''}
            pageCount={count || 1}
            activeClassName='active'
            forcePage={currentPage !== 0 ? currentPage - 1 : 0}
            onPageChange={page => setCurrentPage(page.selected + 1)}
            pageClassName={'page-item'}
            nextLinkClassName={'page-link'}
            nextClassName={'page-item next'}
            previousClassName={'page-item prev'}
            previousLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
        />
    )
}

export default CustomPagination