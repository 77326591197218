import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import customDispatch from "@commons/utils/HandledDispatch";
import Wizard from "@components/wizard";
import cities from "@src/data/cities";
import regions from "@src/data/regions";
import axios from "axios";
import { CardImage, LifePreserver, ListColumns } from "react-bootstrap-icons";
import { FileText, MapPin, UserPlus } from "react-feather";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorHandler } from "../../../common/utils/Error";
import { createBuilding } from "../../../redux/building";
import PropertyAmenity from "./steps/amenities";
import OwnerForm from "./steps/ownerform";
import PropertyAddress from "./steps/propertyAddress";
import PropertyDetail from "./steps/propertyDetail";
import PropertyInformation from "./steps/propertyInformation";
import PropertyMedia from "./steps/propertyMedia";

export default function AddListing() {
  const [categories, setCategories] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");
  const navigate = useNavigate();

  const getCategories = async () => {
    try {
      var { status, data } = await axios.get("/categories/list");
      if (status == 200) {
        setCategories(data.data);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };
  const getAllOwners = async () => {
    try {
      var { status, data } = await axios.get("/owners/list");
      if (status == 200) {
        setOwners(data.data);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const getAllAmenities = async () => {
    try {
      const { data, status } = await axios.get("/amenities/list");

      if (status == 200) {
        setAmenities(data.data);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);

  const defaultValues = {
    name: "",
    slug: "",
    description: "",
    floors: 0,
    price: 0,
    category: "",
    phone: "",
    email: "",
    // location: {
    //   country: "",
    //   city: "",
    //   state: "",
    //   area: "",
    //   address: "",
    //   lat: "",
    //   long: "",
    // },
    location: {
      coordinates: {
        latitude: "",
        longitude: "",
      },
      address: "",
      country: "",
      city: "",
      state: "",
    },
    owner_id: "",
    owner: {
      name: "",
      phone: "",
      email: "",
      emergency_contact: "",
      address: "",
    },
    videoUrl: "",
    landArea: "",
    garages: 0,
    garageSize: "0",
    yearBuilt: new Date().getFullYear(),
    propertyStatus: "",
    deposit: 0,
    reservationFee: 0,
    depositType: "value",
    advanceType: "value",
    advance: 0,
    amenities: [],
  };

  // ** Hooks
  const {
    register,
    reset,
    control,
    getValues,

    setError,
    setValue,
    clearErrors,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    getCategories();
    getAllAmenities();
    getAllOwners();
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const { phone, email } = data;

      delete data.phone;
      delete data.email;

      var formdata = new FormData();

      for (const key in data) {
        formdata.append(key, data[key]);
      }

      if (!thumbnail.length) {
        toast.error("thumbnail image is required");
        return;
      }

      if (!files.length) {
        toast.error("please add at least one image");
        return;
      }

      formdata.append(`thumbnail`, thumbnail[0], thumbnail[0].name);

      files.forEach((file, i) => {
        formdata.append(`file`, file, file.name);
      });

      formdata.set(
        "location",
        JSON.stringify({
          coordinates: {
            latitude: data.location.lat,
            longitude: data.location.long,
          },
          address: data.location?.address ?? "",
          country: data.location?.country.value,
          city: data.location?.city?.value,
          state: data.location?.state?.value,
        })
      );

      formdata.set("contacts", JSON.stringify({ phone, email }));
      formdata.set("owner", JSON.stringify(data.owner));
      formdata.set("category", data.category?.value);
      formdata.set("owner_id", data.owner_id == "" ? "" : data.owner_id?.value);
      formdata.set("propertyStatus", data.propertyStatus?.value ?? "For Rent");

      if (null) {
        const data = await customDispatch(
          dispatch,
          updateBuilding({ id: selectedBuilding._id, formdata })
        );
        if (data) {
          toast.success("Property Information updated Succesfully");
          reset();
        }
      } else {
        const data = await customDispatch(
          dispatch,
          createBuilding({
            formdata,
            headers: { "Content-Type": "multipart/form-data" },
          })
        );
        if (data) {
          toast.success("New Property Created Succesfully");
          reset();
          navigate(`/properties${appView ? `?view=${appView}` : ""}`);
        }
      }
    } catch (error) {
      console.error(error);
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const ref = useRef(null);

  // ** State
  const [stepper, setStepper] = useState(null);

  const steps = [
    {
      id: "owner-details",
      title: "Owner",
      subtitle: "Owner Details.",
      icon: <UserPlus size={18} />,
      content: (
        <OwnerForm
          stepper={stepper}
          control={control}
          errors={errors}
          setValue={setValue}
          register={register}
          owners={owners}
          appView={appView}
        />
      ),
    },
    {
      id: "property-details",
      title: "Property Details",
      subtitle: "Property Details.",
      icon: <FileText size={18} />,
      content: (
        <PropertyInformation
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          categories={categories}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          appView={appView}
        />
      ),
    },
    {
      id: "pricing-info",
      title: "Pricing",
      subtitle: "Property pricing",
      icon: <ListColumns size={18} />,
      content: (
        <PropertyDetail
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          getValues={getValues}
          categories={categories}
          appView={appView}
        />
      ),
    },
    {
      id: "property-amenities",
      title: "Amenities",
      subtitle: "Add Property Amenities",
      icon: <LifePreserver size={18} />,
      content: (
        <PropertyAmenity
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          amenities={amenities}
          getValues={getValues}
          setValue={setValue}
          appView={appView}
        />
      ),
    },
    {
      id: "property-address",
      title: "Address",
      subtitle: "Add Address",
      icon: <MapPin size={18} />,
      content: (
        <PropertyAddress
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          getValues={getValues}
          setValue={setValue}
          regions={regions}
          cities={cities}
          appView={appView}
        />
      ),
    },
    {
      id: "property-media",
      title: "Property Media",
      subtitle: "Add Property Media",
      icon: <CardImage size={18} />,
      content: (
        <PropertyMedia
          stepper={stepper}
          control={control}
          errors={errors}
          register={register}
          files={files}
          setFiles={setFiles}
          loading={loading}
          setThumbnail={setThumbnail}
          thumbnail={thumbnail}
        />
      ),
    },
  ];

  return (
    <>
      <div>
        <h4 className="fw-bolder">Add New Property</h4>
        <p>We are glad to see you again!</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modern-horizontal-wizard">
          <Wizard
            type="modern-horizontal"
            ref={ref}
            steps={steps}
            options={{
              linear: false,
            }}
            instance={(el) => setStepper(el)}
            className="bg-white rounded-sm"
            contentWrapperClassName="shadow-none rounded-0"
            headerClassName="shadow-none rounded-0"
          />
        </div>
      </form>
    </>
  );
}
