// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import authentication from "./authentication";
import users from "./users";
import buildings from "./building";
import CurrentBuilding from "./home";
import bookings from "./booking";
import customers from "./customer";

import sectionals, { sectionalApi } from "./sectionals";
import expenses from "./expenses";
import accounts from "./chartOfAccount";
import payments from "./payments";
import categories from "./category";
import amenities from "./amenities";
import invoices from "./invoice";
import receipts from "./receipt";
import agreements from "./agreements";
import owners from "./owner";
import staff from "./staff";
import leaves from "./leaves";
import jobs from "./jobs";
import departments from "./departments";
import roles from "./roles";
import utilityType from "./utilityTypes";
import utilities from "./utilities";
import accountBook from "./accountBook";
import dashboard from "./dashboard";
// rtk query api import
import { shiftApi } from "./shifts";
import { vendorApi } from "./vendors";
import { sectionalCategoryApi } from "./sectionalCategory";

const rootReducer = {
  navbar,
  layout,
  authentication,
  users,
  buildings,
  sectionals,
  expenses,
  accounts,
  payments,
  categories,
  amenities,
  CurrentBuilding,
  bookings,
  customers,
  invoices,
  receipts,
  agreements,
  owners,
  staff,
  leaves,
  jobs,
  departments,
  roles,
  utilityType,
  utilities,
  accountBook,
  dashboard,
  [shiftApi.reducerPath]: shiftApi.reducer,
  [sectionalApi.reducerPath]: sectionalApi.reducer,
  [vendorApi.reducerPath]: vendorApi.reducer,
  [sectionalCategoryApi.reducerPath]: sectionalCategoryApi.reducer,
};

export default rootReducer;
