// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllbookings = createAsyncThunk(
  "bookings/getAllData",
  async ({ status }, { getState }) => {
    let bodyContent = {
      params: {
        query: {
          status,
          building: getState().CurrentBuilding?.data?._id ?? undefined,
        },
        options: {
          sort: { createdAt: "desc" },
          populate: [
            {
              path: "createdBy",
              dir: "users",
              select: "_id username",
            },
            {
              path: "unit",
              dir: "sectionals",
              select: "name price reservationFee",
            },
            {
              path: "customer",
              dir: "customers",
              select: "name",
            },
          ],
        },
      },
    };
    const response = await axios.get("/bookings", bodyContent);
    const docs = response.data?.data?.docs || [];
    return {
      docs,
      totalRows: response.data?.data?.totalDocs,
      params: bodyContent.params,
    };
  }
);

export const getData = createAsyncThunk(
  "bookings/getData",
  async (params, { dispatch, getState }) => {
    console.log(params);
    var strparams = getState().bookings.params;
    var newparams = {
      query: {
        ...strparams.options,
        building: getState().CurrentBuilding?.data?._id ?? "",
      },
      options: {
        ...strparams,
        ...params,
        populate: [
          {
            path: "createdBy",
            dir: "users",
            select: "username",
          },
          {
            path: "unit",
            dir: "sectionals",
            select: "name price reservationFee",
          },
          {
            path: "customer",
            dir: "customers",
            select: "name",
          },
        ],
      },
    };
    const response = await axios.get("/bookings", {
      params: newparams,
    });
    return {
      params: newparams,
      data: response.data?.data?.docs,
    };
  }
);

// export const createBooking = createAsyncThunk(
//   "bookings/create",
//   async (formdata, { dispatch, getState }) => {
//     var response = await axios.post("/bookings", formdata);
//     // await dispatch(getData(getState().users.params));
//     dispatch(addBooking(response.data?.data));

//     return { status: response.status, message: response.message };
//   }
// );

export const createBooking = createAsyncThunk(
  "bookings/create",
  async (formdata, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.post("/bookings", formdata);
      dispatch(addBooking(response.data?.data));
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateBooking = createAsyncThunk(
  "bookings/update",
  async ({ data, id }, { dispatch, getState }) => {
    console.log("EDit booking", data);
    var response = await axios.patch(`/bookings/${id}`, data);
    // await dispatch(getData(getState().users.params));
    dispatch(editBooking(response.data?.data));
    return { status: response.status, message: response.message };
  }
);
export const cancelBooking = createAsyncThunk(
  "bookings/cancel",
  async (id, data, { dispatch, getState }) => {
    var response = await axios.patch(`/bookings/cancel/${id}`, data);
    return { status: response.status, message: response.message };
  }
);

export const deleteBooking = createAsyncThunk(
  "bookings/delete",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/bookings/${id}`);
    await dispatch(getAllbookings());
    return response.status;
  }
);

export const bookingslice = createSlice({
  name: "bookings",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedBooking: null,
  },
  reducers: {
    addBooking: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectBooking: (state, { payload }) => {
      state.selectedBooking = payload;
    },
    editBooking: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedBooking = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllbookings.pending, (state, action) => {
        state.loading = !state.data.length ? true : false;
        state.error = null;
      })
      .addCase(getAllbookings.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.params = action.payload.params;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      });
  },
});

export const { addBooking, selectBooking, editBooking } = bookingslice.actions;
export default bookingslice.reducer;
