// ** React Imports
import { useEffect } from 'react'

// ** Styles
import '@styles/base/pages/app-invoice-print.scss'
import PreviewCard from './PreviewCard'

const printReceipt = () => {
  // ** Print on mount
  useEffect(() => {
    setTimeout(() => window.print(), 50)
  }, [])

  const receipt = JSON.parse(localStorage.getItem("printReceipt"))

  return <PreviewCard data={receipt} />;
}

export default printReceipt
