import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { ErrorHandler } from "../../../common/utils/Error";
import axios from "axios";
import DataTable from "react-data-table-component";
import { formatCurrency } from "../../../utility/Utils";
import moment from "moment";
import { Filter, Printer, Search } from "react-feather";
import { FileExcel, PlusCircle } from "react-bootstrap-icons";
import JournalEntry from "./journalEnteryForm";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";

export default function JournalEntryList() {
  const [data, setData] = useState([]);
  const [journalForm, setJournalForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const getJournal = async () => {
    try {
      setLoading(true);
      const { data, status } = await axios.get("/accountings/journal");

      if (status == 200) {
        setData(data.data.results);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Date",

      selector: (row) => moment(row.datetime).format("DD/MM/YYYY"),
    },
    {
      name: "Account Name",

      selector: (row) => row.meta?.accountName ?? "",
    },

    {
      name: "Description",
      selector: (row) => row.memo ?? "",
      cell: (row) => <div>{row?.memo || ""}</div>,
    },
    {
      name: "DR",
      selector: (row) => (row.debit == 0 ? "" : formatCurrency(row.debit)),
      cell: (row) => (
        <span
          className={classNames({
            "text-decoration-line-through text-danger": row.voided == true,
          })}
          title={row.void_reason ?? ""}
        >
          {row.debit == 0 ? "---" : formatCurrency(row.debit)}
        </span>
      ),
    },
    {
      name: "CR",

      selector: (row) => (row.credit == 0 ? "" : formatCurrency(row.credit)),
      cell: (row) => (
        <span
          className={classNames({
            "text-decoration-line-through text-danger": row.voided == true,
          })}
        >
          {row.credit == 0 ? "---" : formatCurrency(row.credit)}
        </span>
      ),
    },
  ];

  const toggleJournalForm = () => {
    setJournalForm(false);
  };

  const defaultValues = {
    dateRange: [],
    account: "",
  };
  // ** Hooks
  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    setValue,
    trigger,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    getJournal();
  }, []);

  return (
    <>
      <JournalEntry
        show={journalForm}
        ShowModal={toggleJournalForm}
        loader={getJournal}
      />
      <Card>
        <CardBody className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">General Ledger</h4>
          <ButtonGroup>
            <Button
              size="md"
              color="primary"
              onClick={() => setJournalForm(true)}
            >
              <PlusCircle size={15} /> Make Entry
            </Button>
            <Button size="md" outline>
              <Filter size={15} /> Filter
            </Button>
          </ButtonGroup>
        </CardBody>
        <CardBody>
          <Row>
            <Col xs={12} md={6}>
              <Label className="form-label" for={`filter_account`}>
                Account
              </Label>
              <Controller
                name={`account`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    id={`filter_account`}
                    className="react-select"
                    classNamePrefix="select"
                    invalid={errors.account && true}
                    {...field}
                    placeholder={"Select Account"}
                    options={[]}
                  />
                )}
              />
              {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
            </Col>
            <Col xs={12} md={6}>
              <Label className="form-label" for={`filter_date`}>
                Date Range
              </Label>
              <Controller
                name={`dateRange`}
                control={control}
                render={({ field }) => (
                  <Input
                    id={`filter_date`}
                    invalid={errors.dateRange && true}
                    {...field}
                    type="text"
                  />
                )}
              />
              {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
            </Col>

            <Col xs={12} className="text-center mt-2">
              <Button className="" color="primary">
                {" "}
                <Search size={14} className="" /> Apply Filter{" "}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="mb-0">General Ledger</h4>
              <p className="mb-0">All Accounts</p>
              <p className="mb-2">All Date</p>
            </div>
            <div className="">
              <Printer
                size={30}
                className="cursor-pointer me-1"
                title="Print"

                // onClick={() => handlePrint()}
              />
              <FileExcel
                size={30}
                className="cursor-pointer"
                title="Export to Excell"
                onClick={() => {}}
              />
            </div>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <DataTable
              noHeader
              responsive
              columns={columns}
              className="react-dataTable"
              data={data}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
}
