// ** React Imports
import { useEffect, useState } from "react";

// ** Third Party Components
import axios from "axios";
import Chart from "react-apexcharts";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Button,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import moment from "moment/moment";

var monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const RevenueReport = ({ revenues = [], expenses = [], colors = [] }) => {
  let months = [
    ...revenues?.map((rv) => rv?._id),
    ...expenses?.map((ex) => ex?._id),
  ];

  months?.sort((a, b) => a - b);
  months = [...new Set(months)];

  const revenueOptions = {
      chart: {
        stacked: true,
        type: "bar",
        toolbar: { show: false },
      },
      grid: {
        padding: {
          top: -20,
          bottom: -10,
        },
        yaxis: {
          lines: { show: false },
        },
      },
      xaxis: {
        categories: months?.map((m) => monthShortNames[m - 1]) || [],
        labels: {
          style: {
            colors: "#b9b9c3",
            fontSize: "0.86rem",
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "17%",
          borderRadius: [5],
        },
        distributed: true,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#b9b9c3",
            fontSize: "0.86rem",
          },
        },
      },
    },
    revenueSeries = [
      {
        name: "Revenue",
        data:
          months?.map((month) => {
            const revenue = revenues?.find((rv) => rv?._id == month);
            return revenue?.total || 0;
          }) || [],
      },
      {
        name: "Expense",

        data:
          months?.map((month) => {
            const expense = expenses?.find((ex) => ex?._id === month);
            return expense?.total || 0;
          }) || [],
      },
    ];

  return (
    <Card className="card-revenue-budget">
      <Row className="mx-0">
        <Col className="revenue-report-wrapper" md="12" xs="12">
          <div className="d-sm-flex justify-content-between align-items-center mb-3">
            <CardTitle className="mb-50 mb-sm-0">
              Revenue Report By Month
            </CardTitle>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center me-2">
                <span className="bullet bullet-success me-50 cursor-pointer"></span>
                <span>Revenue</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="bullet bullet-warning me-50 cursor-pointer"></span>
                <span>Expense</span>
              </div>
            </div>
          </div>
          <Chart
            id="revenue-report-chart"
            type="bar"
            height="230"
            options={revenueOptions}
            series={revenueSeries}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default RevenueReport;
