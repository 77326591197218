import React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Input,
  ModalFooter,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { ErrorHandler } from "../../../common/utils/Error";
import {
  useCreateVendorMutation,
  useUpdateVendorMutation,
} from "../../../redux/vendors";
import toast from "react-hot-toast";
import { useEffect } from "react";

const defaultValues = {
  name: "",
  contact: "",
  address: "",
  status: "active",
};

const VendorModel = ({ show, ShowModal, selectedVendor }) => {
  const [loading, setIsLoading] = useState(false);
  const [createVendor] = useCreateVendorMutation();
  const [updateVendor] = useUpdateVendorMutation();

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (selectedVendor) {
        const { data: res } = await updateVendor({
          id: selectedVendor._id,
          formData: data,
        });
        if (res) {
          reset();
          ShowModal(false);
          toast.success("Vendor added successfully!");
        }
      } else {
        const { data: res } = await createVendor(data);
        if (res) {
          reset();
          ShowModal(false);
          toast.success("Vendor added successfully!");
        }
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    reset(defaultValues);
  };

  useEffect(() => {
    reset({
      name: selectedVendor?.name,
      contact: selectedVendor?.contact,
      address: selectedVendor?.address,
      status: selectedVendor?.status,
    });
  }, [selectedVendor]);

  return (
    <Modal
      isOpen={show}
      onClosed={onDiscard}
      toggle={ShowModal}
      className=""
      modalClassName="modal-dark"
    >
      <ModalHeader toggle={ShowModal} className="bg-white">
        Add New Vendor
      </ModalHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className="pb-5 px-sm-4 mx-50">
          <Row className="gy-1 gx-2 flex-column">
            <Col xs={12}>
              <Label className="form-label" for="name">
                Vendor Name
              </Label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    id="name"
                    placeholder="Vendor name"
                    {...register(
                      "name",
                      { required: true },
                      "Vendor Name is required"
                    )}
                    invalid={errors.name && true}
                    {...field}
                  />
                )}
              />
              {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )}
            </Col>
            <Col xs={12}>
              <Label className="form-label" for="contact">
                Contact
              </Label>
              <Controller
                name="contact"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="contact"
                    placeholder="+252 000 000"
                    invalid={errors.contact && true}
                    {...field}
                  />
                )}
              />
              {errors.contact && (
                <FormFeedback>Please enter a valid Contact</FormFeedback>
              )}
            </Col>

            <Col xs={12}>
              <Label className="form-label" for="address">
                Address
              </Label>
              <Controller
                name="address"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="address"
                    placeholder="Taleex, Mogadisho"
                    invalid={errors.address && true}
                    {...field}
                  />
                )}
              />
              {errors.address && (
                <FormFeedback>Please enter a valid Address</FormFeedback>
              )}
            </Col>

            <Col xs={12}>
              <Label className="form-label" for="status">
                Status
              </Label>
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="status"
                    type="select"
                    placeholder="+2526"
                    invalid={errors.status && true}
                    {...field}
                  >
                    <option value="inactive">InActive</option>
                    <option value="active">Active</option>
                  </Input>
                )}
              />
              {errors.status && (
                <FormFeedback>Please enter a valid Status</FormFeedback>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <Button type="submit" className="" color="success" disabled={loading}>
            <Spinner
              color="light"
              size="sm"
              className="me-1"
              hidden={!loading}
            />
            {loading ? "Loading" : "Submit"}
          </Button>
          <Button
            type="reset"
            className=""
            color="dark"
            outline
            onClick={onDiscard}
          >
            Discard
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default VendorModel;
