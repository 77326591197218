// // ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// // ** Axios Imports
import axios from "axios";

export const getAllBuildings = createAsyncThunk(
  "buildings/getAllData",
  async ({ appView, status }, { rejectWithValue }) => {
    try {
      let bodyContent = {
        params: {
          query: {
            status: status,
            propertyStatus: appView
              ? appView == "salesAndBroker"
                ? ["For Sale", "Broker Rent"]
                : ["For Rent"]
              : ["For Rent"],
          },
          options: {
            // limit:15,
            populate: [
              {
                path: "category",
                dir: "categories",
                select: "name",
              },
            ],
          },
        },
      };

      const response = await axios.get("/buildings", bodyContent);
      const docs = response.data?.data?.docs || [];
      return { docs, totalRows: response.data?.data?.totalDocs };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getData = createAsyncThunk("buildings/getData", async (params) => {
  const response = await axios.get("/buildings", {
    params: { options: params },
  });
  return {
    params,
    data: response.data?.data?.docs,
  };
});

export const createBuilding = createAsyncThunk(
  "buildings/create",
  async ({ formdata, headers }, { dispatch, getState, rejectWithValue }) => {
    var response = await axios.post("/buildings", formdata, {
      headers: headers,
    });
    // await dispatch(getData(getState().users.params));
    dispatch(addBuilding(response.data?.data));

    return { status: response.status, message: response.message };
  }
);

export const updateFeaturedProperty = createAsyncThunk(
  "buildings/updateFeatured",
  async (data, { dispatch, getState, rejectWithValue }) => {
    var response = await axios.post(`/property/status/${data.id}`, data);
    // await dispatch(getData(getState().users.params));
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    return { status: response.status, data: response.data.data };
  }
);

export const updateBuilding = createAsyncThunk(
  "buildings/update",
  async ({ formdata, id }, { dispatch, getState }) => {
    var response = await axios.patch(`/buildings/${id}`, formdata);
    // await dispatch(getData(getState().users.params));
    dispatch(editBuilding(response.data?.data));
    return { status: response.status, message: response.message };
  }
);

// export const deleteBuilding = createAsyncThunk(
//   "buildings/delete",
//   async (id, { dispatch, getState }) => {
//     const response = await axios.delete(`/buildings/${id}`);
//     await dispatch(getAllBuildings());
//     return response.status;
//   }
// );

export const deleteBuilding = createAsyncThunk(
  "buildings/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/buildings/${id}`);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const buildingSlice = createSlice({
  name: "buildings",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedBuilding: null,
  },
  reducers: {
    addBuilding: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },

    selectBuilding: (state, { payload }) => {
      state.selectedBuilding = payload;
    },
    editBuilding: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedBuilding = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBuildings.pending, (state, action) => {
        state.loading = !state.data.length ? true : false;
        state.error = null;
      })
      .addCase(getAllBuildings.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
      })
      .addCase(getAllBuildings.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })

      .addCase(updateFeaturedProperty.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.data = state.data.map((item) => {
          if (item._id == payload.data._id) {
            return { ...item, ...payload.data };
          }
          return item;
        });
        state.allData = state.allData.map((item) => {
          if (item._id == payload.data._id) {
            return { ...item, ...payload.data };
          }
          return item;
        });
      });
  },
});

export const { addBuilding, selectBuilding, editBuilding } =
  buildingSlice.actions;
export default buildingSlice.reducer;

//! The following is RTK query based on the api, it still not applied fully yet:

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../utility/helper";

export const buildingApi = createApi({
  reducerPath: "buildings",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["Buildings"],
  endpoints: (builder) => ({
    getAllBuildings: builder.query({
      query: ({ appView }) => {
        const propertyStatus =
          appView === "salesAndBroker"
            ? ["For Sale", "Broker Rent"]
            : ["For Rent"];
        const queryParams = {
          "query[propertyStatus][0]": propertyStatus[0],
          "options[populate][0][path]": "category",
          "options[populate][0][dir]": "categories",
          "options[populate][0][select]": "name",
        };

        return {
          url: "/buildings",
          method: "GET",
          params: queryParams,
        };
      },
      providesTags: (result, error) => {
        if (error) {
          ApiErrorHandler(error);
          return [];
        }

        const { data: buildings } = result;
        if (Array.isArray(buildings) || buildings.docs) {
          const docs = Array.isArray(buildings) ? buildings : buildings.docs;
          return [
            ...docs.map(({ _id }) => ({ type: "Buildings", id: _id })),
            { type: "Buildings", id: 1 },
          ];
        }
        return [{ type: "Buildings", id: 1 }];
      },
      // transformResponse: (res) => {
      //   if
      // },
    }),

    getData: builder.query({
      query: (params) => `/buildings?options=${params}`,
      invalidatesTags: ["Buildings"],
    }),

    createBuilding: builder.mutation({
      query: (building) => ({
        url: "/buildings",
        method: "POST",
        body: building,
      }),
      invalidatesTags: ["Buildings"],
    }),

    updateFeaturedProperty: builder.mutation({
      query: (formData) => ({
        url: `/property/status/${formData.id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Buildings"],
    }),

    updateBuilding: builder.mutation({
      query: ({ formData, id }) => ({
        url: `/buildings/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Buildings"],
    }),

    deleteBuilding: builder.mutation({
      query: (id) => ({
        url: `/buildings/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Buildings"],
    }),
  }),
});

export const {
  useGetAllBuildingsQuery,
  useGetDataQuery,
  useCreateBuildingMutation,
  useUpdateFeaturedPropertyMutation,
  useUpdateBuildingMutation,
  useDeleteBuildingMutation,
} = buildingApi;
