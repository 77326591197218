import moment from "moment/moment";
import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { ChevronDown } from "react-feather";
import ReactPaginate from "react-paginate";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
} from "reactstrap";
import { formatCurrency } from "../../../utility/Utils";
import themeConfig from "../../../configs/themeConfig.js";
import { Link } from "react-router-dom";
import AvatarGroup from "@components/avatar-group";

const Invoice = ({ store }) => {
  // const properties = store.data.properties || [];
  // console.log(properties);

  const [sort, setSort] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [properties, setProperties] = useState(
    store.data?.properties?.slice(0, rowsPerPage)
  );

  const dataToRender = () => {
    return properties;
  };

  const columns = [
    {
      name: "Property",
      sortable: true,
      width: "20%",
      // sortField: "name",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="d-flex align-items-start">
          {/* {row.photos.length ? ( */}
          {/* {row.thumbnail ? (
            <img
              src={themeConfig.serverUrl + "uploads/" + row.thumbnail}
              className="img-fluid featured-img"
            />
          ) : null} */}

          {/* ) : (
            <img
              src={require("@src/assets/images/property_img.jpg").default}
              className="img-fluid featured-img"
            />
          )} */}
          <Link
            to={`/properties/${row._id}?view=company`}
            className="text-body"
          >
            <span className="fw-bolder">{row.name}</span>
          </Link>

          {/* <div className="d-flex flex-column">
            

            <div className={classNames({ "d-none": !row.category?._id })}>
              <BuildingUp size={13} color="grey" className="me-1" />
              {row.category?.name}
            </div>
            <div className={classNames({ "d-none": !row.location?.address })}>
              <MapPin size={13} color="grey" className="me-1" />
              {row.location?.address}
            </div>
            <div className={classNames({ "d-none": !row.price })}>
              <CurrencyDollar size={13} color="grey" className="me-1" />
              {row.price}
            </div>
          </div> */}
        </div>
      ),
    },
    {
      name: "Units",
      sortable: true,

      sortField: "units",
      selector: (row) => row.units,
      cell: (row) => <span>{row.units?.length ?? 0}</span>,
    },
    {
      name: "Deposit",
      sortable: true,

      sortField: "deposit",
      selector: (row) => row.deposit,
      cell: (row) => <span>{row.deposit}</span>,
    },
    {
      name: "Advance",
      sortable: true,

      sortField: "advance",
      selector: (row) => row.advance,
      cell: (row) => <span>{row.advance}</span>,
    },
    {
      name: "Floors",
      sortable: true,

      sortField: "floors",
      selector: (row) => row.floors,
      cell: (row) => <span>{row.floors}</span>,
    },

    {
      name: "Photos",

      sortable: true,
      sortField: "photos",
      wrap: true,
      selector: (row) => row.photos,
      cell: (row) => {
        const images = (row.photos || []).slice(0, 3).map((img) => {
          return { img: `${themeConfig.serverUrl}uploads/${img}` };
        });

        // ({

        // }));
        return <AvatarGroup data={images} />;
      },
    },
    // {
    //   name: "Published",
    //   width: "11%",
    //   sortable: true,
    //   sortField: "published",
    //   selector: (row) => row.published,
    //   cell: (row) => (
    //     <>
    //       <div className="form-switch form-check-success">
    //         <Input
    //           type="switch"
    //           checked={row.published}
    //           disabled={publishedLoading}
    //           id={`published_${row._id}`}
    //           name={`published_${row._id}`}
    //           onChange={(event) => {
    //             handlePublished(row._id, event.target.checked);
    //           }}
    //         />
    //         <CustomLabel htmlFor={`published_${row._id}`} />
    //       </div>
    //       {/* <Badge color={row.published ? 'light-success': 'light-warning'}>{row.published ?  'Yes' : 'No'} </Badge> */}
    //     </>
    //   ),
    // },

    // {
    //   name: "Featured",
    //   width: "11%",
    //   sortable: true,
    //   sortField: "published",
    //   selector: (row) => row.featured,
    //   cell: (row) => (
    //     <>
    //       <div className="form-switch form-check-success">
    //         <Input
    //           type="switch"
    //           checked={row.featured}
    //           disabled={featuredLoading}
    //           id={`featured_${row._id}`}
    //           name={`featured_${row._id}`}
    //           onChange={(event) => {
    //             handleFeatured(row._id, event.target.checked);
    //           }}
    //         />
    //         <CustomLabel htmlFor={`featured_${row._id}`} />
    //       </div>
    //       {/* <Badge color={row.featured ? 'light-success': 'light-warning'}>{row.featured ?  'Yes' : 'No'} </Badge> */}
    //     </>
    //   ),
    // },

    {
      name: "Status",
      width: "11%",
      sortable: true,
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <>
          <Badge
            color={
              row.status == "Available"
                ? "light-success"
                : row.status == "Booked"
                ? "light-warning"
                : "light-danger"
            }
          >
            {row.status}
          </Badge>
        </>
      ),
    },
    // {
    //   name: "Actions",
    //   width: "11%",
    //   cell: (row) => (
    //     <div className="column-action">
    //       <UncontrolledDropdown>
    //         <DropdownToggle tag="div" className="btn btn-sm">
    //           <MoreVertical size={14} className="cursor-pointer" />
    //         </DropdownToggle>
    //         <DropdownMenu>
    //           <DropdownItem
    //             tag={Link}
    //             className="w-100"
    //             onClick={(e) => {
    //               e.preventDefault();
    //               loginIntoBuilding(row);
    //             }}
    //           >
    //             <LogIn size={14} className="me-50" />
    //             <span className="align-middle">Select</span>
    //           </DropdownItem>
    //           <Can2 I="update" a="Building" y={appView}>
    //             <DropdownItem
    //               tag="a"
    //               href="/"
    //               className="w-100"
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 dispatch(selectBuilding(row));
    //                 setModalShow(true);
    //               }}
    //             >
    //               <Archive size={14} className="me-50" />
    //               <span className="align-middle">Edit</span>
    //             </DropdownItem>
    //           </Can2>
    //           <Can2 I="delete" a="Building" y={appView}>
    //             <DropdownItem
    //               tag="a"
    //               href="/"
    //               className="w-100"
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 handleConfirmDelete(row._id, row.name);
    //               }}
    //             >
    //               <Trash2 size={14} className="me-50" />
    //               <span className="align-middle">Delete</span>
    //             </DropdownItem>
    //           </Can2>
    //         </DropdownMenu>
    //       </UncontrolledDropdown>
    //     </div>
    //   ),
    // },
  ];

  const handlePagination = (page) => {
    const startIndex = page.selected * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const currentPageData = store.data.properties.slice(startIndex, endIndex);

    setProperties(currentPageData);
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.data.properties.length / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">
          <CardTitle>Ownership Detail</CardTitle>
          {/* <Button color="primary">New Invoice</Button> */}
        </CardHeader>
        <div className="react-dataTable user-view-account-projects">
          {/* <DataTable
            noHeader
            responsive
            columns={columns}
            paginationComponent={CustomPagination}
            data={store.data.invoices}
            className="react-dataTable"
            sortIcon={<ChevronDown size={10} />}
          /> */}

          <DataTable
            subHeader
            sortServer
            pagination
            responsive
            paginationServer
            striped
            columns={columns}
            sortIcon={<ChevronDown />}
            className="react-dataTable react-dataTable-selectable-rows"
            paginationComponent={CustomPagination}
            data={dataToRender()}
          />
        </div>
        <CardBody></CardBody>
      </Card>
    </Fragment>
  );
};

export default Invoice;
