import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Tooltip,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
import "@styles/react/libs/react-select/_react-select.scss";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { ErrorHandler } from "../../../common/utils/Error";
import HandleDispatch from "../../../common/utils/HandledDispatch";
import {
  createBooking,
  getAllbookings,
  selectBooking as selectBookingReducer,
  updateBooking,
} from "../../../redux/booking";

const AddNewAddress = ({
  show,
  ShowModal,
  data: dataArr,
  selectedRow,
  setSelectedRow,
}) => {
  const selectedBooking = useSelector(
    (state) => state.bookings.selectedBooking
  );
  const customersState = useSelector((state) => state.customers.allData);
  const CurrentBuilding = useSelector((state) => state.CurrentBuilding.data);

  const [apartments, setApartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vatTooltipOpen, setVatTooltipOpen] = useState(false); // Tooltip state

  const defaultValues = {
    unit: "",
    customer: "",
    discount: 0,
    amount: 0,
    includeVAT: true, // Default value for VAT inclusion
    vatAmount: 0, // Default value for VAT amount
    totalWithVAT: 0, // Default value for total with VAT
  };

  // ** Hooks
  const {
    register,
    reset,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (!CurrentBuilding) {
        toast.error("You must select/login Building to Create booking");
        return;
      }

      // Calculate VAT and total with VAT
      const netAmount =
        parseFloat(data.amount) - parseFloat(data.discount || 0);
      const vatAmount = data.includeVAT ? netAmount * 0.05 : 0;
      data.vatAmount = vatAmount.toFixed(2);
      data.totalWithVAT = (netAmount + vatAmount).toFixed(2);

      if (selectedRow) {
        data.building = CurrentBuilding?._id;
        let newData = await HandleDispatch(
          dispatch,
          updateBooking({ id: selectedRow?._id, data })
        );
        if (newData) {
          toast.success("Booking Information updated Successfully");
          reset();
          dataArr.getAvailableSectionals();
          ShowModal();
        }
      } else {
        data.building = CurrentBuilding?._id;
        const responseData = await HandleDispatch(
          dispatch,
          createBooking(data)
        );
        if (responseData) {
          toast.success("New Booking Created Successfully");
          reset();
          dataArr.getAvailableSectionals();
          ShowModal();
        }
      }

      dispatch(getAllbookings({}));
    } catch (error) {
      ErrorHandler(error);
    }
    setLoading(false);
  };

  const [customers, setCustomers] = useState([]);

  const getCustomers = async () => {
    try {
      setLoading(true);
      const params = {};
      if (CurrentBuilding) {
        params.building = CurrentBuilding?._id;
      }
      const { data } = await axios.get(`/customers/list`, { params });
      const customers = data?.data?.map((cus) => ({
        value: cus._id,
        label: cus.name,
      }));
      setCustomers(customers);
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    const units = dataArr.sectionals?.map((sec) => ({
      value: sec?._id,
      label: `${sec.name}  - $${sec?.reservationFee ?? 0}`,
    }));
    setApartments([{ value: "building", label: `All Building` }, ...units]);
  }, [dataArr?.sectionals, selectedRow, show]);

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    dispatch(selectBookingReducer(null));
    setSelectedRow(null);
    reset(defaultValues);
  };

  useEffect(() => {
    const customer = customersState.find(
      (customer) => customer._id === selectedRow?.customer?._id
    );

    let data = { ...defaultValues };

    if (selectedRow?.unit) {
      const unit = {
        value: selectedRow?.unit?._id,
        label: `${selectedRow?.unit?.name}  - $${
          selectedRow?.unit?.reservationFee ?? 0
        }`,
      };
      setApartments([...apartments, unit]);

      data = { ...data, unit: unit.value };
    }

    if (customer) {
      data = {
        ...data,
        customer: customer?._id,
      };
    }

    data = {
      ...data,
      amount: selectedRow?.amount ?? 0,
      discount: selectedRow?.discount ?? 0,
      includeVAT: selectedRow?.includeVAT ?? true,
    };

    reset({ ...defaultValues, ...data });
  }, [show, customersState]);

  // Watch for changes in 'amount', 'discount', and 'includeVAT' to update VAT calculations
  useEffect(() => {
    const amount = parseFloat(getValues("amount")) || 0;
    const discount = parseFloat(getValues("discount")) || 0;
    const includeVAT = getValues("includeVAT");

    const netAmount = amount - discount;
    const vatAmount = includeVAT ? netAmount * 0.05 : 0;
    setValue("vatAmount", vatAmount.toFixed(2));
    setValue("totalWithVAT", (netAmount + vatAmount).toFixed(2));
  }, [watch("amount"), watch("discount"), watch("includeVAT")]);

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={ShowModal}
        modalClassName="modal-lg"
        size="md"
        backdrop="static"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={ShowModal}
        ></ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5 px-sm-4 mx-50">
            <h3 className="address-title mb-1">
              {selectedBooking ? "Update Application" : "New Application"}
            </h3>

            <Row className="gy-1 gx-2">
              <Col xs={12} md={6}>
                <Label className="form-label" for="customer">
                  Select Customer
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="customer"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.customer && true}
                      {...register("customer", { required: true })}
                      {...field}
                      placeholder={"Select Customer"}
                      value={customers?.find((b) => b.value === field.value)}
                      options={customers ?? []}
                      onChange={(v) => field.onChange(v.value)}
                    />
                  )}
                />
                {errors.customer && (
                  <FormFeedback>Please select a customer</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={6}>
                <Label className="form-label" for="unit">
                  Select Unit
                </Label>
                <Controller
                  name="unit"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="unit"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.unit && true}
                      {...register("unit", { required: true })}
                      {...field}
                      value={apartments.find((b) => b.value === field.value)}
                      options={apartments ?? []}
                      onChange={(v) => {
                        field.onChange(v.value);
                        const amount =
                          dataArr.sectionals?.find((sec) => sec._id === v.value)
                            ?.reservationFee ?? 0;
                        setValue("amount", amount);
                      }}
                    />
                  )}
                />
                {errors.unit && (
                  <FormFeedback>Sectional Is Required</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={6}>
                <Label className="form-label" for="amount">
                  Reservation Fee
                </Label>

                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      readOnly={true}
                      id="amount"
                      type="number"
                      {...register("amount", { required: true })}
                      step={"any"}
                      placeholder="0"
                      invalid={errors.amount && true}
                      {...field}
                    />
                  )}
                />
                {errors.amount && (
                  <FormFeedback>Please enter a valid amount</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={6}>
                <Label className="form-label" for="discount">
                  Discount
                </Label>

                <Controller
                  name="discount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="discount"
                      type="number"
                      {...register("discount", { required: true })}
                      step={"any"}
                      placeholder="0"
                      invalid={errors.discount && true}
                      {...field}
                    />
                  )}
                />
              </Col>

              {/* Include VAT as part of form state */}
              <Col xs={12} className="mt-2">
                <div className="form-check form-switch">
                  <Controller
                    name="includeVAT"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="switch"
                        id="includeVAT"
                        name="includeVAT"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        className="form-check-input"
                      />
                    )}
                  />
                  <Label
                    className="form-check-label"
                    for="includeVAT"
                    id="vatTooltip"
                  >
                    Include VAT (5%)
                  </Label>
                  <Tooltip
                    isOpen={vatTooltipOpen}
                    target="vatTooltip"
                    toggle={() => setVatTooltipOpen(!vatTooltipOpen)}
                  >
                    This will add a 5% VAT to the net amount (Reservation Fee -
                    Discount).
                  </Tooltip>
                </div>
              </Col>

              {/* VAT Amount Field */}
              <Col xs={12} md={6}>
                <Label className="form-label" for="vatAmount">
                  VAT Amount (5%)
                </Label>
                <Controller
                  name="vatAmount"
                  control={control}
                  render={({ field }) => (
                    <Input id="vatAmount" type="number" readOnly {...field} />
                  )}
                />
              </Col>

              {/* Total with VAT Field */}
              <Col xs={12} md={6}>
                <Label className="form-label" for="totalWithVAT">
                  Total with VAT
                </Label>
                <Controller
                  name="totalWithVAT"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="totalWithVAT"
                      type="number"
                      readOnly
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col className="text-center" xs={12}>
                <Button
                  type="submit"
                  className="me-1 mt-2"
                  color={selectedBooking ? "success" : "primary"}
                  disabled={loading}
                >
                  <Spinner
                    color="light"
                    size="sm"
                    className="me-1"
                    hidden={!loading}
                  />
                  {selectedBooking ? "Save Changes" : "Submit"}
                </Button>
                <Button
                  type="reset"
                  className="mt-2"
                  color="secondary"
                  outline
                  onClick={onDiscard}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default AddNewAddress;
