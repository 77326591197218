// ** React Imports
import { Fragment, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Alert,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

import axios from "axios";

import { ErrorHandler } from "../../../common/utils/Error";
import { Check2Circle } from "react-bootstrap-icons";
import moment from "moment";

const GenerateInvoiceModel = ({ show, onClose, row, rows }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const defaultValues = {
    invoice_date: moment().format("YYYY-MM-DD"),
    type: rows.length ? "multiple" : "single",
    agreement: row?._id,
    agreements: rows,
  };

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      if (row || rows.length) {
        var inv = await axios.post("/accountings/invoices/generate", {
          ...data,
          agreement: row?._id,
          agreements: rows,
          type: rows.length ? "multiple" : "single",
        });
        if (inv) {
          toast.success(
            rows.length
              ? `${rows.length} Invoices generated`
              : `Invoice Generated to Agreement ${row.agreementName}`
          );
          reset();
          onClose();
        }
      } else {
        toast.error("Please Select Agreement");
        reset();
        onClose();
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ??
        error?.response?.data ??
        error.message ??
        "Something went wrong, please try again";

      // ErrorHandler(error);
      setErrorMessage(errorMessage);
    }
    setLoading(false);
  };

  const onDiscard = () => {
    clearErrors();
    reset();
    onClose();
    setErrorMessage(null);
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onClose}
        className=""
        centered
      >
        <ModalHeader toggle={onClose} className="bg-white">
          Invoice Information
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}

            {errorMessage && (
              <Alert color="danger" className="px-2 py-1">
                {errorMessage}
              </Alert>
            )}

            <Row className="gy-1 gx-2 flex-column mb-1">
              <Col xs={12}>
                <Label className="form-label" for="invoice_date">
                  Invoice Date
                </Label>
                <Controller
                  name="invoice_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="invoice_date"
                      type="date"
                      invalid={errors.invoice_date && true}
                      {...field}
                    />
                  )}
                />
                {errors.invoice_date && (
                  <FormFeedback>Please enter Invoice Date</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="success"
              disabled={(!row && !rows.length) || loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading ? "Submitting..." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default GenerateInvoiceModel;
