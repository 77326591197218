import moment from "moment";
import React from "react";
import { Col, Row, Table } from "reactstrap";
import SakeenaLogo from "../../../assets/images/logo/inv-lh-logo.png";
import TextHeader from "../../../assets/images/logo/inv-lh-tx.png";
import AddowLogo from "../../../assets/images/logo/inv-logo2.png";

const Invoice = ({ invoiceData = [], ref }) => {
  // Calculate the subtotal, discount, and total
  const subtotal = invoiceData?.reduce(
    (acc, invoice) => acc + invoice.total,
    0
  );
  const discountTotal = invoiceData?.reduce(
    (acc, invoice) => acc + invoice.discount,
    0
  );

  const invoiceNumbers = invoiceData
    ?.map((invoice) => "#" + invoice.invoiceNo)
    .join(", ");

  const vatTotal = invoiceData?.reduce((acc, invoice) => acc + invoice.tax, 0);
  const grandTotal = subtotal - discountTotal;

  return (
    <div ref={ref}>
      {/* Logo and Header Section */}
      <Row className="mb-4">
        <Col>
          <img
            src={SakeenaLogo}
            alt="Company Logo"
            style={{ maxWidth: "150px" }}
          />
        </Col>
        <Col className="text-center mt-1">
          <img
            src={TextHeader}
            alt="Company Logo"
            style={{ maxWidth: "300px" }}
          />
          <span
            className="badge mt-2 h5"
            style={{
              backgroundColor: "#f0f0f0",
              padding: "10px 50px",
              borderRadius: 20,
              fontWeight: "bold",
              color: "black",
              border: "1px solid #dcdcdc",
              fontSize: "20px",
            }}
          >
            INVOICES
          </span>
        </Col>
        <Col>
          <img
            src={AddowLogo}
            alt="Company Logo"
            style={{ maxWidth: "150px" }}
          />
        </Col>
      </Row>

      {/* Items Table */}
      <Row style={{ margin: "10px 5px" }}>
        <Col>
          <div
            className="  bg-primary text-white p-1 fw-bold "
            style={{
              marginRight: "15px",
            }}
          >
            <div className="d-flex justify-content-between fw-bold">
              <span>INVOICES: {invoiceNumbers}</span>
              <span>DATE ISSUED: {moment().format("DD/MMM/YYYY")}</span>
            </div>
            <div
              className="d-flex justify-content-between fw-bold"
              style={{ marginTop: "10px" }}
            >
              <span>Name: {invoiceData[0]?.customer?.name}</span>
            </div>
          </div>
          <table
            striped
            className="mt-2"
            style={{
              width: "100%",
              marginTop: "10px",
              borderCollapse: "collapse",
            }}
          >
            <thead striped>
              <tr
                style={{
                  backgroundColor: "#f0f0f0",
                  textAlign: "left",
                  borderBottom: "2px dashed #ccc",
                  height: "40px",
                  padding: "5px",
                }}
              >
                <th style={{ width: "40%" }}>Description</th>
                <th>QTY</th>
                <th>Price</th>
                <th>Discount</th>
                <th>VAT</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.map((invoice, index) => (
                <tr
                  key={index}
                  style={{
                    borderBottom: "1px solid #ddd",
                    height: "40px",
                    fontSize: "15px",
                    textAlign: "left",
                    gap: "5px",
                  }}
                >
                  <td>{invoice.items[0].description}</td>
                  <td>{invoice?.items?.length || 0}</td>
                  <td>${invoice.items[0].amount.toFixed(2)}</td>
                  <td>${invoice.discount.toFixed(2)}</td>
                  <td>${invoice.tax.toFixed(2)}</td>
                  <td>${invoice.total.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>

      {/* Summary Section */}
      <Row className="my-3" style={{ margin: "10px 5px" }}>
        <Col>
          <Table bordered style={{ textAlign: "center" }}>
            <tbody>
              <tr style={{ backgroundColor: "#f0f0f0" }}>
                <td>
                  <span>SUBTOTAL :</span>
                  <span className="mx-1">${subtotal.toFixed(2)}</span>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f0f0f0" }}>
                <td>
                  <span>DISCOUNT :</span>
                  <span className="mx-1">${discountTotal.toFixed(2)}</span>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f0f0f0" }}>
                <td>
                  <span>VAT :</span>
                  <span className="mx-1">${vatTotal.toFixed(2)}</span>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f0f0f0" }}>
                <td>
                  <span>TOTAL :</span>
                  <span className="mx-1">${grandTotal.toFixed(2)}</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      {/* Footer Section */}
      <Row className="mb-4">
        <Col className="text-center">
          <h5> WE ARE HONORED TO HAVE YOU AS ONE OF OUR DEAR CUSTOMERS.</h5>
        </Col>
      </Row>

      {/* Signature Section */}
      <Row className="mb-2">
        <Col className="text-center">
          <p>SIGNATURE</p>
          <p>_______________________________________________</p>
        </Col>
      </Row>

      <Row className="text-center">
        <h5 className="mt-2">THANKS FOR YOUR COLLABORATION.</h5>
      </Row>
    </div>
  );
};

export default Invoice;
