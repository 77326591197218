// ** React Imports
import { Fragment, useEffect, useState } from "react";

// ** Reactstrap Imports
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { Controller, useForm } from "react-hook-form";

// ** Styles
import Select from "react-select";

import { ErrorHandler } from "../../../common/utils/Error";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllbookings } from "../../../redux/booking";
import moment from "moment";

const ReceiptModel = ({ show, onClose, application }) => {
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultValues = {
    receipt_date: moment().format("YYYY-MM-DD"),
    application: application?._id,
    building: application?.building,
    customer: application?.customer?._id,
    amount: application?.amount,
    paid: application?.paid,
    account: "",
  };

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const getAccounts = async () => {
    try {
      var { status, data } = await axios.get(
        "/accountings/accounts/hierarchy/64089b489d122d411c67abe4"
      );
      if (status == 200) {
        setAccounts(data?.data ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setValue("receipt_date", moment().format("YYYY-MM-DD"));
    setValue("application", application?._id);
    setValue("building", application?.building);
    setValue("customer", application?.customer?._id);
    setValue("amount", application?.amount);
    setValue("paid", application?.amount - application?.paid);

    getAccounts();
  }, [show]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (data.paid > data.amount) {
        throw new Error("Paid Amount must be less than amount");
      }

      data.account = data.account?.value;

      console.log(data);

      var response = await axios.post(
        "/accountings/receipts/quick_receipt",
        data
      );

      if (response.status === 200) {
        onClose();
        dispatch(getAllbookings({}));
        // navigate(`/leases/create?application=${application._id}`);
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const onDiscard = () => {
    clearErrors();
    reset();
    onClose();
  };
  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onClose}
        className=""
        centered
      >
        <ModalHeader toggle={onClose} className="bg-white">
          Make Receipt
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}

            <Row className="gy-1 gx-2 flex-column mb-1">
              <Col xs={12}>
                <Label className="form-label" for="amount">
                  Amount
                </Label>
                <Controller
                  name="amount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="amount"
                      type="text"
                      readOnly
                      invalid={errors.amount && true}
                      {...field}
                    />
                  )}
                />
                {errors.amount && (
                  <FormFeedback>Please enter Amount</FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <Label className="form-label" for="paid">
                  Paid
                </Label>
                <Controller
                  name="paid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="paid"
                      type="text"
                      invalid={errors.paid && true}
                      {...field}
                    />
                  )}
                />
                {errors.paid && (
                  <FormFeedback>Please enter Paid Amount</FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <Label className="form-label" for="account">
                  Deposit to
                </Label>
                <Controller
                  name="account"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      id="account"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.account && true}
                      {...register("account", { required: true })}
                      {...field}
                      options={accounts.map((ac) => ({
                        value: ac._id,
                        label: `${ac.name}`,
                        isDisabled: ac.systemAccount,
                      }))}
                    />
                  )}
                />
                {errors.account && (
                  <FormFeedback>Please enter account</FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <Label className="form-label" for="receipt_date">
                  Receipt Date
                </Label>
                <Controller
                  name="receipt_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="receipt_date"
                      type="date"
                      invalid={errors.receipt_date && true}
                      {...field}
                    />
                  )}
                />
                {errors.receipt_date && (
                  <FormFeedback>Please enter Receipt Date</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="success"
              //   disabled={(!row && !rows.length) || loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading ? "Submitting..." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default ReceiptModel;
