import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { ErrorHandler } from "../../../common/utils/Error";
import {
  useCreateSectionalCategoryMutation,
  useUpdateSectionalCategoryMutation,
} from "../../../redux/sectionalCategory";

const VendorModel = ({ show, ShowModal, selectedCategory }) => {
  const [loading, setIsLoading] = useState(false);
  const [createSectionalCategory] = useCreateSectionalCategoryMutation();
  const [updateSectionalCategory] = useUpdateSectionalCategoryMutation();

  const defaultValues = {
    name: selectedCategory?.name ?? "",
    price: selectedCategory?.price ?? 0,
    reservationFee: selectedCategory?.reservationFee ?? 0,
    rooms: selectedCategory?.rooms ?? 0,
    bathRooms: selectedCategory?.bathRooms ?? 0,
    sectionalType: selectedCategory?.sectionalType ?? "",
  };

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (selectedCategory) {
        const { data: res } = await updateSectionalCategory({
          id: selectedCategory._id,
          formData: data,
        });
        if (res) {
          reset();
          ShowModal(false);
          toast.success("Category updated successfully!");
        }
      } else {
        const { data: res } = await createSectionalCategory(data);
        if (res) {
          reset();
          ShowModal(false);
          toast.success("Category added successfully!");
        }
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    reset(defaultValues);
  };

  useEffect(() => {
    reset({
      ...defaultValues,
    });
  }, [selectedCategory]);

  return (
    <Modal
      isOpen={show}
      onClosed={onDiscard}
      toggle={ShowModal}
      className="modal-lg"
      modalClassName="modal-dark"
    >
      <ModalHeader toggle={ShowModal} className="bg-white">
        Add New Category
      </ModalHeader>
      <ModalBody className="pb-5 px-sm-4 mx-50">
        <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Row className="gy-1 gx-2">
            <Col xs={12} md={6}>
              <Label className="form-label" for="name">
                Name
              </Label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    id="name"
                    placeholder="Name"
                    {...register(
                      "name",
                      { required: true },
                      "Name is required"
                    )}
                    invalid={errors.name && true}
                    {...field}
                  />
                )}
              />
              {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )}
            </Col>

            <Col xs={12} md={6}>
              <Label className="form-label" for="rooms">
                Rooms
              </Label>
              <Controller
                name="rooms"
                control={control}
                render={({ field }) => (
                  <Input
                    id="rooms"
                    placeholder="5"
                    {...register(
                      "rooms",
                      {
                        required: true,
                      },
                      "Rooms is required"
                    )}
                    invalid={errors.rooms && true}
                    {...field}
                  />
                )}
              />
              {errors.rooms && (
                <FormFeedback>Please enter a valid Rooms</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6}>
              <Label className="form-label" for="bathRooms">
                Bathrooms
              </Label>
              <Controller
                name="bathRooms"
                control={control}
                render={({ field }) => (
                  <Input
                    id="bathRooms"
                    type="number"
                    placeholder="4"
                    {...register(
                      "bathRooms",
                      {
                        required: true,
                      },
                      "BathRoom is required"
                    )}
                    invalid={errors.bathrooms && true}
                    {...field}
                  />
                )}
              />
              {errors.bathrooms && (
                <FormFeedback>Please enter a valid Bath-Rooms</FormFeedback>
              )}
            </Col>

            <Col xs={12} md={6}>
              <Label className="form-label" for="price">
                Rent Fee
              </Label>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <Input
                    id="price"
                    min={0}
                    type="number"
                    {...register(
                      "price",
                      {
                        required: true,
                      },
                      "Rent Fee is required"
                    )}
                    step={"any"}
                    placeholder="300"
                    invalid={errors.price && true}
                    {...field}
                  />
                )}
              />
              {errors.price && (
                <FormFeedback>Please enter a valid Rent Fee</FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6}>
              <Label className="form-label" for="price">
                Reservation Fee
              </Label>
              <Controller
                name="reservationFee"
                control={control}
                render={({ field }) => (
                  <Input
                    id="reservationFee"
                    min={0}
                    type="number"
                    {...register(
                      "reservationFee",
                      {
                        required: true,
                      },
                      "reservation Fee is required"
                    )}
                    step={"any"}
                    placeholder="300"
                    invalid={errors.reservationFee && true}
                    {...field}
                  />
                )}
              />
              {errors.reservationFee && (
                <FormFeedback>
                  Please enter a valid Reservation Fee
                </FormFeedback>
              )}
            </Col>
            <Col xs={12} md={6}>
              <Label className="form-label" for="sectionalType">
                Sectional Type
              </Label>
              <Controller
                name="sectionalType"
                control={control}
                render={({ field }) => (
                  <Input
                    id="sectionalType"
                    min={0}
                    type="select"
                    {...register(
                      "sectionalType",
                      {
                        required: true,
                      },
                      "Sectional Type is required"
                    )}
                    placeholder="Select Unit Type"
                    invalid={errors.sectionalType && true}
                    {...field}
                  >
                    <option>Select Unit Type</option>
                    <option value="Apartment">Apartment</option>
                    <option value="Normal House">Normal House</option>
                  </Input>
                )}
              />
              {errors.sectionalType && (
                <FormFeedback>Please enter a valid Sectional-Type</FormFeedback>
              )}
            </Col>

            <Col className="text-center" xs={12}>
              <Button
                type="submit"
                className="me-1 mt-2"
                color="primary"
                disabled={loading}
              >
                <Spinner className="me-1" size={"sm"} hidden={!loading} />
                Submit
              </Button>

              <Button
                type="reset"
                className="mt-2"
                color="secondary"
                outline
                onClick={onDiscard}
              >
                Discard
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default VendorModel;
