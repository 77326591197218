import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
// ** Icons Imports
import { Fragment, useState } from "react";

import { useEffect } from "react";

import { Check2All } from "react-bootstrap-icons";
import { toast } from "react-hot-toast";

import axios from "axios";
import classNames from "classnames";
import { TreeSelect } from "primereact/treeselect";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ErrorHandler } from "../../common/utils/Error";
import WebSetting from "./components/WebSetting";
import { formatTreeNodes } from "../../utility/Utils";

const TransactionAccount = [
  {
    value: "RentApplication",
    label: "Rent Application Income",
    dr: "",
    cr: "",
  },
  { value: "Rent", label: "Rent", dr: "", cr: "" },
  { value: "Customer Deposits", label: "Customer Deposits", dr: "", cr: "" },
  { value: "Expenses", label: "Expenses ", dr: "", cr: "" },
  { value: "Invoicing", label: "Invoicing", dr: "", cr: "" },
  { value: "consultation", label: "consultation", dr: "", cr: "" },
  { value: "maintainance", label: "maintainance", dr: "", cr: "" },
  { value: "salesAndBroker", label: "salesAndBroker", dr: "", cr: "" },
];

export default function AppSetting() {
  const [btnLoading, setBtnLoading] = useState(false);
  const [defaultAccountsLoading, setdefaultAccountsLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [active, setActive] = useState("1");

  const [webSetting, setWebSettings] = useState({});

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const getAccounts = async () => {
    try {
      var { status, data } = await axios.get(
        "/accountings/accounts/type/sub-accounts"
      );
      if (status == 200) {
        setAccounts(data?.data ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDefaultAccounts = async () => {
    setdefaultAccountsLoading(true);
    try {
      var { status, data } = await axios.get("/settings/accountings");
      if (status == 200) {
        let accs = [];
        if (data.data) {
          let uniqueValues = TransactionAccount.filter(
            (acc) =>
              !data.data.defaultAccounts.some((acc2) => acc2.value == acc.value)
          );
          accs = data.data.defaultAccounts ?? [];
          accs.push(...uniqueValues);
        }
        reset({ defaultAccounts: accs });
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setdefaultAccountsLoading(false);
    }
  };

  const getWebSettings = async () => {
    try {
      var { status, data } = await axios.get("/settings/web-settings");
      if (status == 200) {
        if (data?.data) {
          setWebSettings(data?.data);
        }
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getAccounts();
    getDefaultAccounts();
    getWebSettings();
  }, []);

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    setValue,
    trigger,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { defaultAccounts: TransactionAccount },
  });

  const { fields } = useFieldArray({
    control,
    name: "defaultAccounts",
  });

  const onSubmit = async (values) => {
    setBtnLoading(true);
    try {
      var { status, data } = await axios.post("/settings/accountings", values);
      if (status == 200) {
        toast.success("Default Accounts has being set");
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Fragment>
      <Card className="">
        <CardHeader className="d-flex align-items-start">
          <CardTitle tag="h4">General Settings</CardTitle>
        </CardHeader>
        <CardBody className="nav-vertical">
          <Nav tabs className="nav-left me-3">
            <NavItem>
              <NavLink
                active={active === "1"}
                onClick={() => {
                  toggle("1");
                }}
              >
                Accounting
              </NavLink>
              <NavLink
                active={active === "2"}
                onClick={() => {
                  toggle("2");
                }}
              >
                Payments
              </NavLink>
              <NavLink
                active={active === "3"}
                onClick={() => {
                  toggle("3");
                }}
              >
                Website Settings
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={active} className="">
            <TabPane tabId="1">
              <h4>Default Accounts</h4>
              <p>Manage default accounts for all system transactions</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                {defaultAccountsLoading ? (
                  <div className="py-5 mx-auto d-flex justify-content-center align-items-center">
                    <Spinner color="md" />
                  </div>
                ) : (
                  <table className="table">
                    <tbody>
                      {fields.map((acc, idx) => (
                        <tr key={acc.id}>
                          <td width={"40%"} className="ps-0" key={idx}>
                            <Label className="mb-0">
                              <h6 className="mb-0 fw-bolder">{acc.label}</h6>
                            </Label>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="fw-bolder">DR</span>
                              <Controller
                                name={`defaultAccounts.${idx}.dr`}
                                control={control}
                                render={({ field, fieldState }) => (
                                  <TreeSelect
                                    // filter
                                    options={formatTreeNodes(accounts)}
                                    placeholder="Select Item"
                                    className={classNames("w-100 ms-1", {
                                      "p-invalid": fieldState.error,
                                    })}
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="fw-bolder">CR</span>
                              <Controller
                                name={`defaultAccounts.${idx}.cr`}
                                control={control}
                                render={({ field, fieldState }) => (
                                  <TreeSelect
                                    // filter
                                    options={formatTreeNodes(accounts)}
                                    placeholder="Select Item"
                                    className={classNames("w-100 ms-1", {
                                      "p-invalid": fieldState.error,
                                    })}
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                <Button
                  color="success"
                  type="success"
                  className="mt-2"
                  disabled={btnLoading}
                >
                  {btnLoading ? (
                    <Spinner size={"sm"} className="me-1" />
                  ) : (
                    <Check2All size={18} className="me-1" />
                  )}
                  Save Changes
                </Button>
              </form>
            </TabPane>

            <TabPane tabId="2">select available payments</TabPane>
            <TabPane tabId="3">
              <WebSetting webSetting={webSetting} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Fragment>
  );
}
