import React from "react";
import { useSearchParams } from "react-router-dom";
import Summery from "./property/Summery";

export default function PropertiesReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div>
      PropertiesReport
      {/* <pre>{JSON.stringify(searchParams)}</pre> */}
      <Summery />
    </div>
  );
}
