import IonIcon from "@reacticons/ionicons";
import { Mail, Home } from "react-feather";

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/home",
  },
  {
    id: "secondPage",
    title: "Second Page",
    icon: <Mail size={20} />,
    navLink: "/second-page",
  },
  {
    id: "buildings",
    title: "Buildings",
    icon: <Home size={20} />  ,
    navLink: "/second-page",
  },
  {
    id: "bookings",
    title: "Bookings",
    icon: <IonIcon name="bookmarks-outline" />  ,
    navLink: "/bookings",
    action: "manage",
    resource: "Bookings",
  },
  {
    id: "invoices",
    title: "Invoices",
    icon: <IonIcon name="calculator-outline" />  ,
    navLink: "/invoices",
    action: "manage",
    resource: "Invoice",
  },
];
