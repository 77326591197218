import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { mdiMicrosoftExcel } from "@mdi/js";
import Icon from "@mdi/react";
import LetterHead from "@src/assets/images/letterhead/LetterHead-04.png";
import axios from "axios";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { useRef } from "react";
import { Printer, Search } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import { ErrorHandler } from "../../../common/utils/Error";
import { downloadCSV, formatCurrency } from "../../../utility/Utils";

export default function TrialBalance() {
  let accounts = [];
  let expenses = [];
  const [nodes, setNodes] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(null);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultValues = { fiscalYear: "", start_date: "", end_date: "" };

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (values) => {
    values.fiscalYear = values.fiscalYear?.value ?? "";
    let q = "?";
    for (const key in values) {
      if (values[key] != "") {
        q += `&${key}=${values[key]}`;
      }
    }

    getStatement(q.length > 1 ? q : "");
  };

  const getStatement = async (query) => {
    try {
      setLoading(true);
      const { data, status } = await axios.get(
        `/accountings/statements/trial-balance${query}`
      );

      if (status == 200) {
        accounts = data.data;
      }
      formatTreeNodes();
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Promise.all([getFiscalYears(), getStatement("")]);
  }, []);

  useEffect(() => {
    expandAll();
  }, [nodes]);

  const formatTreeNodes = () => {
    let formattedAccounts = [];
    let totalDebit = accounts.reduce(
      (prev, rvacc) => prev + Number(rvacc.debit),
      0
    );
    let totalCredit = accounts.reduce(
      (prev, rvacc) => prev + Number(rvacc.credit),
      0
    );

    accounts
      .filter((acc) => acc.parentAccount == null)
      .forEach((account) => {
        formattedAccounts.push({
          key: account._id,
          data: {
            ...account,
            debit: account.debit != 0 ? formatCurrency(account.debit) : "",
            credit: account.credit != 0 ? formatCurrency(account.credit) : "",
          },
          children: getSubAccounts(account._id),
        });
      });

    let acc = [
      ...formattedAccounts,
      {
        key: `row_` + Math.floor(Math.random() * 10000),
        isGrandTotal: true,
        data: {
          name: "Total",
          debit: formatCurrency(totalDebit),
          credit: formatCurrency(totalCredit),
        },
      },
    ];

    setNodes(acc);

    return acc;
  };

  const getSubAccounts = (parent) => {
    return accounts
      .filter((account) => {
        if (account.parentAccount == null && account.type == parent) {
          return true;
        } else if (account.parentAccount != null && account.type == parent) {
          return false;
        } else if (account.parentAccount == parent || account.type == parent) {
          return true;
        }
      })
      .map((account) => ({
        key: account._id,
        expanded: true,
        data: {
          ...account,
          debit: account.debit != 0 ? formatCurrency(account.debit) : "",
          credit: account.credit != 0 ? formatCurrency(account.credit) : "",
        },
        children: getSubAccounts(account._id),
      }));
  };

  const rowClassName = (node) => {
    return {
      "fw-bolder bg-light": node.isTitle,
      "fw-bolder text-primary ": node.isTotal,
      "fw-bolder text-success ": node.isProit == true,
      "fw-bolder text-danger ": node.isProit == false,
      "fw-bolder text-white bg-dark": node.isGrandTotal,
    };
  };

  const expandAll = () => {
    let _expandedKeys = {};

    for (let node of nodes) {
      expandNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  };

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Trail Balance Report`,
    pageStyle: `
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always !important;
      }
      .card{
        box-shadow:none !important;
      }
    }
    
    @page {
      size: auto;
    }
    `,
    bodyClass: "",
    // removeAfterPrint: true,
  });

  const getFiscalYears = async () => {
    try {
      var { status, data } = await axios.get("/accountings/books/list");
      if (status == 200) {
        setFiscalYears(data?.data ?? []);
        let curr = data.data.find((year) => year.isCurrent == true);
        if (curr) {
          setValue("fiscalYear", { label: curr.name, value: curr._id });
        }
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };
  const AccountStyle = (node) => {
    let data = node.data;

    if (
      node.isGrandTotal ||
      node.isProit ||
      node.isTotal ||
      node.isTitle ||
      node.isNetIncome
    ) {
      return <span>{data.name}</span>;
    }

    return (
      <Link to={"/accountings/account-statement?account=" + data._id}>
        <span>{data?.name}</span>
      </Link>
    );
  };

  return (
    <>
      <Card>
        <CardBody className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Trial Balance Statement</h4>
        </CardBody>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} md={4}>
                <Label className="form-label" for={`fiscal_year`}>
                  Fiscal Year
                </Label>
                <Controller
                  name={`fiscalYear`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      id={`fiscal_year`}
                      className="react-select"
                      classNamePrefix="select"
                      // invalid={errors.lines![index].account && true}
                      {...field}
                      placeholder={"Select Fiscal Year"}
                      options={fiscalYears?.map((year) => ({
                        label: year.name,
                        value: year._id,
                      }))}
                    />
                  )}
                />
                {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
              </Col>
              <Col xs={12} md={3}>
                <Label className="form-label" for={`start_date`}>
                  From Date
                </Label>
                <Controller
                  name={`start_date`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      id={`start_date`}
                      invalid={errors.dateRange && true}
                      {...field}
                      type="date"
                    />
                  )}
                />
                {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
              </Col>
              <Col xs={12} md={3}>
                <Label className="form-label" for={`end_date`}>
                  To Date
                </Label>
                <Controller
                  name={`end_date`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      id={`end_date`}
                      invalid={errors.dateRange && true}
                      {...field}
                      type="date"
                    />
                  )}
                />
                {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
              </Col>

              <Col xs={12} md={2} className="text-center mt-2">
                <Button className="h-100 w-100" color="primary">
                  {" "}
                  <Search size={14} className="" /> Apply Filter{" "}
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card innerRef={printRef} dir="ltr">
        <CardBody>
          <div className="d-flex justify-content-between">
            <div>
              <img src={LetterHead} alt="" className="mb-1" width={70} />
              <h2 className="mb-0">Trial Balance Statement</h2>
              <p className="mb-2">All Date</p>
            </div>
            <div className="no-print">
              <Printer
                size={26}
                className="cursor-pointer me-1"
                title="Print"
                onClick={() => handlePrint()}
              />
              <span
                className="cursor-pointer"
                onClick={() => {
                  let excl = nodes.map((node) => node.data);
                  downloadCSV(excl, "Balance Sheet");
                }}
              >
                <Icon path={mdiMicrosoftExcel} size={1.6} horizontal />
              </span>
            </div>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <TreeTable
              value={nodes}
              expandedKeys={expandedKeys}
              onToggle={(e) => setExpandedKeys(e.value)}
              emptyMessage="Trial Balance  Not Available"
              rowClassName={rowClassName}
              className="border"
              tableClassName="table"
            >
              <Column
                field="name"
                header="Account Name"
                expander
                body={AccountStyle}
              ></Column>
              <Column
                field="debit"
                header="DR"
                body={(row) => row.data.debit || "---"}
              ></Column>
              <Column
                field="credit"
                header="CR"
                body={(row) => row.data.credit || "---"}
              ></Column>
            </TreeTable>
          )}
        </CardBody>
      </Card>
    </>
  );
}
